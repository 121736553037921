<template>
    <div class="page-wrapper">
        <div class="page-padding">
            <div class="header">
                <div class="left">
                    <div class="title">
                        <div class="back">
                            <arrow-button
                                color="black"
                                direction="left"
                                @click="$router.push('/reports')"
                            ></arrow-button>
                        </div>
                        <h2 v-if="!isFormTemplatesReportDefault">{{ report.name || $route.query.name }}</h2>
                        <h2 v-else-if="isFormTemplatesReportDefault">{{ selectedFormTemplate }}</h2>
                    </div>
                    <div class="subtitle">
                        <q-button class="button" variation="blank" v-if="canEdit" @click="startEdit"
                            >Voorwaarden en gegevens inzien</q-button
                        >
                    </div>
                </div>
                <div class="right">
                    <jump-transition>
                        <div v-if="!loadingReport && !isCrowMasterOrganisation">
                            <q-button size="small" variation="ghost" @click="handleExportReport"
                                >Exporteren</q-button
                            >
                        </div>
                    </jump-transition>
                    <jump-transition>
                        <div
                            v-if="
                                !loadingReport && !predefinedReport && hasFilterOptions && !isFormTemplatesReportDefault
                            "
                            class="button-container"
                        >
                            <q-button
                                class="filters-button"
                                size="small"
                                icon="filterIcon"
                                :disabled="filtersDisabled"
                                @click="handleToggleMenu"
                                >Filters</q-button
                            >

                            <div class="filters-tag">{{ filtersTagCount }}</div>
                        </div>
                    </jump-transition>

                    <jump-transition>
                        <div v-if="isFormTemplatesReportDefault">
                            <q-dropdown
                                v-model="selectedFormTemplateId"
                                :options="organisationFormTemplatesOptions"
                                :placeholder="'Kies een formuliertemplate'"
                                size="small"
                                @input="handleFormTemplateSelected"
                                style="width: 400px"
                                autocomplete
                            ></q-dropdown>
                        </div>
                    </jump-transition>

                    <!-- <q-button class="snapshot-button" variation="blank" icon="camera">Snapshot maken</q-button> -->
                </div>
            </div>
            <div class="body">
                <q-popup v-if="showBuildingReportPopup" width="500px">
                    <div class="building-popup">
                        <h3>We zijn je rapportage aan het bouwen!</h3>
                        <p>
                            Het kan zijn dat het iets langer duurt als we veel data moeten ophalen. Zie je dit bericht
                            vaker? Neem dan contact op met onze klantenservice.
                        </p>
                        <div class="footer">
                            <q-button variation="primary" @click="showBuildingReportPopup = false">Begrepen</q-button>
                        </div>
                    </div>
                </q-popup>
                <div class="table-wrapper">
                    <q-table-with-pagination
                        :data="data"
                        :columns="columns"
                        :tableLoading="buildingReport || showLoadingState"
                        :maxRows="paginatedTableLength"
                        :dataLength="resultsTotalAmount"
                        :openedGroupKey="openedGroupKey"
                        :isSearching="Boolean(selectedFiltersLength)"
                        :loadingTableRows="loadingTableRows"
                        paddingInline="20px"
                        ref="reportsTable"
                        overflowX="auto"
                        clickable
                        multiLineHeader
                        @click="handleTableRowClick"
                        @filterUpdated="filterUpdated"
                        @filterSearch="_filterSearchChanged"
                        @pageSelection="handleTablePagination"
                        @filterInitialized="handleGetReport"
                    >
                        <template v-slot:row="{ column, row }">
                            <span
                                v-if="column === firstColumnField && row.isIndicator"
                                :class="['column-field', 'indicator-list']"
                            >
                                <p>{{ parseResultsetField(row, column) }}</p>
                            </span>
                            <span
                                v-else-if="column === firstColumnField && !row.isTotalSumRow"
                                :class="[
                                    'column-field',
                                    'label-list',
                                    { 'label-list-opened': openedGroupKey === row.collapsableGroupKey },
                                ]"
                            >
                                <p>{{ parseResultsetField(row, column) }}</p>
                                <q-icon type="chevronDown" class="chevron-icon"></q-icon>
                            </span>
                            <span
                                v-else-if="row.isTotalSumRow"
                                :class="[column === firstColumnField ? 'total-score' : '']"
                            >
                                <p>
                                    <strong>{{ parseResultsetField(row, column) }}</strong>
                                </p>
                            </span>
                            <span v-else-if="column === 'display-projectStatus'">
                                <q-tag size="medium" :variation="row[column] === 'Afgerond' ? 'success' : 'warning'">{{
                                    parseResultsetField(row, column)
                                }}</q-tag>
                            </span>
                            <span v-else-if="['display-client', 'display-contractor'].includes(column)">
                                <div class="initiator-badge-wrapper">
                                    <p class="organisation-name">
                                        {{ parseResultsetField(row, column) }}
                                        <q-tooltip>
                                            <template #tooltip>Initiatiefnemer</template>
                                            <q-icon
                                                v-if="
                                                    (column === 'display-client' &&
                                                        row['value-projectInitiatorIsClient'] === 1) ||
                                                    (column === 'display-contractor' &&
                                                        row['value-projectInitiatorIsClient'] === 0)
                                                "
                                                class="initiator-badge"
                                                type="InitiatorBadge"
                                                width="14"
                                                height="14"
                                            ></q-icon>
                                        </q-tooltip>
                                    </p>
                                </div>
                            </span>
                            <span
                                v-else
                                class="column-field"
                                :class="{
                                    'bold-number': openedGroupKey === row.collapsableGroupKey && !row.isIndicator,
                                }"
                            >
                                <p class="row-text" v-html="parseResultsetField(row, column)"></p>
                            </span>
                        </template>
                    </q-table-with-pagination>
                </div>
            </div>
        </div>
        <filter-menu
            v-if="!filtersDisabled && filterMenuLoaded"
            :report="report"
            :showFilterMenu="showFilterMenu"
            :resultsFilterOptions="resultsFilterOptions"
            :activeFiltersAmount="filtersTagCount"
            @toggleMenu="handleToggleMenu"
            @updateFilter="filterMenuUpdated"
        ></filter-menu>
    </div>
</template>

<script>
import _ from 'lodash';
import gql from 'graphql-tag';

import ArrowButton from '@/components/ArrowButton';
import FilterMenu from './FilterMenu.vue';

import {
    ACCESS_REPORT,
    GET_REPORT,
    GET_REPORT_RESULTSET,
    GET_REPORT_RESULTSET_TOTAL_AMOUNT,
    GET_REPORT_RESULTSET_FILTER_OPTIONS,
    REPORTS_MINIMAL,
} from '@/graphql/queries';
import { REPORT_REBUILD_RESULTSET } from '@/graphql/mutations';

import { parseResultsetField } from './utils';
import { extractError, getCalculatedRows } from '@/assets/js/utils';
import { getIndicatorCondition } from './helper';
import XLSX from 'xlsx-js-style';
import { REPORT_UPDATE_CONFIGURATION } from '../../../graphql/mutations';

export default {
    name: 'report-page',
    components: {
        ArrowButton,
        FilterMenu,
    },
    data() {
        return {
            isFormTemplatesReportDefault: false,
            loading: false,
            organisationId: this.$store.getters.getCurrentOrganisation.id,
            labels: [],
            showFilterMenu: false,
            report: {},
            results: [],
            columns: [
                {
                    label: '',
                    key: '',
                },
            ],
            data: [],
            paginatedTableLength: 10,
            currentPageIndex: 0,
            resultsTotalAmount: 0,
            resultsFilterOptions: {},
            resultsFilterOptionsSearch: {},
            resultsConditions: [],
            buildingReport: false,
            showBuildingReportPopup: false,
            filterMenuConditions: [],
            openedGroupKey: '',
            firstColumnField: '',
            predefinedReport: false,
            disableBuildResultset: false,
            filtersTagCount: 0,
            selectedFiltersLength: 0,
            hasFilterOptions: false,
            isCrowMasterOrganisation: this.$store.getters.getCurrentOrganisation.id === 'crow-master-organisation',
            showLoadingState: true,
            loadingReport: true,
            organisationFormTemplatesOptions: [],
            selectedFormTemplateId: null,
            loadingTableRows: 10,
            initializedTableLength: false, 
            filterMenuLoaded: false
        };
    },
    methods: {
        handleTableRowClick(row) {
            if (this.report.configuration.conditions.projects.length === 0 || row.isIndicator) return;

            if(row.collapsableGroupKey) {
                this.openedGroupKey = this.openedGroupKey === row.collapsableGroupKey ? '' : row.collapsableGroupKey;
            }
        },
        filterMenuUpdated(filter) {
            const conditions = [];

            this.filtersTagCount = 0;

            filter.forEach((context) => {
                context.items
                    .filter((item) => item.checked)
                    .forEach((item) => {
                        this.filtersTagCount += 1;
                        const condition = this.getIndicatorCondition(item);

                        conditions.push(condition);
                    });
            });

            this.filterMenuConditions = conditions;

            this.getResultset();
            this.getResultsTotalAmount();
            this.$refs.reportsTable.resetCounter();
        },
        getIndicatorCondition(item) {
            return getIndicatorCondition(item);
        },
        handleToggleMenu() {
            this.showFilterMenu = !this.showFilterMenu;
        },
        filterUpdated(conditions) {
            if (Object.keys(conditions).length) {
                this.selectedFiltersLength = Object.keys(conditions).length;
                this.$refs.reportsTable.resetCounter();
            }
            this.resultsConditions = Object.keys(conditions)
                .filter((field) => conditions[field].filter.length > 0)
                .map((field) => {
                    return {
                        column: field.split('display-')[1],
                        values: conditions[field].filter,
                    };
                });
        },
        handleExportReport() {
            this.exporting = true;

            let groupOnLabel = false;
            let labelColumnId = '';

            if (this.report.configuration.conditions.projects.length > 0) {
                groupOnLabel = true;
                const filteredLabelId = this.report.configuration.projection.filter(
                    (projection) => projection.type === 'hidden-grouped-label'
                );
                if (filteredLabelId) labelColumnId = filteredLabelId[0].id;
            }

            this.$apollo.query({
                query: gql`
                    query exportReport($reportId: String!, $filters: JSON) {
                        report_exportData(reportId: $reportId, filters: $filters) {
                            rows
                        }
                    }
                `,
                variables: {
                    reportId: this.report.id,
                    filters: {
                        conditions: {
                            columnConditions: this.resultsConditions,
                            filterMenuConditions: this.filterMenuConditions,
                        },
                        offset: 0,
                        limit: 999999,
                        orderBy: [],
                        options: {
                            groupOnLabel: groupOnLabel,
                            labelColumnId: labelColumnId,
                        }
                    }
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                const { projection } = this.report.configuration;
                const headerRow = result.data.report_exportData.rows[0];
                const scoreField = headerRow.map((columnName) => columnName === "Gemiddelde score" ||  
                                                                !!projection.find(prjection => prjection.labelName === columnName && 
                                                                (prjection.type.includes('verage') || prjection.type.includes('ScoreFilter'))));
                const rows = result.data.report_exportData.rows.map(row => {
                    for(const index in row) {
                        const value = row[index];

                        // Round all display numbers to 1 decimal
                        if (!isNaN(value) && /\d+\.\d+/.test(`${value}`)) {
                            const number = +value;
                            if (scoreField[index] && number % 1 !== 0) { // has a fractional part
                                row[index] = Math.round(number * 10) / 10;
                            }
                        }
                    }
                    return row;
                });
                this.handleExportExcel(rows);
            })
            .catch(error => {
                console.error(error);
                this.$store.commit('notify', {
                    type: 'danger',
                    message: 'Er is iets misgegaan bij het exporteren van de rapportage',
                });
            })
        },
        cleanArray(array, columns) {
            columns = columns.filter((column) => column.label !== '');

            array = array.map((item) => {
                let newItem = {};

                columns.forEach((column) => {
                    newItem[column.label] = item[column.field];
                });

                return newItem;
            });

            return array;
        },
        /**
         * Builds a CSV (.csv) file from the report
         * @param {Array} resultset
         */
        handleExportCsv(array) {
            const cleanedArray = this.cleanArray(array, this.columns);

            const headers = Object.keys(cleanedArray[0]);
            const headerRow = headers.join(',');
            const csvRows = [headerRow, ...cleanedArray.map((row) => Object.values(row).join(','))].join('\n');

            const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', `export.csv`);
            link.click();
        },
        /**
         * Builds an Excel (.xlsx) file from the report
         * @param {Array} resultset
         */
        handleExportExcel(rows) {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet(rows);
            XLSX.utils.book_append_sheet(wb, ws, 'Rapportage');

            XLSX.writeFile(wb, 'Rapportage.xlsx');
        },
        getAverages(averageSet) {
            if (!averageSet.isTotalSumRow === true) throw new Error('Not a total sum row');

            const projections = this.report.configuration.projection

            const averageProjection = projections.find(
                (projection) => projection.type === 'total-average-forms-over-formtype-project'
            );

            const averageFormProjections = projections.filter(
                (projection) => projection.type === 'average-form-over-formtype-project'
            );

            const averageProjectionKey = `display-${averageProjection.id}`;
            const averageFormProjectionKeys = averageFormProjections.map((projection) => `display-${projection.id}`);

            let averageRow = ['', 'Totaal score']

            averageFormProjectionKeys.forEach((key) => {
                const average = parseFloat(averageSet[key]) || '-'
                averageRow.push(average)
            })

            const average = parseFloat(averageSet[averageProjectionKey]) || '-'
            averageRow.push(average)

            return averageRow
        },
        _filterSearchChanged(filterSearch) {
            this.resultsFilterOptionsSearch = filterSearch;
            this.getFilterOptions();
        },
        handleTablePagination(pageIndex) {
            this.currentPageIndex = pageIndex - 1;
            if(!this.initializedTableLength) return
            this.getResultset();
        },
        parseResultsetField(row, fieldName) {
            return parseResultsetField(row, fieldName, this.filterMenuConditions);
        },
        startEdit() {
            this.$router.push(`/reports/${this.report.id}/edit`);
        },
        async reportIsSynced() {
            const { id } = this.$route.params;
            const { syncedWithConfiguration } = await this.$apollo
                .query({
                    query: GET_REPORT,
                    variables: {
                        id,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((result) => result.data.report)
                .catch((error) => {
                    if (!this.$route.fullPath.includes('/reports')) return;

                    if (id === 'crow-report-default' && !this.isCrowMasterOrganisation) {
                        this.$store.commit('notify', {
                            type: 'warning',
                            message: 'Je hebt geen toegang tot deze rapportage',
                        });
                        this.$router.push('/reports');
                        return;
                    } else {
                        this.$store.commit('notify', {
                            type: 'danger',
                            message: 'Er ging iets fout tijdens het ophalen van de rapportage',
                        });
                    }
                });
            return syncedWithConfiguration;
        },
        async rebuildResultSet() {
            const { id } = this.$route.params;
            await this.$apollo
                .mutate({
                    mutation: REPORT_REBUILD_RESULTSET,
                    variables: {
                        id,
                    },
                })
                .catch((error) => console.log(error));
            return;
        },
        async getReport(showPopup) {
            if (!this.$route.fullPath.includes('/reports')) return;
            const { id } = this.$route.params;
            this.loadingReport = true;

            await this.$apollo
                .query({
                    query: ACCESS_REPORT,
                    variables: {
                        id,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((result) => {
                    if (!this.$route.fullPath.includes('/reports')) return;
                    this.report = result.data.report_access.report;

                    const filterOptions = this.report.configuration.projection.filter(
                        (projection) => projection.name !== 'projectName' && projection.filterable
                    );
                    this.hasFilterOptions = filterOptions.length > 0;

                    let invisibleProjectionsForFilter = [];
                    invisibleProjectionsForFilter = this.report.configuration.projection.filter(
                        (projection) => projection.visible !== true && projection.id !== 'projectInitiatorIsClient'
                    );

                    const labelProjection = this.report.configuration.projection.find(
                        (projection) => projection.type === 'average-forms-over-formtype-project'
                    );

                    if (labelProjection) this.firstColumnField = `display-${labelProjection.id}`;
                    else this.report.configuration.projection[0].id;

                    if (this.report.isFormTemplateResultset) this.isFormTemplatesReportDefault = true;

                    if (this.report.building || !this.report.syncedWithConfiguration) {
                        this.setTableColumns();

                        this.buildingReport = true;
                        if (showPopup) this.showBuildingReportPopup = true;

                        this.fetchNewReport();
                    } else {
                        this.buildingReport = false;
                        this.showBuildingReportPopup = false;

                        this.setTableColumns();
                        this.getResultset();
                        this.getResultsTotalAmount();
                        this.getFilterOptions();
                    }
                })
                .catch((error) => {
                    if (!this.$route.fullPath.includes('/reports')) return;
                    this.loadingReport = false;
                    if (id === 'crow-report-default' && !this.isCrowMasterOrganisation) {
                        this.$store.commit('notify', {
                            type: 'warning',
                            message: 'Je hebt geen toegang tot deze rapportage',
                        });
                        this.$router.push('/reports');
                        return;
                    } else {
                        this.$store.commit('notify', {
                            type: 'danger',
                            message: 'Er ging iets fout tijdens het ophalen van de rapportage',
                        });
                    }
                });
        },
        async fetchNewReport() {
            await new Promise((r) => setTimeout(r, 3000));
            this.getReport(false);
        },
        arraySum(array) {
            return array.reduce((partialSum, a) => partialSum + a, 0);
        },
        getWeightedAverage(rows, valueColumnName, weightColumnName, precision = 1) {
            const filledRows = rows.filter((row) => row[valueColumnName] !== null && row[valueColumnName] !== 0); // ignore empty values
            if (!filledRows.length) return null;
            const weightedValues = filledRows.map((row) => row[valueColumnName] * row[weightColumnName]);
            const weights = filledRows.map((row) => row[weightColumnName]);
            return (this.arraySum(weightedValues) / this.arraySum(weights)).toFixed(precision);
        },
        getFormTemplatesAverageData() {
            //row averages
            if (this.data.length === 0) return;
            const { projection } = this.report.configuration;

            const formNameColumn = projection.find((projection) => projection.type === 'projectName');
            const labelColumns =
                projection.filter((projection) => projection.type === 'average-indicators-over-form') || [];
            const totalAverageOverRowColumn = projection.find((projection) => projection.type === 'average-form-over-indicators');
            const labelColumnIds = labelColumns.map(column => column.id);

            const columnAverage = totalAverageOverRowColumn && 'id' in totalAverageOverRowColumn ? [...labelColumnIds, totalAverageOverRowColumn.id] : labelColumnIds;

            const formIndicators = this.data;

            //column averages
            const newFormIndicators = formIndicators.map((indicator) => {
                return {
                    ...indicator,
                    weight: 1,
                };
            });

            const totalSumOfAverage = {
                [`display-${formNameColumn.id}`]: 'Totaal score',
                isTotalSumRow: true,
            };
            columnAverage.forEach((columnId) => {
                totalSumOfAverage[`value-${columnId}`] = this.getWeightedAverage(
                    newFormIndicators,
                    `value-${columnId}`,
                    'weight'
                );
                totalSumOfAverage[`display-${columnId}`] = totalSumOfAverage[`value-${columnId}`] || '-';
                this.data = [...formIndicators, totalSumOfAverage];
            });
        },
        getIndicatorAndLabelData() {
            const { projection } = this.report.configuration;

            const stringToNumber = function toNumber(value) {
                return Number(value);
            };

            const labelColumn = projection.find((projection) => projection.type === 'hidden-grouped-label');
            const questionNameColumn = projection.find(
                (projection) => projection.type === 'average-forms-over-formtype-project'
            );
            const formColumns = projection.filter(
                (projection) => projection.type === 'average-form-over-formtype-project'
            );
            const totalAverageOverRowColumn = projection.find(
                (projection) => projection.type === 'total-average-forms-over-formtype-project'
            );
            const totalAverageOverRowColumnId = `display-${totalAverageOverRowColumn.id}`;

            const formColumnIds = formColumns.map(column => column.id);
            const formColumnValueIds = projection.filter(proj => proj.type === 'average-form-over-formtype-project').map(proj => `value-${proj.id}`);
            const columnsToAverage = [...formColumnIds, totalAverageOverRowColumn.id];

            const indicators = this.data;

            const labelIds = new Set(indicators.map((indicator) => indicator[`value-${labelColumn.id}`]));

            let tableData = [];

            labelIds.forEach((labelId) => {
                const labelIndicators = indicators
                    .filter((indicator) => indicator[`value-${labelColumn.id}`] === labelId)
                    .map((indicator) => {
                        return {
                            ...indicator,
                            collapsableGroupKey: labelId,
                            collapsable: true,
                            isIndicator: true,
                            weight: 1,
                        };
                    });

                const label = {
                    isLabel: true,
                    [`display-${questionNameColumn.id}`]: labelIndicators[0][`display-${labelColumn.id}`],
                    weight: 1,
                    collapsableGroupKey: labelId,
                };

                columnsToAverage.forEach((columnId) => {
                    label[`value-${columnId}`] = this.getWeightedAverage(
                        labelIndicators,
                        `value-${columnId}`,
                        'weight'
                    );
                    label[`display-${columnId}`] = label[`value-${columnId}`] || '-';
                });

                const projectId = label['value-projectName'];

                tableData = [...tableData, label, ...labelIndicators];
            });

            // row averages
            for (let i = 0; i < tableData.length; i++) {
                const indicator = tableData[i];

                const indicatorRowAverage = Object.entries(indicator).filter(
                    ([key]) => {
                        return formColumnValueIds.includes(key);
                    }
                );

                let result = indicatorRowAverage.map(indicator => indicator[1]);

                result = result.filter(average => average !== null).map(stringToNumber);

                let average = [];

                if (result.length !== 0) {
                    average = result.reduce((partialSum, a) => partialSum + a, 0) / result.length;
                    if (average !== 0) indicator[totalAverageOverRowColumnId] = average.toFixed(1); 
                }                
            }

            const totalSumOfAverage = {
                [`display-${questionNameColumn.id}`]: 'Totaal score',
                isTotalSumRow: true,
            };
            const labelRows = tableData.filter((row) => row.isLabel === true);
            columnsToAverage.forEach((columnId) => {
                totalSumOfAverage[`value-${columnId}`] = this.getWeightedAverage(
                    labelRows,
                    `value-${columnId}`,
                    'weight',
                    1
                );
                totalSumOfAverage[`display-${columnId}`] = totalSumOfAverage[`value-${columnId}`] || '-';
            });

            this.data = [...tableData, totalSumOfAverage];
        },
        parseScore(score) {
            let parsedScore = Math.round(score * 10) / 10;
            return parsedScore;
        },
        startLoadingTimeout() {
            this.waitingForLoadingTimeout = setTimeout(this.handleStartLoading, 150);
        },
        handleStartLoading() {
            this.showLoadingState = true;
        },
        handleStopLoading() {
            if (this.waitingForLoadingTimeout) clearTimeout(this.waitingForLoadingTimeout);
            this.showLoadingState = false;
        },
        getResultset() {
            if (this.report.isFormTemplateResultset) {
                this.paginatedTableLength = 10000;
            }

            let groupOnLabel = false;
            let labelColumnId = '';

            if (this.report.configuration.conditions.projects.length > 0) {
                groupOnLabel = true;
                const filteredLabelId = this.report.configuration.projection.filter(
                    (projection) => projection.type === 'hidden-grouped-label'
                );
                if (filteredLabelId.length > 0) labelColumnId = filteredLabelId[0].id;
            }

            if (!this.showLoadingState) {
                this.startLoadingTimeout();
            }

            this.$apollo
                .query({
                    query: GET_REPORT_RESULTSET,
                    variables: {
                        reportId: this.report.id,
                        conditions: {
                            columnConditions: this.resultsConditions,
                            filterMenuConditions: this.filterMenuConditions,
                        },
                        offset: this.currentPageIndex * this.paginatedTableLength,
                        limit: this.paginatedTableLength,
                        orderBy: [],
                        options: {
                            groupOnLabel: groupOnLabel,
                            labelColumnId: labelColumnId,
                        },
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((result) => {
                    const { projection } = this.report.configuration;
                    const averageColumns = projection.filter((projection) => projection.type.includes('verage') || projection.type.includes('ScoreFilter'));
                    const rows = result.data.resultset.map(row => {
                        const projectNamekey = 'value-projectName';
                        const keys = Object.keys(row);
                        for(const index in keys) {
                            const key = keys[index];
                            const value = row[key];

                            if(key === projectNamekey) row.to = `/projects/${value}`;                            

                            // Round all display numbers to 1 decimal
                            if (key.startsWith('display-') && !isNaN(value) && /\d+\.\d+/.test(`${value}`)) {
                                const number = +value;
                                const id = key.substring(8);
                                const column = averageColumns.find((projection) => projection.id === id);
                                if (column && number % 1 !== 0) { // has a fractional part
                                    row[key] = Math.round(number * 10) / 10;
                                }
                            }
                        }
                        return row
                    });
                    
                    this.data = rows;

                    let projectCondition = this.report.configuration.conditions.projects.find(
                        (project) => project.type === 'project' && project.value
                    );
                    let formTemplateCondition = this.report.configuration.conditions.projects.find(
                        (form) => form.type === 'average-forms-over-formtype-project' && form.value
                    );
                    const isVhbReport = projectCondition instanceof Object && formTemplateCondition instanceof Object;
                    const isFormTemplatesReport = this.report.configuration.conditions.formTemplates?.length > 0;

                    if (isVhbReport) {
                        this.predefinedReport = true;
                        this.getIndicatorAndLabelData();
                    }
                    if (isFormTemplatesReport) {
                        this.predefinedReport = true;
                        this.getFormTemplatesAverageData();
                    }
                    this.setTableColumns();

                    this.handleStopLoading();
                    this.loadingReport = false;
                })
                .catch((error) => {
                    this.loadingReport = false;
                    this.$store.commit('notify', {
                        type: 'danger',
                        message: 'Er ging iets fout tijdens het ophalen van de gegevens',
                    });
                });
        },
        getResultsTotalAmount() {
            let groupOnLabel = false;
            let labelColumnId = '';

            if (this.report.configuration.conditions.projects.length > 0) {
                groupOnLabel = true;
                const filteredLabelId = this.report.configuration.projection.filter(
                    (projection) => projection.type === 'hidden-grouped-label'
                );
                if (filteredLabelId.length > 0) labelColumnId = filteredLabelId[0].id;
            }

            this.$apollo
                .query({
                    query: GET_REPORT_RESULTSET_TOTAL_AMOUNT,
                    variables: {
                        reportId: this.report.id,
                        conditions: {
                            columnConditions: this.resultsConditions,
                            filterMenuConditions: this.filterMenuConditions,
                        },
                        orderBy: [],
                        options: {
                            groupOnLabel: groupOnLabel,
                            labelColumnId: labelColumnId,
                        },
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((result) => {
                    this.resultsTotalAmount = result.data.resultsetTotalAmount || 0;
                });
        },
        getFilterOptions() {
            const fields = this.report.configuration.projection
                .filter((projection) => projection.id && projection.filterable)
                .map((projection) => {
                    let search = '';

                    if (this.resultsFilterOptionsSearch.column === projection.id)
                        search = this.resultsFilterOptionsSearch.searchQuery;

                    return {
                        field: projection.id,
                        search,
                    };
                });

            this.$apollo
                .query({
                    query: GET_REPORT_RESULTSET_FILTER_OPTIONS,
                    variables: {
                        reportId: this.report.id,
                        fields,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((result) => {
                    this.resultsFilterOptions = result.data.resultsetFilterOptions;

                    this.setTableColumns();
                    this.filterMenuLoaded = true;
                })
                .catch((error) => {
                    console.log('error', error);
                });
        },
        setTableColumns() {
            if (!this.report.configuration) return;

            const columns = this.report.configuration.projection
                .filter((projection) => projection.visible)
                .map((projection) => {
                    return {
                        label: this.$t(this.getColumnLabel(projection)),
                        field: `display-${projection.id}`,
                        width:
                            projection.type === 'average-forms-over-formtype-project' ||
                            projection.type === 'projectName' ||
                            projection.type === 'formName'
                                ? '350px;'
                                : projection.type === 'total-average-forms-over-formtype-project'
                                ? '150px;'
                                : '200px;',
                        projectionId: projection.id,
                    };
                });

            this.columns = columns;
        },
        getColumnLabel(projection) {
            switch (projection.type) {
                case 'indicator':
                    return projection.indicator.name;
                case 'indicatorBetterPerformance':
                    return projection.indicator.name;
                case 'average-form-over-formtype-project':
                    return projection.formName;
                case 'average-forms-over-formtype-project':
                    return 'Prestatie';
                case 'total-average-forms-over-formtype-project':
                case 'average-form-over-indicators':
                    return 'Gemiddelde score';
                case 'projectStatus':
                    return 'Projectstatus';
                case 'average-indicators-over-form':
                    return projection.labelName;
                case 'projectName':
                    return 'Project';
                case 'formName':
                    return 'Formulier';
                default:
                    return projection.type;
            }
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        getOrganisationFormTemplatesOptions() {
            const where = { isFormTemplateResultset: true };

            let formTemplateId = undefined;

            this.$apollo
                .query({
                    query: REPORTS_MINIMAL,
                    variables: {
                        where,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((result) => {
                    const formTemplateReports = result.data.reports;
                    this.organisationFormTemplatesOptions = formTemplateReports
                        .filter((formTemplate) => !formTemplate.name.includes('reference-'))
                        .map((report) => {
                            
                            if (report.configuration.conditions.formTemplates instanceof Array) {
                                formTemplateId = report.configuration.conditions.formTemplates[0]?.value;
                            }

                            return {
                                label: report.name,
                                value: formTemplateId
                            };
                        });
                    this.selectedFormTemplateId = this.$route.params.id.substring(14);
                });
        },
        async handleFormTemplateSelected(formTemplateId) {
            if (!formTemplateId) return;

            if (formTemplateId === this.$route.params.id) return;

            this.handleStartLoading();

            if (this.$router.currentRoute.path === `/reports/form-template-${formTemplateId}?name=${this.selectedFormTemplate}`) {
                this.handleStopLoading();
                return;
            }
            this.$router.push(`/reports/form-template-${formTemplateId}?name=${this.selectedFormTemplate}`);
        },
        updateReportConfiguration(updatedReport) {
            this.$apollo
                .mutate({
                    mutation: REPORT_UPDATE_CONFIGURATION,
                    variables: {
                        id: updatedReport.id,
                        configuration: updatedReport.configuration,
                    },
                })
                .then((result) => {})
                .catch((error) => {
                    this.$store.commit('notify', extractError(error));
                });
        },
        async handleGetReport() {
            if (!this.initializedTableLength || this.loading) return;
            this.loading = true;
            this.filterMenuLoaded = false;

            const syncedWithConfiguration = await this.reportIsSynced();
            if (!syncedWithConfiguration) await this.rebuildResultSet();

            await this.getReport(true);

            if (this.report.isFormTemplateResultset) await this.getOrganisationFormTemplatesOptions();

            this.loading = false;
        },
    },
    watch: {
        '$route.path': function () {
            this.handleGetReport();
        }
    },
    computed: {
        canEdit: function () {
            const { id: userId } = this.$store.getters.getUser;

            if (!this.report.users) return false;

            const thisUser = this.report.users.find((user) => user.user.id === userId);

            if (!thisUser) return false;

            const hasOnlyViewPermission = thisUser.permissions.indexOf('view') !== -1;

            return !hasOnlyViewPermission;
        },
        filtersDisabled: function () {
            return this.showBuildingReportPopup || this.buildingReport || !this.hasFilterOptions;
        },
        selectedFormTemplate: function () {
            const formTemplate = this.organisationFormTemplatesOptions.filter(
                (formTemplate) => formTemplate.value === this.selectedFormTemplateId
            );
            if (formTemplate.length > 0) {
                return formTemplate[0].label;
            } else {
                return null;
            }
        },
    },
    mounted() {
        this.paginatedTableLength = getCalculatedRows(10);
        this.loadingTableRows = this.paginatedTableLength;
        this.initializedTableLength = true;
        this.handleGetReport();
    }
};
</script>

<style lang="scss" scoped>
.page-wrapper {
    --back-icon-offset: 24px;
    position: relative;
    margin-left: var(--back-icon-offset);
    min-height: 520px;

    .page-padding {
        padding-bottom: 0px;
    }

    .header {
        display: flex;
        justify-content: space-between;

        .left {
            display: flex;
            .title {
                display: flex;
                align-items: center;
                margin-left: calc(-1 * var(--back-icon-offset));

                h2 {
                    font-weight: 500;
                    font-size: 19px;
                    line-height: 19px;
                }
                .back {
                    transform: translate(0, 1px);
                }
            }

            .subtitle {
                margin: 0 0 0 20px;
                position: relative;
                display: flex;
                gap: 8px;

                .snapshot-button {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .right {
            display: flex;
            align-items: center;
            gap: 12px;
            position: relative;

            .button-container {
                position: relative;

                .filters-tag {
                    position: absolute;
                    display: grid;
                    place-items: center;
                    font-weight: 500;
                    color: white;
                    top: -6px;
                    right: -6px;
                    font-size: 10px;
                    background-color: #fd7e14;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    pointer-events: none;
                    user-select: none;
                    transition: 0.2s ease;
                }
            }
        }
    }

    .body {
        .table-wrapper {
            margin-block: 32px;

            .total-score {
                display: flex;
                justify-content: flex-end;
                margin-right: 20px;
            }

            .bold-number {
                p {
                    font-weight: 500 !important;
                }
            }

            .indicator-list {
                display: block;
                padding: 10px 0 10px 30px;
                p:before {
                    content: '• ';
                }
            }

            .label-list {
                display: flex;
                gap: 6px;
                align-items: center;

                p {
                    max-width: 300px;
                }

                .chevron-icon {
                    scale: 0.8;
                    transition: 0.3s ease;
                }

                &.label-list-opened {
                    .chevron-icon {
                        rotate: 180deg;
                    }

                    p {
                        font-weight: 500;
                    }
                }
            }

            .row-text {
                padding-right: 20px;
            }
        }
    }
}

.building-popup {
    display: flex;
    flex-direction: column;
    gap: 26px;

    .footer {
        display: flex;
        justify-content: flex-end;
    }
}

.initiator-badge-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .tooltip {
        margin-left: 2px;

        .initiator-badge {
            translate: 0 1px;
            scale: 1.1;
        }
    }

    .organisation-name {
        width: fit-content;
    }
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;

    &:hover {
        background: rgb(130, 130, 130);
    }
}
</style>
