var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['q-dropdown', _vm.variation, _vm.disabled ? 'disabled' : ''],on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;$event.preventDefault();}}},[_c('label',[_c('p',[_vm._t("default")],2)]),_vm._v(" "),_c('div',{class:['dropdown'],attrs:{"id":_vm.id}},[_c('input',{class:[
                'input',   
                _vm.size, 
                _vm.panelOpened ? 'with-panel' : '', 
                _vm.avatars ? 'avatar-input' : '', 
                _vm.noBorder ? 'no-border' : '',
                _vm.dropUp ? 'drop-up' : 'drop-down',
                { loading: _vm.loading, 'show-placeholder': !_vm.getValueLabel }
            ],attrs:{"type":"select","disabled":_vm.disabled || _vm.options.length === 0,"placeholder":_vm.computedPlaceholder,"readonly":"","data-testid":"q-dropdown"},on:{"click":_vm.togglePanel}}),_vm._v(" "),(!_vm.loading && _vm.currentOption && !_vm.multiple && !_vm.autocompleteQuery)?_c('div',{staticClass:"input-value"},[_c('p',[_vm._v(_vm._s(_vm.currentOption.label))]),_vm._v(" "),(_vm.currentOption.tag)?_c('q-tag',{staticClass:"option-tag",attrs:{"size":"small","variation":"default"}},[_vm._v(_vm._s(_vm.currentOption.tag))]):_vm._e()],1):(!_vm.loading && _vm.value && _vm.multiple && !_vm.autocompleteQuery)?_c('div',{staticClass:"input-value"},[_c('p',[_vm._v(_vm._s(_vm.getValueLabel))])]):_vm._e(),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"loader-wrapper"},[_c('div',{staticClass:"loader"})]):_vm._e(),_vm._v(" "),(_vm.autocomplete && _vm.panelOpened)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.autocompleteQuery),expression:"autocompleteQuery"}],class:[
                'input', 
                'autocomplete', _vm.size,
                _vm.avatars ? 'avatar-input' : '',
                { 'show-placeholder': !_vm.getValueLabel }
            ],attrs:{"id":`autocomplete-${_vm.id}`,"type":"text","placeholder":_vm.computedPlaceholder},domProps:{"value":(_vm.autocompleteQuery)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.autocompleteSelect.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.autocompleteQuery=$event.target.value}}}):_vm._e(),_vm._v(" "),(_vm.avatars && !_vm.multiple)?_c('div',{staticClass:"selected-avatar"},[_c('q-avatar',{attrs:{"size":_vm.size === 'small' ? 'xsmall' : 'small',"img":_vm.currentOption.avatar}},[_vm._v(_vm._s(_vm.currentOption.avatarFallback))])],1):_vm._e(),_vm._v(" "),(_vm.panelOpened)?_c('Portal',{attrs:{"to":"dropdown"}},[_c('div',{class:['dropdown-panel', 'panel-' + _vm.size, _vm.dropUp ? 'drop-up' : 'drop-down', _vm.noBorder ? 'no-border' : ''],style:(`
                    --position-left: ${_vm.coordinates.left}px; 
                    --position-top: ${_vm.coordinates.top}px;
                    --element-width: ${_vm.coordinates.width}px;
                    --element-height: ${_vm.coordinates.height}px;
                    --panel-height: ${_vm.panelHeight}px;
                `),attrs:{"id":`q-dropdown-panel-${this.id}`}},[_c('div',{staticClass:"dropdown-options-wrapper"},_vm._l((_vm.dropdownOptions),function(option,key){return _c('div',{key:key,class:[
                        'q-option',
                        'dropdown-option',
                        option.selected ? 'selected' : '',
                        option.isNullValue ? 'null-value' : '',
                        { disabled: option.disabled }
                    ],attrs:{"id":`dropdown-option-${option.value}`,"tabindex":"0"},on:{"click":function($event){return _vm.select(option)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;$event.preventDefault();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.select(option, true)}]}},[_c('q-tooltip',{staticClass:"option-wrapper",class:[
                            'option-' + _vm.size,
                            { 'has-avatar': _vm.avatars }
                        ],attrs:{"disabled":!option.tooltip,"position":"top"},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('p',{domProps:{"innerHTML":_vm._s(option.tooltip)}})]},proxy:true}],null,true)},[_vm._v(" "),_c('div',{staticClass:"vertical-center"},[_c('div',{staticClass:"avatar-wrapper"},[(_vm.avatars)?_c('q-avatar',{staticClass:"avatar",attrs:{"size":_vm.size === 'small' ? 'xsmall' : 'small',"img":option.avatar}},[_vm._v(_vm._s(option.avatarFallback))]):_vm._e()],1),_vm._v(" "),_c('span',[_vm._v(_vm._s(option.label))]),_vm._v(" "),(option.tag)?_c('q-tag',{staticClass:"option-tag",attrs:{"size":"small","variation":"default"}},[_vm._v(_vm._s(option.tag))]):_vm._e()],1)])],1)}),0)])]):_vm._e(),_vm._v(" "),_c('div',{class:['arrow', 'icon-' + _vm.size, _vm.panelOpened ? 'up' : '']},[_c('q-icon',{staticStyle:{"width":"14px","height":"14px"},attrs:{"type":"chevronDown","color":_vm.iconColor,"width":_vm.iconSize,"height":_vm.iconSize}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }