<template>
    <div class="component-point">
        <!-- component not answered -->
        <div v-if="!isValid && componentPoint.able && canSeeIndicator" class="component">
            <p class="name">{{ componentPoint.label }}</p>
            <p v-if="componentPoint.component.description" class="description">{{ componentPoint.component.description }}</p>
            <div class="action-buttons">
                <q-button 
                    size="small" 
                    variation="primary"
                    :disabled="!canAnswerIndicator || componentPoint.disabled || loadingReject"
                    :loading="loadingAccept"
                    @click="handleAnswerIndicator(true)"
                >{{ acceptButtonLabel }}</q-button>
                <q-button
                    size="small" 
                    variation="blank"
                    :disabled="!canAnswerIndicator || componentPoint.disabled || loadingAccept"
                    :loading="loadingReject"
                    @click="handleAnswerIndicator(false)"
                >{{ rejectButtonLabel }}</q-button>
            </div>
        </div>

        <div v-else>
            <q-tag size="medium" :variation="pointVariation">{{ componentPoint.label }}</q-tag>
        </div>

        <!-- confirmation to share project popup -->
        <q-popup v-if="showConfirmShareModal">
            <confirm-share-modal
                @close="showConfirmShareModal = false"
                @confirm="handleAnswerIndicator(true)"
            ></confirm-share-modal>
        </q-popup>
    </div>
</template>

<script>
import { isValidAnswer, executeParentFunction } from '@/assets/js/utils';
import gql from 'graphql-tag';
import { SAVE_VALUE_VALUE } from '@/graphql/mutations';

import ConfirmShareModal from '../../ConfirmShareModal.vue';

export default {
    name: 'component-point',
    components: {
        ConfirmShareModal
    },
    props: {
        point: {
            type: Object,
            required: true
        },
        project: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            componentPoint: this.point,
            loadingAccept: false,
            loadingReject: false,
            showConfirmShareModal: false
        }
    },
    methods: {
        handleAddForm() {
            this.$emit('createIntermediateMeasurement');
        },
        handleAnswerIndicator(value) {
            if(
                this.componentPoint.id === 'crow-point' && 
                this.project.private && 
                value && 
                !this.showConfirmShareModal
            ) return this.showConfirmShareModal = true;

            if(value) this.loadingAccept = true;
            else this.loadingReject = true;

            this.showConfirmShareModal = false;

            if(this.componentPoint.id === 'crow-point') this.setUseCrowFlow(value);
            else this.answerIndicator(value);
        },
        answerIndicator(value) {
            const organisationId = this.$store.getters.getCurrentOrganisation.id;
            const projectJwt = executeParentFunction(this, 'project', 'getProjectJwt');
            const indicator = this.componentPoint.component.indicator;
            indicator.componentId = this.componentPoint.component.id;
            indicator.display = this.componentPoint.component.display;

            this.$apollo
                .mutate({
                    mutation: SAVE_VALUE_VALUE,
                    variables: {
                        componentId: indicator.componentId,
                        responseId: this.project.referencesResponseId,
                        formId: this.project.referencesId,
                        indicatorId: indicator.id,
                        value,
                        organisationId,
                        projectJwt
                    },
                })
                .then(result => {
                    const value = result.data.value_setValue;
                    executeParentFunction(this, 'project', 'onSaveIndicator',{ value, indicator });
                    this.$store.commit('notify', { type: 'success', message: 'Antwoord succesvol opgeslagen' });
                    this.loadingAccept = false;
                    this.loadingReject = false;
                })
                .catch((err) => {
                    this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het opslaan' });
                    this.loadingAccept = false;
                    this.loadingReject = false;
                });
        },
        setUseCrowFlow(useCrowFlow) {
            this.$apollo.mutate({
                mutation: gql`
                    mutation($id: String!, $useCrowFlow: Boolean!) {
                        project_pdUseCrowFlow(id: $id, useCrowFlow: $useCrowFlow)
                    }
                `,
                variables: {
                    id: this.project.id,
                    useCrowFlow
                }
            })
            .then(() => {
                this.loadingAccept = false;
                this.loadingReject = false;
                executeParentFunction(this, 'project', 'onPdUsesCrowFlowChanged', useCrowFlow);
            })
            .catch(error => {
                console.log(error)
                this.loadingAccept = false;
                this.loadingReject = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout' });
            })
        }
    },
    computed: {
        isValid: function() {
            return isValidAnswer(this.componentPoint?.value)
        },
        pointVariation: function() {
            if(Boolean(this.componentPoint?.value)) return 'success'
            return 'default'
        },
        canAnswerIndicator() {
            return executeParentFunction(this, 'project', 'canEditIndicator', this.componentPoint);
        },
        canSeeIndicator() {
            const { protectionLevel } = this.componentPoint;
            const organisationType = this.$store.getters.getOrganisationType;

            return protectionLevel === 'public' || protectionLevel.includes(organisationType)
        },
        acceptButtonLabel() {
            if(this.componentPoint.id.includes('discussed-psu')) return 'Ja, besproken'
            else return 'Ja, toepassen'
        },
        rejectButtonLabel() {
            if(this.componentPoint.id.includes('discussed-psu')) return 'Nee, niet besproken'
            else return 'Nee, niet toepassen'
        }
    },
    watch: {
        point: {
            handler() {
                this.componentPoint = this.point;
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../../components/qds/assets/style/_variables.scss';
@import '../../../../../components/qds/assets/style/fonts/fonts.css';

.component-point {
    flex-grow: 1;

    .component {
        margin-top: 10px;

        .name {
            font-weight: 500;
            color: $color-grey-9;
            margin-bottom: 8px;
        }
        .description {
            margin-bottom: 16px;
        }

        .action-buttons {
            display: flex;
            align-items: center;
            gap: 18px;
        }
    }
}

</style>