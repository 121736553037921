// components/index.js
import Q2fa from './Q2FA.vue';
import QAlert from './QAlert.vue';
import QBadge from './QBadge.vue';
import QButton from './QButton.vue';
import QIconButton from './QIconButton.vue';
import QCheckbox from './QCheckbox.vue';
import QIcon from './QIcon.vue';
import QInput from './QInput.vue';
import QClearInput from './QClearInput.vue';
import QRadio from './QRadio.vue';
import QSection from './QSection.vue';
import QSelect from './QSelect.vue';
import QSwitch from './QSwitch.vue';
import QTag from './QTag.vue';
import QText from './QText.vue';
import QToggle from './QToggle.vue';
import QAvatar from './QAvatar.vue';
import QDropdown from './QDropdown.vue';
import QOption from './QOption.vue';
import QOptions from './QOptions.vue';
import QTable from './QTable';
import QTableWithPagination from './QTableWithPagination';
import QSlider from './QSlider';
import QTooltip from './QTooltip';
import QPageItem from './QPageItem.vue';
import QQuestion from './QQuestion';
import QStep from './QStep';
import QZeroState from './QZeroState';
import QPopup from './QPopup';
import QArrowBox from './QArrowBox'
import QTimeline from './QTimeline';
import QLabelSwitch from './QLabelSwitch';
import QColorCard from './QColorCard';
import QLogoCard from './QLogoCard';
import QBranchCard from './QBranchCard';
import QScoreOptions from './QScoreOptions';
import QCheckboxOptions from './QCheckboxOptions';
import QRadioOptions from './QRadioOptions';

import QFilter from './patterns/QFilter.vue';
import QOrganizationInfo from './patterns/QOrganizationInfo.vue';
import QProfileDropdown from './patterns/QProfileDropdown.vue';
import QProfileInfo from './patterns/QProfileInfo.vue';
import QNavbar from './patterns/QNavbar.vue';
import QTopbar from './patterns/QTopbar.vue';
import QSearchbox from './patterns/QSearchbox.vue';
import QPageFlow from './patterns/QPageFlow.vue';
import QTokenText from './patterns/QTokenText';
import QPill from './QPill.vue';
import QDatePicker from './QDatePicker.vue';
import QAvatarGroup from './QAvatarGroup.vue';
import QSelectOrganisation from './QSelectOrganisation.vue';
import QSelectOrganisationCrow from './QSelectOrganisationCrow.vue';

import QCircleDiagram from './statistics/QCircleDiagram.vue';
import QScoreText from './statistics/QScoreText.vue';

import SlideTransition from './transitions/SlideTransition.vue';
import SlideTransitionGroup from './transitions/SlideTransitionGroup.vue';
import JumpTransition from './transitions/JumpTransition.vue';
import CollapseTransition from './transitions/CollapseTransition.vue';

const components = [
    Q2fa,
    QAlert,
    QFilter,
    QBadge,
    QButton,
    QIconButton,
    QCheckbox,
    QIcon,
    QInput,
    QClearInput,
    QOrganizationInfo,
    QProfileDropdown,
    QProfileInfo,
    QRadio,
    QSection,
    QSelect,
    QSwitch,
    QTag,
    QText,
    QToggle,
    QAvatar,
    QNavbar,
    QTopbar,
    QSearchbox,
    QDropdown,
    QOption,
    QOptions,
    QTable,
    QTableWithPagination,
    QSlider,
    QTooltip,
    QPageFlow,
    QPageItem,
    QQuestion,
    QStep,
    QZeroState,
    QPill,
    QDatePicker,
    QAvatarGroup,
    QSelectOrganisation,
    QPopup,
    QSelectOrganisationCrow,
    QFilter,
    QArrowBox,
    QTimeline,
    QLabelSwitch,
    QOptions,
    QTokenText,
    QColorCard,
    QLogoCard,
    QBranchCard,
    QScoreOptions,
    QCheckboxOptions,
    QRadioOptions,
    QCircleDiagram,
    QScoreText,
    SlideTransition,
    SlideTransitionGroup,
    JumpTransition,
    CollapseTransition
];

const install = function(Vue) {
    components.forEach(component => {
        Vue.component(component.name, component);
    });
};

export default install;
