// Parts of answervalues that should be displayed in bold (but which are not stored in db as html). 
// If a word/phrase occurs multiple times within a certain answer value, its surrounding context 
// can be provided in this way '<before>_<bold part>_<after>'. Make sure this matches the string exactly,
// including spaces/punctuation, so e.g. 'over _the_ lazy' will make only the second occurence of 'the'
// bold in 'the quick brown fix jumped over the lazy dog.'
const boldParts = {
    'crow-services-2023-client-1-d': ['tijdig en volgens afspraak', 'ruim voldoende'],
    'crow-services-2023-client-1-c': ['meestal tijdig en volgens afspraak', 'voldoende'],
    'crow-services-2023-client-1-b': ['regelmatig niet tijdig en/of niet volgens afspraak', 'matige'],
    'crow-services-2023-client-1-a': ['vaak niet tijdig en/of niet volgens afspraak', 'onvoldoende'],
    'crow-services-2023-client-2-d': ['goed en tijdig', ' constructief', 'niet of nauwelijks'],
    'crow-services-2023-client-2-c': ['meestal goed en tijdig', 'meestal constructief', 'af en toe'],
    'crow-services-2023-client-2-b': ['regelmatig niet goed en/of niet tijdig', 'regelmatig niet constructief', 'hier _regelmatig_ om'],
    'crow-services-2023-client-2-a': ['vaak niet goed en/of niet tijdig', 'opdrachtgever _vaak_ niet', 'hier _vaak_ om'],
    'crow-services-2023-client-3-d': ['ruim voldoende', 'geen'],
    'crow-services-2023-client-3-c': ['voldoende', 'incidenteel'],
    'crow-services-2023-client-3-b': ['onvoldoende', 'regelmatig'],
    'crow-services-2023-client-3-a': ['nauwelijks', 'regelmatig tot vaak'],
    'crow-services-2023-client-4-d': ['duidelijke'],
    'crow-services-2023-client-4-c': ['grotendeels', 'duidelijke', 'af en toe'],
    'crow-services-2023-client-4-b': ['beperkt', 'duidelijke', 'regelmatig'],
    'crow-services-2023-client-4-a': ['niet of nauwelijks', 'duidelijke', 'vaak'],
    'crow-services-2023-client-5-d': ['ruim voldoende', 'niet of nauwelijks'],
    'crow-services-2023-client-5-c': ['voldoende', 'af en toe'],
    'crow-services-2023-client-5-b': ['onvoldoende', 'regelmatig'],
    'crow-services-2023-client-5-a': ['niet of nauwelijks', 'regelmatig tot vaak'],
    'crow-services-2023-client-6-d': ['tijdig en op eigen initiatief', 'concrete voorstellen ', 'ruim voldoende'],
    'crow-services-2023-client-6-c': ['meestal tijdig en op eigen initiatief', 'voldoende concrete voorstellen', 'houdt _voldoende_ rekening'],
    'crow-services-2023-client-6-b': ['regelmatig niet tijdig en/of niet op eigen initiatief', 'matig concrete voorstellen en/of houdt matig'],
    'crow-services-2023-client-6-a': ['vaak niet tijdig en/of niet op eigen initiatief', 'geen of onvoldoende concrete voorstellen en/of houdt onvoldoende'],
    'crow-services-2023-client-7-d': ['transparant', 'tijdig'],
    'crow-services-2023-client-7-c': ['grotendeels transparant', 'meestal tijdig', 'voldoende'],
    'crow-services-2023-client-7-b': ['beperkt transparant ', 'regelmatig niet tijdig', 'onvoldoende'],
    'crow-services-2023-client-7-a': ['niet transparant', 'vaak niet tijdig', 'niet of nauwelijks'],
    'crow-services-2023-contractor-1-d': ['tijdig en volgens afspraak', 'goed'],
    'crow-services-2023-contractor-1-c': ['meestal tijdig en volgens afspraak', 'voldoende'],
    'crow-services-2023-contractor-1-b': ['regelmatig niet tijdig en/of niet volgens afspraak', 'onvoldoende'],
    'crow-services-2023-contractor-1-a': ['niet tijdig en/of niet volgens afspraak', 'niet of nauwelijks'],
    'crow-services-2023-contractor-2-d': ['ruim voldoende', 'geen'],
    'crow-services-2023-contractor-2-c': ['voldoende', 'incidenteel'],
    'crow-services-2023-contractor-2-b': ['onvoldoende', 'regelmatig'],
    'crow-services-2023-contractor-2-a': ['nauwelijks', 'regelmatig tot vaak'],
    'crow-services-2023-contractor-3-d': ['voortdurend'],
    'crow-services-2023-contractor-3-c': ['regelmatig'],
    'crow-services-2023-contractor-3-b': ['onvoldoende'],
    'crow-services-2023-contractor-3-a': ['niet of nauwelijks'],
    'crow-services-2023-contractor-4-d': ['open houding', 'constructief'],
    'crow-services-2023-contractor-4-c': ['meestal', 'open houding', 'voldoende constructief'],
    'crow-services-2023-contractor-4-b': ['niet altijd', 'open houding', 'onvoldoende constructief'],
    'crow-services-2023-contractor-4-a': ['voorstellen _niet of nauwelijks_ met', 'open houding', 'niet of nauwelijks constructief'],
    'crow-services-2023-contractor-5-d': ['goed'],
    'crow-services-2023-contractor-5-c': ['voldoende'],
    'crow-services-2023-contractor-5-b': ['onvoldoende'],
    'crow-services-2023-contractor-5-a': ['niet of nauwelijks'],
    'crow-services-2023-contractor-6-d': ['goed', 'plezierige en effectieve'],
    'crow-services-2023-contractor-6-c': ['voldoende', 'communiceert _meestal_ op', 'plezierige en effectieve', 'is _meestal_ open'],
    'crow-services-2023-contractor-6-b': ['onvoldoende', 'communiceert _regelmatig niet_ op', 'plezierige en effectieve', 'is _regelmatig niet_ open'],
    'crow-services-2023-contractor-6-a': ['niet of nauwelijks', 'communiceert _vaak niet_ op', 'plezierige en effectieve', 'was _vaak niet_ open'],
    'crow-services-2023-contractor-7-d': ['goed'],
    'crow-services-2023-contractor-7-c': ['voldoende'],
    'crow-services-2023-contractor-7-b': ['onvoldoende'],
    'crow-services-2023-contractor-7-a': ['niet of nauwelijks'],
    'crow-works-2023-client-1-d': ['ruim op tijd', 'en _tijdig_ geactualiseerd', 'goed en tijdig', 'niet of nauwelijks'],
    'crow-works-2023-client-1-c': ['op tijd', 'meestal tijdig', 'meestal goed en tijdig', 'af en toe'],
    'crow-works-2023-client-1-b': ['te laat', 'regelmatig niet tijdig', 'regelmatig niet goed en/of niet tijdig'],
    'crow-works-2023-client-1-a': ['niet of veel te laat', 'vaak niet tijdig', 'vaak niet goed en/of niet tijdig', 'hier _vaak_ om'],
    'crow-works-2023-client-2-d': ['tijdig en volgens afspraak', 'ruim voldoende', 'niet of nauwelijks'],
    'crow-works-2023-client-2-c': ['meestal tijdig en volgens afspraak', 'voldoende', 'af en toe'],
    'crow-works-2023-client-2-b': ['regelmatig niet tijdig en/of niet volgens afspraak', 'regelmatig onvoldoende', 'hier _regelmatig_ om'],
    'crow-works-2023-client-2-a': ['vaak niet tijdig en/of niet volgens afspraak', 'veelal onvoldoende', 'hier _vaak_ om'],
    'crow-works-2023-client-3-d': ['ruim voldoende', 'geen'],
    'crow-works-2023-client-3-c': ['voldoende', 'incidenteel'],
    'crow-works-2023-client-3-b': ['onvoldoende', 'regelmatig'],
    'crow-works-2023-client-3-a': ['nauwelijks', 'regelmatig tot vaak'],
    'crow-works-2023-client-4-d': ['duidelijke'],
    'crow-works-2023-client-4-c': ['grotendeels', 'duidelijke', 'af en toe'],
    'crow-works-2023-client-4-b': ['beperkt', 'duidelijke', 'regelmatig'],
    'crow-works-2023-client-4-a': ['niet of nauwelijks', 'duidelijke', 'vaak'],
    'crow-works-2023-client-5-d': ['voortdurend', 'volledige'],
    'crow-works-2023-client-5-c': ['regelmatig', 'voldoende'],
    'crow-works-2023-client-5-b': ['onvoldoende', 'beperkte mate van'],
    'crow-works-2023-client-5-a': ['niet of nauwelijks', 'zeer beperkte mate van'],
    'crow-works-2023-client-6-d': ['ruim voldoende', 'niet of nauwelijks'],
    'crow-works-2023-client-6-c': ['voldoende', 'af en toe'],
    'crow-works-2023-client-6-b': ['onvoldoende', 'regelmatig'],
    'crow-works-2023-client-6-a': ['niet of nauwelijks', 'regelmatig tot vaak'],
    'crow-works-2023-client-7-d': ['goed', 'voorkomen'],
    'crow-works-2023-client-7-c': ['voldoende', 'meestal voorkomen'],
    'crow-works-2023-client-7-b': ['onvoldoende', 'regelmatig niet voorkomen'],
    'crow-works-2023-client-7-a': ['niet of nauwelijks', 'vaak niet voorkomen'],
    'crow-works-2023-client-8-d': ['goed', 'voorkomen'],
    'crow-works-2023-client-8-c': ['voldoende', 'meestal voorkomen'],
    'crow-works-2023-client-8-b': ['onvoldoende', 'regelmatig niet voorkomen'],
    'crow-works-2023-client-8-a': ['niet of nauwelijks', 'vaak niet voorkomen'],
    'crow-works-2023-client-9-d': ['denkt _goed_ mee', 'effectief'],
    'crow-works-2023-client-9-c': ['meestal goed', 'meestal effectief'],
    'crow-works-2023-client-9-b': ['onvoldoende', 'regelmatig niet effectief'],
    'crow-works-2023-client-9-a': ['vaak niet goed', 'vaak niet effectief'],
    'crow-works-2023-client-10-d': ['duidelijke', 'goed en tijdig', 'niet of nauwelijks'],
    'crow-works-2023-client-10-c': ['grotendeels', 'duidelijke verwachtingen', 'meestal goed en tijdig', 'af en toe'],
    'crow-works-2023-client-10-b': ['beperkt', 'duidelijke', 'regelmatig niet goed en/of tijdig', 'hier _regelmatig_ om'],
    'crow-works-2023-client-10-a': ['niet of nauwelijks', 'duidelijke', 'regelmatig tot vaak niet goed en/of tijdig', 'hier _regelmatig tot vaak_ om'],
    'crow-works-2023-client-11-d': ['transparant', 'tijdig'],
    'crow-works-2023-client-11-c': ['grotendeels transparant', 'meestal tijdig', 'voldoende'],
    'crow-works-2023-client-11-b': ['beperkt transparant ', 'regelmatig niet tijdig', 'onvoldoende'],
    'crow-works-2023-client-11-a': ['niet transparant', 'vaak niet tijdig', 'niet of nauwelijks'],
    'crow-works-2023-client-12-d': ['goed', 'plezierige en effectieve', 'open'],
    'crow-works-2023-client-12-c': ['voldoende', 'communiceert _meestal_ op', 'plezierige en effectieve', 'is _meestal_ open'],
    'crow-works-2023-client-12-b': ['onvoldoende', 'communiceert _regelmatig niet_ op', 'plezierige en effectieve ', 'regelmatig niet open'],
    'crow-works-2023-client-durability-d': ['tijdig en op eigen initiatief', 'concrete voorstellen ', 'ruim voldoende'],
    'crow-works-2023-client-durability-c': ['meestal tijdig en op eigen initiatief', 'voldoende concrete voorstellen', 'houdt _voldoende_ rekening'],
    'crow-works-2023-client-durability-b': ['regelmatig niet tijdig en/of niet op eigen initiatief', 'matig concrete voorstellen en/of houdt matig'],
    'crow-works-2023-client-durability-a': ['vaak niet tijdig en/of niet op eigen initiatief ', 'geen of onvoldoende concrete voorstellen en/of houdt onvoldoende'],
    'crow-works-2023-contractor-1-d': ['tijdig en volgens afspraak', 'goed'],
    'crow-works-2023-contractor-1-c': ['meestal tijdig en volgens afspraak', 'voldoende'],
    'crow-works-2023-contractor-1-b': ['regelmatig niet tijdig en/of niet volgens afspraak', 'onvoldoende'],
    'crow-works-2023-contractor-1-a': ['niet tijdig en/of niet volgens afspraak', 'niet of nauwelijks'],
    'crow-works-2023-contractor-2-d': ['ruim voldoende', 'geen'],
    'crow-works-2023-contractor-2-c': ['voldoende', 'incidenteel'],
    'crow-works-2023-contractor-2-b': ['onvoldoende', 'regelmatig'],
    'crow-works-2023-contractor-2-a': ['nauwelijks', 'regelmatig tot vaak'],
    'crow-works-2023-contractor-3-d': ['voortdurend'],
    'crow-works-2023-contractor-3-c': ['regelmatig'],
    'crow-works-2023-contractor-3-b': ['onvoldoende'],
    'crow-works-2023-contractor-3-a': ['niet of nauwelijks'],
    'crow-works-2023-contractor-4-d': ['open houding', 'constructief'],
    'crow-works-2023-contractor-4-c': ['meestal', 'open houding', 'voldoende constructief'],
    'crow-works-2023-contractor-4-b': ['niet altijd', 'open houding', 'onvoldoende constructief'],
    'crow-works-2023-contractor-4-a': ['voorstellen _niet of nauwelijks_ met', 'open houding', 'niet of nauwelijks constructief'],
    'crow-works-2023-contractor-5-d': ['goed'],
    'crow-works-2023-contractor-5-c': ['voldoende'],
    'crow-works-2023-contractor-5-b': ['onvoldoende'],
    'crow-works-2023-contractor-5-a': ['niet of nauwelijks'],
    'crow-works-2023-contractor-6-d': ['goed', 'plezierige en effectieve'],
    'crow-works-2023-contractor-6-c': ['voldoende', 'communiceert _meestal_ op', 'plezierige en effectieve', 'is _meestal_ open'],
    'crow-works-2023-contractor-6-b': ['onvoldoende', 'communiceert _regelmatig niet_ op', 'plezierige en effectieve', 'is _regelmatig niet_ open'],
    'crow-works-2023-contractor-6-a': ['niet of nauwelijks', 'communiceert _vaak niet_ op', 'plezierige en effectieve', 'was _vaak niet_ open'],
    'crow-works-2023-contractor-7-d': ['goed'],
    'crow-works-2023-contractor-7-c': ['voldoende'],
    'crow-works-2023-contractor-7-b': ['onvoldoende'],
    'crow-works-2023-contractor-7-a': ['niet of nauwelijks'],    
    'crow-bouwteams-2024-client-1-a': ['niet actief', 'vaak niet tijdig', 'vaak niet volgens afspraak'],
    'crow-bouwteams-2024-client-1-b': ['weinig actief', 'regelmatig niet tijdig', 'regelmatig niet volgens afspraak'],
    'crow-bouwteams-2024-client-1-c': ['actief', 'meestal tijdig', 'volgens afspraak'],
    'crow-bouwteams-2024-client-1-d': ['zeer actief', 'tijdig', 'volgens afspraak'],
    'crow-bouwteams-2024-client-2-d': ['ruim voldoende'],
    'crow-bouwteams-2024-client-2-c': ['voldoende'],
    'crow-bouwteams-2024-client-2-b': ['soms onvoldoende'],
    'crow-bouwteams-2024-client-2-a': ['onvoldoende'],
    'crow-bouwteams-2024-client-3-d': ['ruim op tijd', 'op eigen initiatief', 'concrete voorstellen', 'goed'],
    'crow-bouwteams-2024-client-3-c': ['tijdig', 'grotendeels op eigen initiatief', 'voldoende concrete voorstellen', 'hierbij _voldoende_ rekening'],
    'crow-bouwteams-2024-client-3-b': ['soms niet tijdig', 'soms niet op eigen initiatief', 'weinig concrete voorstellen', 'soms onvoldoende'],
    'crow-bouwteams-2024-client-3-a': ['vaak niet tijdig', 'vaak niet op eigen initiatief', 'niet of nauwelijks concrete voorstellen', 'onvoldoende'],
    'crow-bouwteams-2024-client-4-d': ['ruim voldoende', 'niet of nauwelijks'],
    'crow-bouwteams-2024-client-4-c': ['voldoende', 'af en toe'],
    'crow-bouwteams-2024-client-4-b': ['onvoldoende', 'regelmatig'],
    'crow-bouwteams-2024-client-4-a': ['niet of nauwelijks', 'regelmatig tot vaak'],
    'crow-bouwteams-2024-client-5-d': ['grote mate van', 'ruim op tijd'],
    'crow-bouwteams-2024-client-5-c': ['voldoende mate van', 'op tijd'],
    'crow-bouwteams-2024-client-5-b': ['soms onvoldoende mate van', 'soms niet op tijd'],
    'crow-bouwteams-2024-client-5-a': ['onvoldoende mate van', 'vaak niet op tijd'],
    'crow-bouwteams-2024-client-6-d': ['goed', 'open'],
    'crow-bouwteams-2024-client-6-c': ['voldoende', 'meestal open', 'communiceert _meestal_ op'],
    'crow-bouwteams-2024-client-6-b': ['onvoldoende', 'regelmatig niet open', 'communiceert _regelmatig niet_ op'],
    'crow-bouwteams-2024-client-6-a': ['niet of nauwelijks', 'vaak niet open', 'communiceert _vaak niet_ op'],
}

const getSentence = (value) => {
    let sentence = value.label;

    if (value.id in boldParts) {
        boldParts[value.id].forEach(part => {
            const pieces = part.split('_');
            if (pieces.length === 1) {
                sentence = sentence.replace(part, `<span style=\"font-weight:500\">${part}</span>`);
            } else if (pieces.length === 3) {
                sentence = sentence.replace(pieces.join(''), `${pieces[0]}<span style=\"font-weight:500\">${pieces[1]}</span>${pieces[2]}`);
            }
        });
    }

    return sentence;
}

export default getSentence;