<template>
    <div class="page-padding">
        <div class="header" v-if="organisation">
            <div class="title">
                <q-avatar class="avatar" size="medium" :img="organisation.logo"
                    ><span style="font-size:20px">{{ organisation.name.substr(0, 1).toUpperCase() }}</span></q-avatar
                >
                <h2>{{ organisation.name }}</h2>
            </div>
            <div class="orgInfo">
                <div style="display:flex">
                    <div class="left" v-if="organisation.address && organisation.address.line1">
                        <p><b>Hoofdvestiging</b></p>
                        <p>{{ organisation.address.line1 }}</p>
                        <p>
                            {{
                                organisation.address.line2 ? organisation.address.line2 : '' + organisation.address.city
                            }}
                        </p>
                    </div>
                    <div v-else>
                        <p><b>Hoofdvestiging</b></p>
                        <p>Geen informatie beschikbaar</p>
                    </div>
                    <div class="right">
                        <p><b>Juridische info</b></p>
                        <p>KvK: {{ organisation.legal.registrationId }}</p>
                    </div>
                </div>
                <div class="marks">
                    <div class="mark">
                        <p>Diensten:</p>
                        <q-tag
                            :emoji="getScore(organisation.servicesAverage)"
                            :emojiValue="organisation.servicesAverage"
                        ></q-tag>
                    </div>
                    <div class="mark">
                        <p>Werken:</p>
                        <q-tag
                            :emoji="getScore(organisation.worksAverage)"
                            :emojiValue="organisation.worksAverage"
                        ></q-tag>
                    </div>
                </div>
            </div>
            <div class="projectsDiv">
                <h3>Projecten</h3>
                <q-table
                    class="projectsTable"
                    :data="projects"
                    :columns="columns"
                    clickable
                    :loading="loadingProjects"
                    :loadingRows="30"
                >
                    <template v-slot:row="{ row, column }">
                        <span v-if="column == 'norm' && row.norm == 'crow'">
                            <img
                                class="crow-logo"
                                src="https://storage.googleapis.com/qfact_uploads_production/misc/crow-logo.png"
                                alt="crow-logo"
                            />
                        </span>
                        <span v-else-if="column == 'score'">
                            <q-tag :emoji="getScore(row.average)" :emojiValue="row.average"></q-tag>
                        </span>
                        <span v-else :class="column == 'name' ? 'bold' : ''">{{ row[column] }}</span>
                    </template>
                </q-table>
            </div>
        </div>
    </div>
</template>

<script>
import { ORGANISATION, GET_PROJECT_READMODELS } from '@/graphql/queries';
import { getDate, getSmileyForCrowScore } from '../../assets/js/utils';

export default {
    name: 'Company',
    data() {
        return {
            organisation: null,
            organisationId: this.$route.params.id,
            projects: [],
            loadingProjects: false,
            columns: [
                {
                    field: 'norm',
                    label: 'Type',
                    width: '10%',
                    loadingWidth: '60%'
                },
                {
                    field: 'project',
                    label: 'Project',
                    width: '40%'
                },
                {
                    field: 'client',
                    label: 'Opdrachtgever',
                    width: '25%'
                },
                {
                    field: 'date',
                    label: 'Datum',
                    width: '15%'
                },
                {
                    field: 'score',
                    label: 'Eindcijfer',
                    width: '10%',
                    loadingStyle: 'badge'
                }
            ]
        };
    },
    created() {
        this.getOrganisation();
        this.getProjects();
    },
    methods: {
        getScore(score) {
            return getSmileyForCrowScore(score);
        },
        getOrganisation() {
            this.$apollo
                .query({
                    query: ORGANISATION,
                    variables: {
                        organisationId: this.organisationId
                    }
                })
                .then(result => {
                    this.organisation = result.data.organisation;
                })
                .catch(err => {});
        },
        getProjects() {
            this.loadingProjects = true;

            this.$apollo
                .query({
                    query: GET_PROJECT_READMODELS,
                    variables: {
                        where: {
                            AND: [
                                {
                                    info_contractorId: this.organisationId
                                },
                                { 
                                    info_status: 'finished' 
                                }
                            ]
                        }
                    },
                    fetchPolicy: 'no-cache'
                })
                .then(result => {
                    this.projects = result.data.projectReadmodels.map(readmodel => {
                        return {
                            id: readmodel.id,
                            norm: readmodel.project.norm,
                            project: readmodel.project.name,
                            projectId: readmodel.project.id,
                            client: readmodel.project.client ? readmodel.project.client.name : '',
                            contractor: readmodel.project.contractor ? readmodel.project.contractor.name : '',
                            date: getDate(readmodel.project.creationDate),
                            average: readmodel.clientAverage ? readmodel.clientAverage.toFixed(1) : null,
                            to: `/projects/${readmodel.project.id}?title=${readmodel.project.name}`
                        };
                    });
                    this.loadingProjects = false;
                })
                .catch(error => {
                    this.loadingProjects = false;
                    console.log(error);
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.wrapper {
    margin: 100px 40px 40px 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 4px -3px rgb(100, 100, 100);
}

.header {
    .title {
        display: flex;
        align-items: center;

        .avatar {
            margin-right: 12px;
        }

        h2 {
            font-weight: 700;
        }
    }

    .orgInfo {
        display: flex;
        justify-content: space-between;

        margin: 18px 0 0 64px;

        .right {
            margin-left: 180px;
        }

        .marks {
            .mark {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                margin-bottom: 12px;

                p {
                    margin-right: 4px;
                }
            }
        }
    }

    .projectsDiv {
        margin-top: 32px;

        .projectsTable {
            margin-top: 46px;
        }
    }
}

.crow-logo {
    height: 16px;
}
</style>
