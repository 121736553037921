<template>
    <div class="crow-form-point flex align-center space-between">
        <!-- custom components -->
        <div v-if="point.id === 'create-intermediate'" class="flex align-center">
            <div class="button">
                <q-button
                    icon="survey"
                    size="small"
                    variation="ghost"
                    :disabled="!canAnswerIndicator || point.disabled || !point.able || creatingForm"
                    :loading="creatingForm"
                    @click="handleAddForm"
                    >Nieuwe tussentijdse meting</q-button
                >
            </div>
        </div>

        <!-- default crow form -->
        <div v-else class="form flex align-center space-between">
            <div class="left flex align-center">
                <q-tag size="medium" :variation="getFormInfo.variation">{{ getFormInfo.tagText }}</q-tag>
    
                <p v-if="point.form.status === 'concept' && point.able">
                    {{ point.form.name }} Better Performance invullen
                </p>
                <p v-else-if="point.form.status === 'concept' && !point.able">
                    {{ point.form.name }} nog niet beschikbaar
                </p>
                <p v-else-if="!['add_new', 'finalize'].includes(point.form.status)">
                    {{ getFormInfo.text }}
                </p>
            </div>
    
            <!-- right column (options) -->
            <div class="right">
                <q-button
                    v-if="point.form.status === 'skipped'"
                    size="xsmall"
                    variation="ghost"
                    @click="viewSkippedReason = true"
                    >Bekijk toelichting</q-button
                >
                <q-button
                    v-else-if="canSkip"
                    size="xsmall"
                    variation="ghost"
                    @click.stop.prevent="skipRating = true"
                    class="show-on-hover"
                    >Overslaan</q-button
                >
                <q-tooltip v-if="showPublishedIcon" position="top">
                    <template v-slot:tooltip>
                        <p>Opdrachtgever heeft het project gepubliceerd</p>
                    </template>
                    <div class="chart-circle">
                        <q-icon type="chart"></q-icon>
                    </div>
                </q-tooltip>
                <div class="results">
                    <q-pill
                        v-if="showScorePill"
                        :values="getNumberValues"
                        size="medium"
                        interactive
                    ></q-pill>
                    <div v-else>{{ point.result }}</div>
                </div>
            </div>
        </div>


        <!-- skipping form popup -->
        <form-skip-review
            v-if="skipRating"
            :project="project"
            :form="point.form"
            @close="skipRating = false;"
        ></form-skip-review>

        <!-- form is skipped popup -->
        <q-popup v-if="viewSkippedReason && skippedCategory">
            <div class="modal">
                <div class="vertical-center">
                    <h3>Tussentijdse meting overgeslagen</h3>
                </div>
                <div class="modal-content">
                    <p>
                        In dit project is de tussentijdse Better Performance meting overgeslagen op
                        <strong> {{ getDate(skippedCategory.skippedDate) }} </strong> door
                        <strong>{{ skippedCategory.skippedUserId }}</strong>.<br/>
                        Bekijk hieronder de gegeven toelichting.
                    </p><br/>
                    <p style="font-style: italic;">{{ skippedCategory.skippedReason }}</p>

                    <div class="footer">
                        <q-button @click="closePopupViewSkippedReason">Terug naar het project </q-button>
                    </div>
                </div>
            </div>
        </q-popup>
    </div>
</template>

<script>
import FormSkipReview from '@/pages/app/project/FormSkipReview';

import { isDefined, executeParentFunction, getDate } from '@/assets/js/utils';

export default {
    name: 'crow-form-point',
    components: {
        FormSkipReview
    },
    props: {
        project: {
            type: Object,
            required: true
        },
        point: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            viewSkippedReason: false,
            skipRating: false,
            creatingForm: false
        }
    },
    methods: {
        closePopupViewSkippedReason() {
            this.viewSkippedReason = false;
        },
        async handleAddForm() {
            this.creatingForm = true;
            await executeParentFunction(this, 'project', 'clickNewMeasurement', { phaseId: this.point.phaseId });
            this.creatingForm = false;
        },
        getDate(unix) {
            return getDate(unix)
        },
        getFormScoreState(organisationType) {
            const FILLING_STATUS = ['concept', 'open', 'fill'];

            const greyColor = '#f4f5f6';
            const greenColor = '#cdecd2';
            const redColor = '#fce1e2';

            const form = this.point.form;
            const companionForm = this.point.form.companions[0];
            const formIfSkipped = organisationType === this.point.form.type ? this.point.form.companions[0] : this.point.form;

            const yourScore = form[`${organisationType}ScoreData`]?.average;

            const companionHasCompletedForm = !['concept', 'open', 'fill'].includes(companionForm?.status);
            const youHaveCompletedForm = !['concept', 'open', 'fill'].includes(form.status);

            const youHaveResponse = ['open', 'fill'].includes(form.status) && form.responseIds?.length !== 0;
            const companionHasResponse = ['open', 'fill'].includes(companionForm?.status) && companionForm?.responseIds?.length !== 0;

            const yourFormSkipped = !formIfSkipped.responseIds?.length && formIfSkipped.status === 'finished';

            const youInRevision = form.status === 'disagreed' || (form.status === 'pending_crow_admin' && companionForm?.status === 'crow_admin_disagreed');
            const companionInRevision = companionForm?.status === 'disagreed' || form.status === 'crow_admin_disagreed';

            let you = ['Je',`De ${this.$t(organisationType).toLowerCase()}`];
            let companion = organisationType === 'client' ? 'De opdrachtnemer' : 'De opdrachtgever';

            const scoreState = {
                text: '',
                tooltip: '',
                color: greyColor
            };

            // your form has been manually finished by your companion,
            // most likely because you have not answered a single question
            // before the deadline (14 days) after your companion has completed theirs
            if(yourFormSkipped) {
                scoreState.text = '>';
                scoreState.tooltip = `Geen score, eenzijdig afgerond door ${you[1].toLowerCase()}`;

                return scoreState
            }

            // your score is defined in your form
            if(isDefined(yourScore)) {
                scoreState.text = yourScore.toFixed(1);
                scoreState.tooltip = `Behaalde score voor ${you[1].toLowerCase()}`;
                if(yourScore > 5.5) scoreState.color = greenColor;

                return scoreState
            }

            // from this point, either one or both parties have not completed their form
            // meaning the form relevance (your/companion) are switched, because you are
            // responsible for the score that will be given to your component

            // your form has status 'disagreed', meaning you have
            // disagreed with the answers filled by your companion,
            // thus setting the companion in the 'revision' stage
            if(youInRevision || companionInRevision) {
                scoreState.text = '?';
                scoreState.tooltip = `Het formulier voor ${you[1].toLowerCase()} is in revisie`;
                scoreState.color = redColor;

                return scoreState
            }

            // form state of your own form
            if(form.type === organisationType) {
                if(companionHasCompletedForm) {
                    scoreState.text = '?';
                    scoreState.tooltip = `${companion} heeft al vastgesteld`;

                    return scoreState
                }

                if(!companionHasResponse) {
                    scoreState.text = '-';
                    scoreState.tooltip = `${companion} moet nog beginnen met invullen`;

                    return scoreState
                }

                if(!companionHasCompletedForm) {
                    scoreState.text = '?';
                    scoreState.tooltip = `${companion} moet nog vaststellen`;

                    return scoreState
                }
            }
            
            // form state of the companion form
            else {
                if(!youHaveResponse) {
                    scoreState.text = '-';
                    scoreState.tooltip = `${you[0]} moet nog beginnen met invullen`;

                    return scoreState
                }

                if(!youHaveCompletedForm) {
                    scoreState.text = '?';
                    scoreState.tooltip = `${you[0]} moet het formulier nog vaststellen`;

                    return scoreState
                }
                
            }

            return scoreState
        },
        capitalizeFirstLetter(string) {
            return String(string).charAt(0).toUpperCase() + String(string).slice(1);
        }
    },
    computed: {
        /**
         * Determines the tag and text to show for every state the form can be in
         * see formStatus.js for documentation about the statusses
        */
        getFormInfo: function() {
            const { formStatus } = require('../../../form/utils');
            const { status } = formStatus(this.point.form);
            let statusLabel = this.$t(`formStatus.${this.point.form.statusLabel}`);
            const text = this.point.formType === 'bp-final' ? 'Eindmeting Better Performance' : 'Tussentijdse Better Performance meting';

            // dit moet netter, formStatus utils werkt hier nog niet mee
            if (this.point.form.status === 'skipped') {
                return {
                    variation: 'info',
                    tagText: statusLabel || 'Overgeslagen',
                    text
                };
            } else if (status === 'concept') {
                return {
                    variation: 'default',
                    tagText: statusLabel || 'Concept',
                    text
                };
            } else if (status === 'startFilling') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'waitingForCompanionToStart') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'filling') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'waitingForCompanionFill') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'discussion') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'revision') {
                return {
                    variation: 'danger',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'waitingForCompanionDiscussion') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'waitingForCompanionRevision') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'waitingForCrowAdmin') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'finished') {
                return {
                    variation: 'success',
                    tagText: statusLabel || 'Afgerond',
                    text
                };
            }

            return false;
        },
        getNumberValues: function() {
            if(!['bp-intermediate','bp-final'].includes(this.point.formType) || this.point.type !== 'form') return

            const organisationType = this.point.form.type;
            const companionOrganisationType = organisationType === 'client' ? 'contractor' : 'client';

            const yourScoreState = this.getFormScoreState(organisationType);
            const companionScoreState = this.getFormScoreState(companionOrganisationType);

            // switch scores around, as your score is defined in companions form
            return [
                companionScoreState,
                yourScoreState
            ]
        },
        canSkip: function() {
            const { pdHybridmode, contractor, organisationId } = this.project;
            const currentOrganisationId = this.$store.getters.getCurrentOrganisation.id;
            const initiator = pdHybridmode
                ? currentOrganisationId === contractor?.id
                : currentOrganisationId === organisationId;
            const isOnlyIntermediateMeasurement = !this.project.timeline.points.some(point => point.phaseId === this.point.phaseId && point.formType === 'bp-intermediate' && point.id !== this.point.id);
            const hasNoResponses = (!this.point.form.responseIds?.length && !this.point.form.companions[0]?.responseIds?.length);

            const canSkip = this.point.able && 
                this.point.form.status === 'concept' && 
                this.point.formType === 'bp-intermediate' && 
                initiator && 
                isOnlyIntermediateMeasurement && 
                hasNoResponses;

            return canSkip
        },
        showScorePill: function() {
            if(!['bp-intermediate','bp-final'].includes(this.point.formType) || this.point.type !== 'form') return false
            const companion = this.point.form.companions[0] || {};
            const hasResponses = this.point.form.responseIds?.length > 0 || companion.responseIds?.length > 0;
            return this.point.able && (this.point.form.status !== 'concept' || hasResponses);
        },
        canAnswerIndicator: function() {
            return executeParentFunction(this, 'project', 'canEditIndicator', this.point);
        },
        skippedCategory: function() {
            const phase = this.project.timeline.phases.find(phase => phase.pointIds.includes(this.point.id));
            const category = phase.categories.find(category => category.skipped);
            return category
        },
        organisationType: function() {
            return this.$store.getters.getOrganisationType
        },
        showPublishedIcon: function() {
            const isFinalMeasurement = this.point.formType === 'bp-final';

            return this.project.clientStatus === 'finished' && isFinalMeasurement
        }
    }
}
</script>

<style lang="scss" scoped>

.crow-form-point {
    width: 100%;

    .form {
        width: 100%;

        .left {
            gap: 8px;
        }
    
        .right {
            display: flex;
            align-items: center;
            gap: 12px;

            .show-on-hover {
                opacity: 0;
                transition: 300ms ease;
            }
        }
    }
}

.crow-form-point:hover {
    .form .right .show-on-hover {
        opacity: 1;
    }
}

.modal {
    width: 500px;
    display: flex;
    flex-direction: column;

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 20px 0 0 0;
    }
}

.modal-content {
    margin: 20px 0 0 0;
}

</style>