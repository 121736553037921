<template>
    <div class="page-padding">
        <div class="header">
            <arrow-button color="#aeb5bc" @click="$router.push('/settings')" direction="left">Instellingen</arrow-button>
            <div class="space-between">
                <h2>Projecttemplates</h2>
                <q-button size="medium" variation="primary" @click="handleNewFormTemplate">Projecttemplate toevoegen</q-button>
            </div>
            <p>In dit overzicht vind je alle projecttemplates die aangemaakt zijn door je organisatie. Ook kun je een nieuw projecttemplate aanmaken of bestaande projecttemplates wijzigen.</p>
        </div>

        <div class="template-group">
            <div class="show-loader" :class="loadingTemplates ? 'show' : 'hide'">
                <div class="center">
                    <div class="loader"></div>
                </div>
            </div>
            <context-rows
                v-if="templates.length > 0"
                class="template-rows"
                :labels="templates"
                showStatus
                dataName="projecttemplate"
                @toggleShowRow="handleSelectRow"
                @labelUpdated="handleLabelUpdated"
                @labelDeleted="handleLabelDeleted">
            </context-rows>
            <p v-else-if="!loadingTemplates" class="template-rows">Er zijn geen actieve projecttemplates gevonden</p>
        </div>
    </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton.vue';
import gql from 'graphql-tag';
import ContextRows from './ContextRows.vue';

import { UPDATE_PROJECTTEMPLATE, REMOVE_PROJECTTEMPLATE } from '@/graphql/mutations';
import { GET_PROJECT_TEMPLATES } from '@/graphql/queries';

export default {
    name: 'project-templates',
    components: {
        'arrow-button': ArrowButton,
        ContextRows
    },
    data() {
        return {
            templates: [],
            loadingTemplates: false
        }
    },
    methods: {
        getProjectTemplates() {
            this.loadingTemplates = true;
            const { id: organisationId } = this.$store.getters.getCurrentOrganisation;
            this.$apollo.query({
                query: GET_PROJECT_TEMPLATES,
                variables: {
                    where: { AND: [
                        { organisationId },
                        { status__in: ["active","disabled"] },
                        { isMaster__nin: [true] },
                        { masterId__not_contains: "" }
                    ]}
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                this.templates = result.data.projecttemplates;
                this.loadingTemplates = false;
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de projecttemplates' })
                this.loadingTemplates = false;
            })
        },
        handleLabelUpdated(template) {
            this.$apollo.mutate({
                mutation: UPDATE_PROJECTTEMPLATE,
                variables: {
                    id: template.id,
                    name: template.name,
                    description: template.description,
                    status: template.status
                }
            })
            .then(result => {
                this.templates = this.templates.map(_template => {
                    if(_template.id === template.id) return template
                    return _template
                })
                this.$store.commit('notify', { type: 'success', message: 'De wijzigingen zijn doorgevoerd' })
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het opslaan van de wijzigingen' })
            })
        },
        handleSelectRow(row) {
            console.log(row)
            this.$router.push(`/manage/projecttemplates/${row.id}?name=${row.name}`)
        },
        handleNewFormTemplate() {
            this.$router.push(`/manage/projecttemplates/create`);
        },
        handleLabelDeleted(template) {
            this.$apollo.mutate({
                mutation: REMOVE_PROJECTTEMPLATE,
                variables: {
                    id: template.id
                }
            })
            .then(result => {
                this.templates = this.templates.filter(_template => _template.id !== template.id);
                this.$store.commit('notify', { type: 'success', message: 'Projecttemplate is succesvol verwijderd' })
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het verwijderen van het projecttemplate' })
            })
        }
    },
    created() {
        this.getProjectTemplates();
    }
}
</script>

<style lang="scss" scoped>

.page-padding {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .space-between {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
        min-width: 300px;
    }

    .right {
        display: flex;
        gap: 30px;
    }
}

.template-group {
    padding-top: 20px;
    .template-rows {
        margin-top: 20px;
    }
}

.show-loader {
    overflow: hidden;
    transition: max-height .2s ease;

    &.show {
        max-height: 120px;
    }
    &.hide {
        max-height: 0;
        .center .loader {
            transition: .2s ease;
            scale: 0.5;
            opacity: 0;
        }
    }
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-block: 40px;
    }
}

.loader {
    $loader-color: #8a8c8f;
    $loader-size: 40px;
    $loader-border-size: 3px;
    $loader-animation-duration: 700ms;
    @import '../../../components/qds/assets/loaders/loaders.scss';
    @include loader02;
}

</style>