var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.type,{ref:"button",tag:"component",class:['button', _vm.disabledState ? 'disabled' : '', _vm.ripple, _vm.size, _vm.state, _vm.variation, { buttonColor: _vm.buttonColor, 'autocomplete': _vm.showAutoComplete }],style:(`
        cursor: ${_vm.loading || _vm.disabledState ? 'default' : 'pointer'}; 
        --button-color: ${_vm.buttonColor}; 
        --percentage-x: ${_vm.percentageX}%; 
        --percentage-y: ${_vm.percentageY}%; 
        --bubble-scale: ${_vm.bubbleScale};
        --autocomplete-ms: ${_vm.autocomplete}ms;
    `),attrs:{"href":_vm.href,"type":_vm.submit,"for":_vm.formFor,"actions":_vm.actions,"icon":_vm.icon,"target":"_blank","loading":_vm.loading},on:{"click":_vm.handleClick}},[_c('div',{ref:"hitBox",staticClass:"interactive"}),_vm._v(" "),_c('div',{staticClass:"bubble-wrapper"},[_c('span',{staticClass:"button-bubble",class:{ hide: !_vm.initializedPosition && !_vm.autocomplete }})]),_vm._v(" "),(_vm.icon && !_vm.loading && _vm.iconPosition === 'left')?_c('q-icon',{staticClass:"button_icon",style:(_vm.iconStyleObject),attrs:{"type":_vm.icon,"width":_vm.iconSizeInput,"height":_vm.iconSizeInput}}):_vm._e(),_vm._v(" "),_c('div',{class:{ hide: _vm.loading }},[_vm._t("default")],2),_vm._v(" "),(_vm.icon && !_vm.loading && _vm.iconPosition === 'right')?_c('q-icon',{staticClass:"button_icon",style:(_vm.iconStyleObject),attrs:{"type":_vm.icon,"width":_vm.iconSizeInput,"height":_vm.iconSizeInput}}):_vm._e(),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"preloader"},[_c('div',{staticClass:"loader"})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }