<template>
    <Transition 
        name="jump" 
        appear 
        @before-enter="handleEnterDelay" 
        @after-enter="handleRemoveAttribute"
        @leave="handleLeaveDelay" 
    >
        <slot></slot>
    </Transition>
</template>

<script>
export default {
    name: 'jump-transition',
    props: {
        jump: {
            type: String,
            default: 'medium'
        },
        /*
        *   If a group is defined, a data attribute 'data-stagger' will
        *   be set to the group value, which results in all the elements
        *   with the same group being staggered in their animation
        * 
        *   This also handles the animation only being staggered for all
        *   current playing animations, so it will not wait unneccesarily
        *   if the DOM index is for example 3 while the animation index
        *   should be 1 because the staggering starts on this element
        */
        group: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            staggerDelay: 100
        }
    },
    methods: {
        async handleEnterDelay(element) {
            if(this.group) element.setAttribute('data-stagger', this.group);
            else return element.style = `--delay: 0ms`;

            await new Promise(r => setTimeout(r, 1));
            const transitions = document.querySelectorAll(`[data-stagger="${this.group}"]`);
            const index = [...transitions].findIndex(transition => transition.contains(this.$el));
            const delay = index * this.staggerDelay;

            let style = '';
            
            style += `--delay: ${delay}ms;`;
            if(this.jump === 'big') style += `animation-timing-function: cubic-bezier(.51,2,.45,1.08);`;

            element.style = style;
        },
        handleRemoveAttribute(element) {
            element.removeAttribute('data-stagger');
            element.style = `--delay: 0ms`;
            this.$emit('after-enter', element);
        },
        handleLeaveDelay(element) {
            return

            // if(!this.$attrs['data-stagger']) return element.style = `--delay: 0ms`;

            // const transitions = [ ...document.querySelectorAll(`[data-stagger="${this.$attrs['data-stagger']}"]`) ];
            // const index = transitions.findIndex(transition => transition.contains(element));
            // const delay = (transitions.length - index) * this.staggerDelay;
            
            // element.style = `--delay: ${delay}ms`;
        }
    }
}
</script>

<style lang="scss" scoped>

.jump-enter-active {
    opacity: 0;
    scale: 0.5;
    animation: jumpIn .25s cubic-bezier(.51,1.4,.45,1.08) forwards var(--delay, 0ms);
}
.jump-leave-active {
    scale: 1;
    opacity: 1;
    animation: jumpOut .15s ease-in forwards var(--delay, 0ms);
}

@keyframes jumpIn {
    from {
        opacity: 0;
        scale: 0.5;
    } to {
        opacity: 1;
        scale: 1;
    }
}
@keyframes jumpOut {
    0% {
        scale: 1;
        opacity: 1;
    } 100% {
        opacity: 0;
        scale: 0.2;
    }
}
    
</style>