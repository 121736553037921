<template>
    <router-link :class="['link', 'link--arrowed-' + direction]" :style="buttonColor" :to="href || ''" custom v-slot="{ href:routerHref, navigate }">
        <a :href="routerHref" @click.prevent.stop="onClick($event, routerHref, navigate)">
            <svg
                v-if="direction == 'left'"
                class="arrow-icon rotation"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 16 32"
            >
                <g fill="none" :stroke="color" stroke-width="1.5" stroke-linejoin="round" stroke-miterlimit="10">
                    <!-- <circle class="arrow-icon--circle" cx="16" cy="16" r="15.12"></circle> -->
                    <path class="arrow-icon--arrow" d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"></path>
                </g>
            </svg>
            <span class="text">
                <slot />
            </span>
            <svg
                v-if="direction != 'left'"
                class="arrow-icon rotation"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 16 32"
            >
                <g fill="none" :stroke="color" stroke-width="1.5" stroke-linejoin="round" stroke-miterlimit="10">
                    <!-- <circle class="arrow-icon--circle" cx="16" cy="16" r="15.12"></circle> -->
                    <path class="arrow-icon--arrow" d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"></path>
                </g>
            </svg>
        </a>
    </router-link>
</template>

<script>
export default {
    name: 'ArrowButton',
    props: {
        href: {
            type: String,
            default: null
        },
        color: {
            type: String,
            default: '#000000'
        },
        // Currently only the directions 'left' and 'right' are available.
        direction: {
            type: String,
            default: 'left'
        }
    },
    data() {
        return {
            prevRoute: null
        }
    },
    methods: {
        onClick(event, href, navigate) {
            console.log(this.prevRoute, href)
            if(this.prevRoute === href) {
                event.preventDefault();
                return this.$router.go(-1);
            }
            if(this.href) return navigate(this.href)
            this.$emit('click')
        },
        removeQueryParams(url) {
            if(!url.includes('?')) return url
            return url.split('?')[0]
        }
    },
    computed: {
        buttonColor() {
            return {
                color: this.color
            };
        }
    },
    created() {
        const lastRoute = this.$store.getters.getLastRoutingEvent;
        this.prevRoute = this.removeQueryParams(lastRoute.from);
    }
};
</script>

<style lang="scss" scoped>
.link {
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;

    & .text {
        margin-left: -7px;
    }
}

.link--arrowed-right {
    display: inline-block;
    height: 2rem;
    line-height: 2rem;

    .arrow-icon {
        position: relative;
        top: -1px;
        -webkit-transition: -webkit-transform 0.3s ease;
        transition: -webkit-transform 0.3s ease;
        transition: transform 0.3s ease;
        transition: transform 0.3s ease, -webkit-transform 0.3s ease;
        vertical-align: middle;
    }

    &:hover {
        .arrow-icon {
            transform: translate3d(5px, 0, 0);
        }
    }
}

.link--arrowed-left {
    display: inline-block;
    height: 2rem;
    line-height: 2rem;

    .arrow-icon {
        position: relative;
        top: -1px;
        -webkit-transition: -webkit-transform 0.3s ease;
        transition: -webkit-transform 0.3s ease;
        transition: transform 0.3s ease;
        transition: transform 0.3s ease, -webkit-transform 0.3s ease;
        vertical-align: middle;
        transform: rotate(180deg);
    }

    &:hover {
        .arrow-icon {
            transform: translate3d(-5px, 0, 0) rotate(180deg);
        }
    }
}

a:visited {
    color: inherit;
}
</style>
