<template>
    <q-popup>
        <div class="modal">
            <div class="header">
                <h2>{{ typeName }} {{ disableEdit ? 'bekijken' : editing ? 'bewerken' : 'toevoegen' }}</h2>
                <q-button 
                    v-if="
                        editing && 
                        deleteLabel && 
                        (!(!this.contextName) || this.contextName === 'prestatie') && 
                        (!isMaster || this.contextName === 'projecttemplate' || this.contextName === 'formtemplate') && 
                        !disableEdit
                    " 
                    variation="danger" size="small" :loading="loadingDelete" @click="handleDelete">{{ deleteLabel }}</q-button>
                <q-label-switch v-if="!editing && this.contextName !== 'prestatie'" :options="indicatorOptions" v-model="selectedIndicatorOption" optionWidth="160px" @input="handleLabelInput"></q-label-switch>
            </div>
            <div v-if="selectedIndicatorOption === 'new'" class="content">
                <div class="line">
                    <div class="inline">
                        <p class="name">{{ typeName === 'Vraag' ? 'Vraag' : 'Kenmerk' }}</p>
                    </div>
                    <q-text
                        v-model="configuringIndicator.name"
                        class="text-input"
                        :disabled="isMaster || disableEdit"
                        :placeholder="typeName === 'Vraag' ? 'Typ hier de vraag' : 'Typ hier de naam van het kenmerk'"
                        size="small"
                        maxlength="0"
                        rows="1"
                        disableEnterKey
                        @input="handleConfigurationValidation"
                        :autofocus="isBeingCreated"
                    ></q-text>
                </div>
                <div v-if="!disableEdit" class="line">
                    <div class="inline">
                        <p class="name">Omschrijving</p><p class="optional-field">(optioneel)</p>
                    </div>
                    <q-input labelPosition="inside" 
                        v-model="configuringIndicator.description" 
                        class="user-input" 
                        size="small"
                        :placeholder="`Typ hier een omschrijving voor ${typeName === 'Vraag' ? 'deze vraag' : 'dit projectkenmerk'}`">
                    </q-input>
                </div>
                <div v-if="configuringIndicator.type === 'question' && !disableEdit" class="line">
                    <div class="inline">
                        <p class="name">Formulier</p>
                    </div>
                    <q-dropdown 
                        v-model="configuringIndicator.formtemplateIds"
                        class="user-input" 
                        :options="formTemplateOptions" 
                        :disabled="configuringIndicator.isMaster"
                        size="small" 
                        placeholder="Kies een formulier" 
                        @input="handleFormtemplateSelected"
                        multiple
                        autocomplete
                        :loading="loadingFormTemplates">
                    </q-dropdown>
                </div>
                <div v-if="!disableEdit" class="line">
                    <div class="inline">
                        <p class="name">Projecttemplate</p>
                    </div>
                    <q-dropdown 
                        v-model="configuringIndicator.projecttemplateIds"
                        class="user-input" 
                        :options="projectTemplateOptions" 
                        :disabled="configuringIndicator.isMaster"
                        size="small" 
                        placeholder="Kies een projecttemplate"
                        @input="handleProjecttemplateSelected"
                        multiple
                        autocomplete
                        :loading="loadingProjectTemplates">
                    </q-dropdown>
                </div>
                <div v-if="!disableEdit" class="line">
                    <div class="inline">
                        <p class="name">Antwoordtype</p>
                    </div>
                    <q-dropdown
                        :value="configuringIndicator.displayType"
                        class="user-input" 
                        :options="displayTypeOptions" 
                        size="small" 
                        placeholder="Kies een antwoordtype" 
                        :disabled="isMaster || editing"
                        @input="handleDisplayTypeUpdated"
                        autocomplete>
                    </q-dropdown>
                </div>
                <div v-if="configuringIndicator.displayType === 'numberUnit' && !disableEdit" class="line">
                    <div class="inline">
                        <p class="name">Eenheid</p>
                    </div>
                    <q-input labelPosition="inside" 
                        v-model="configuringIndicator.unit"
                        class="user-input" 
                        size="small" 
                        placeholder="Typ hier de eenheid waarin je wilt meten"
                        :unit="configuringIndicator.unit"
                        :disabled="isMaster"
                        @input="handleConfigurationValidation">
                    </q-input>
                </div>
                <div v-if="!disableEdit" class="line">
                    <div class="inline">
                        <p class="name">Actief</p>
                    </div>
                    <div class="user-options">
                        <q-radio :label="configuringIndicator.status" value="active" name="active" @input="handleUpdateIndicator('status', 'active')">Ja</q-radio>
                        <q-radio :label="configuringIndicator.status" value="disabled" name="active" @input="handleUpdateIndicator('status', 'disabled')">Nee</q-radio>
                    </div>
                </div>
                <div v-if="!disableEdit" class="line">
                    <div class="inline">
                        <p class="name">Verplicht</p>
                        <q-tooltip position="top">
                            <template #tooltip>
                                <p>{{ typeName === 'Vraag' ? 'Deze vraag' : 'Dit kenmerk' }} is verplicht om in te vullen door de respondent van je formulier</p>
                            </template>
                            <q-icon type="questionCircle"></q-icon>
                        </q-tooltip>
                    </div>
                    <div class="user-options">
                        <q-radio :label="configuringIndicator.required" :value="true" name="required" @input="handleUpdateIndicator('required', true)">Ja</q-radio>
                        <q-radio :label="configuringIndicator.required" :value="false" name="required" @input="handleUpdateIndicator('required', false)">Nee</q-radio>
                    </div>
                </div>
                <div v-if="typeName === 'Vraag' && !disableEdit" class="line">
                    <div class="inline">
                        <p class="name">Vereist</p>
                        <q-tooltip position="top">
                            <template #tooltip>
                                <p>Deze vraag is vereist om te stellen bij het uitsturen van je formulier. <br>Je kunt de vraag bij het opstellen van je formulier dus niet deactiveren</p>
                            </template>
                            <q-icon type="questionCircle"></q-icon>
                        </q-tooltip>
                    </div>
                    <div class="user-options">
                        <q-radio :disabled="isMaster" :label="configuringIndicator.mandatory" :value="true" name="mandatory" @input="handleUpdateIndicator('mandatory', true)">Ja</q-radio>
                        <q-radio :disabled="isMaster" :label="configuringIndicator.mandatory" :value="false" name="mandatory" @input="handleUpdateIndicator('mandatory', false)">Nee</q-radio>
                    </div>
                </div>
                <div v-if="typeName === 'Vraag' && !disableEdit" class="line">
                    <div class="inline">
                        <p class="name">Niet van toepassing</p>
                    </div>
                    <div class="user-options">
                        <q-radio :disabled="isMaster" :label="configuringIndicator.canFillNotApplicable" :value="true" name="nvt" @input="handleUpdateIndicator('canFillNotApplicable', true)">Ja</q-radio>
                        <q-radio :disabled="isMaster" :label="configuringIndicator.canFillNotApplicable" :value="false" name="nvt" @input="handleUpdateIndicator('canFillNotApplicable', false)">Nee</q-radio>
                    </div>
                </div>
                <div v-if="typeName === 'Vraag' && !disableEdit" class="line">
                    <div class="inline">
                        <p class="name">Opmerkingen</p>
                    </div>
                    <div class="user-options">
                        <q-radio :disabled="isMaster" :label="configuringIndicator.canFillComment" :value="true" name="comments" @input="handleUpdateIndicator('canFillComment', true)">Ja</q-radio>
                        <q-radio :disabled="isMaster" :label="configuringIndicator.canFillComment" :value="false" name="comments" @input="handleUpdateIndicator('canFillComment', false)">Nee</q-radio>
                    </div>
                </div>
                <div v-if="configurableDisplayTypes.includes(configuringIndicator.displayType)" class="answer-type-configuration">
                    <div class="inline">
                        <p class="name">Beoordelingswaarden:</p>
                        <q-checkbox 
                            v-if="configuringIndicator.displayType === 'score'" 
                            v-model="configuringIndicator.showScore"
                            :disabled="isMaster" 
                            labelPosition="left"
                            @input="handleUpdateIndicator('showScore', $event)"
                        >Toon cijferscore tijdens invullen</q-checkbox>
                    </div>
                    <div class="answer-type-options">
                        <component
                            :is="componentType"
                            :options="configuringIndicator.answerValues"
                            :configurable="!configuringIndicator.isMaster"
                            :disabled="configuringIndicator.isMaster"
                            @optionsUpdated="handleIndicatorValuesUpdated"
                            @validationUpdated="handleValidationUpdated"
                        ></component>
                    </div>
                </div>
            </div>
            <div v-else class="content">
                <div class="line">
                    <div class="inline">
                        <p class="name">{{ typeName }}</p>
                    </div>
                    <q-dropdown 
                        v-model="selectedIndicatorIds"
                        class="user-input" 
                        :options="labelIndicatorOptions" 
                        :loading="loadingIndicators"
                        size="small" 
                        placeholder="Selecteer een of meer" 
                        @input="handleConfigurationValidation"
                        multiple
                        autocomplete>
                    </q-dropdown>
                </div>
            </div>
            <div class="footer">
                <q-button variation="blank" @click="handleCancel">{{ disableEdit ? 'Sluiten' : 'Annuleren' }}</q-button>
                <div class="mandatory-fields">
                    <q-tooltip class="tooltip" position="top" :class="{ show: disabledReason }">
                        <template #tooltip >
                            <span v-html="disabledReason"></span>
                        </template>
                        <div class="question-circle">
                            <q-icon type="QuestionCircle" width="22" height="22" color="red"></q-icon>
                        </div>
                    </q-tooltip>
                    <div v-if="!disableEdit" class="add-button">
                        <q-button variation="primary" :disabled="!hasValidConfiguration" @click="handleSave()" :loading="loading">{{ typeName }} {{ editing ? 'opslaan' : 'toevoegen' }}</q-button>
                    </div>
                </div>
            </div>
        </div>
    </q-popup>
</template>

<script>
import { isValidAnswer } from '@/assets/js/utils';
import { CREATE_INDICATOR, UPDATE_INDICATOR, REMOVE_INDICATOR, ADD_LABEL_TO_PROJECTTEMPLATE, ADD_LABEL_TO_FORMTEMPLATE } from '@/graphql/mutations';
import { GET_INDICATOR, GET_INDICATORS_WITH_TEMPLATES } from '@/graphql/queries';
import gql from 'graphql-tag';
import _ from 'lodash';

export default {
    name: 'question-configuration-modal',
    props: {
        indicator: {
            type: Object,
            default: null
        },
        dataName: {
            type: String,
            default: ''
        },
        contextName: {
            type: String,
            default: ''
        },
        editing: {
            type: Boolean,
            default: false
        },
        disableEdit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            emptyConfiguringIndicator: {
                name: '',
                unit: '',
                answerValues: [],
                projecttemplateIds: [],
                formtemplateIds: [],
                displayType: '',
                required: true,
                mandatory: true,
                canFillNotApplicable: true,
                canFillComment: true,
                showScore: true,
                status: 'active'
            },
            configuringIndicator: {
                name: '',
                unit: '',
                answerValues: [],
                displayType: '',
                required: null,
                mandatory: null,
                canFillNotApplicable: null,
                canFillComment: null,
                status: 'active'
            },
            defaultValuesScore: [
                {
                    label: '',
                    value: 10,
                    checked: false
                },
                {
                    label: '',
                    value: 9,
                    checked: false
                },
                {
                    label: '',
                    value: 8,
                    checked: false
                },
                {
                    label: '',
                    value: 7,
                    checked: false
                },
                {
                    label: '',
                    value: 6,
                    checked: false
                },
                {
                    label: '',
                    value: 5,
                    checked: false
                },
                {
                    label: '',
                    value: 4,
                    checked: false
                },
                {
                    label: '',
                    value: 3,
                    checked: false
                },
                {
                    label: '',
                    value: 2,
                    checked: false
                },
                {
                    label: '',
                    value: 1,
                    checked: false
                },
            ],
            formTemplateOptions: [],
            projectTemplateOptions: [],
            configurableDisplayTypes: ['score','checkbox','radio'],
            selectedIndicatorOption: 'new',
            questionOptions: [],
            hasValidAnswerValues: true,
            hasValidConfiguration: true,
            loading: false,
            loadingDelete: false,
            disabledReason: '',
            masterIndicator: null,
            loadingIndicators: false,
            selectedIndicatorIds: '',
            labelIndicators: [],
            labelIndicatorOptions: [],
            isBeingCreated: false,
            loadingFormTemplates: false,
            loadingProjectTemplates: false
        }
    },
    methods: {
        handleLabelInput() {
            if(this.selectedIndicatorOption === 'new') {
                this.configuringIndicator = { ...this.emptyConfiguringIndicator };
                if(this.indicator) this.configuringIndicator = { ...this.configuringIndicator, ..._.cloneDeep(this.indicator) };
            } else {
                if(this.labelIndicators.length === 0) this.setLabelIndicators();
            }
            this.handleConfigurationValidation()
        },
        async setLabelIndicators() {
            try {
                this.loadingIndicators = true;
                const indicators = await this.$apollo.query({
                    query: GET_INDICATORS_WITH_TEMPLATES,
                    variables: {
                        organisationId: this.$store.getters.getCurrentOrganisation.id,
                        where: {
                            AND: [
                                { labelIds: this.configuringIndicator.labelIds[0] },
                                { projecttemplateIds__nin: this.$route.params.id },
                                { formtemplateIds__nin: this.$route.params.id },
                                { type: this.configuringIndicator.type }
                            ]
                        }
                    },
                    fetchPolicy: 'no-cache'
                })
                .then(result => result.data.indicators_with_templates);

                this.labelIndicators = indicators;
                this.labelIndicatorOptions = indicators.map(indicator => {
                    return {
                        label: indicator.name,
                        value: indicator.id
                    }
                });
                this.loadingIndicators = false;
            } catch(error) {
                this.loadingIndicators = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de kenmerken' });
            }
        },
        async handleAddIndicators() {
            this.loading = true;

            try {
                if(this.contextName === 'projecttemplate') 
                    await this.$apollo.mutate({
                        mutation: ADD_LABEL_TO_PROJECTTEMPLATE,
                        variables: {
                            id: this.$route.params.id,
                            labelId: this.configuringIndicator.labelIds[0],
                            indicatorIds: this.selectedIndicatorIds
                        }
                    })
                else if(this.contextName === 'formulier') 
                    await this.$apollo.mutate({
                        mutation: ADD_LABEL_TO_FORMTEMPLATE,
                        variables: {
                            id: this.$route.params.id,
                            labelId: this.configuringIndicator.labelIds[0],
                            indicatorIds: this.selectedIndicatorIds
                        }
                    })
                    
                this.loading = false;
                const indicators = this.labelIndicators.filter(indicator => this.selectedIndicatorIds.includes(indicator.id))
                this.$emit('indicatorsAdded', indicators);
            } catch(error) {
                this.loading = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het toevoegen' });
                this.$emit('cancel');
            }
        },
        handleFormtemplateSelected() {
            this.configuringIndicator.formtemplates = this.formTemplateOptions
                .filter(option => this.configuringIndicator.formtemplateIds.includes(option.value))
                .map(option => {
                    return {
                        id: option.value,
                        name: option.label
                    }
                })
            this.handleConfigurationValidation();
        },
        handleProjecttemplateSelected() {
            this.configuringIndicator.projecttemplates = this.projectTemplateOptions
                .filter(option => this.configuringIndicator.projecttemplateIds.includes(option.value))
                .map(option => {
                    return {
                        id: option.value,
                        name: option.label
                    }
                })
            this.handleConfigurationValidation();
        },
        handleIndicatorValuesUpdated(options) {
            this.configuringIndicator.answerValues = options;
            this.handleConfigurationValidation();
        },
        handleValidationUpdated(validAnswerValues) {
            this.hasValidAnswerValues = validAnswerValues;
            this.handleConfigurationValidation();
        },
        handleConfigurationValidation() {
            let valid = true;
            if(this.selectedIndicatorOption === 'new') valid = this.typeName === 'Vraag' ? this.validateIndicator() : this.validateReference();
            else {
                this.disabledReason = '';
                this.hasValidConfiguration = this.selectedIndicatorIds.length > 0;
                return
            }

            if(valid) this.disabledReason = '';
            
            this.hasValidConfiguration = valid;

            if(!valid) this.hasValidConfiguration = false;
            else if(!this.hasValidAnswerValues) {
                this.hasValidConfiguration = false;
                this.disabledReason = 'Beoordelingswaarden niet volledig'
            }
            else this.hasValidConfiguration = true;
        },
        validateIndicator() {
            const requiredIndicatorKeys = ['name', 'status', 'required', 'displayType', 'canFillNotApplicable', 'canFillComment'];
            if(this.configuringIndicator.displayType === 'numberUnit') requiredIndicatorKeys.push('unit');
            let invalidAnswer = false;

            requiredIndicatorKeys.forEach(key => {
                if(!isValidAnswer(this.configuringIndicator[key])) {
                    invalidAnswer = true;
                    this.disabledReason = 'Nog niet alle verplichte velden zijn ingevuld';
                }
            })

            return !invalidAnswer
        },
        validateReference() {
            const requiredReferenceKeys = ['name', 'status', 'required', 'displayType'];
            if(this.configuringIndicator.displayType === 'numberUnit') requiredReferenceKeys.push('unit');
            let invalidAnswer = false;

            requiredReferenceKeys.forEach(key => {
                if(!isValidAnswer(this.configuringIndicator[key])) {
                    invalidAnswer = true;
                    this.disabledReason = 'Nog niet alle verplichte velden zijn ingevuld';
                }
            })

            return !invalidAnswer
        },
        handleDisplayTypeUpdated(displayType) {
            const sameAnswerValuesDisplays = ['radio','checkbox'];
            if(sameAnswerValuesDisplays.includes(displayType) && sameAnswerValuesDisplays.includes(this.configuringIndicator.displayType))
                return this.configuringIndicator.displayType = displayType;

            if(displayType === 'score') this.configuringIndicator.answerValues = [...this.defaultValuesScore];
            else this.configuringIndicator.answerValues = [];
            this.configuringIndicator.displayType = displayType;
            this.handleValidationUpdated(true);
        },
        handleUpdateIndicator(key, value) {
            this.configuringIndicator[key] = value;
            this.handleConfigurationValidation();
        },
        handleCancel() {
            this.$emit('cancel');
        },
        parseDisplayType(displayType) {
            switch(displayType) {
                case'dropdown':
                    return 'radio'
                case'dropdown-multiple':
                default:
                    return displayType
            }
        },
        getAnswerType(displayType) {
            switch(displayType) {
                case'scale':
                case'radio':
                    return 'radio'
                case'checkbox':
                    return 'checkbox'
                case'number':
                case'numberUnit':
                case'currency':
                    return 'number'
                case'document':
                    return 'upload'
                default:
                    return displayType
            }
        },
        handleSave(deleting) {
            if(this.selectedIndicatorOption === 'existing') return this.handleAddIndicators(); 
            if(!deleting) this.loading = true;

            this.configuringIndicator.answerType = this.getAnswerType(this.configuringIndicator.displayType);

            const answerValues = this.configuringIndicator.answerValues || [];
            const parsedAnswerValues = answerValues.map(value => {
                let status = 'active';
                if(this.configuringIndicator.displayType === 'score') status = value.checked ? 'active' : 'disabled';

                return {
                    value: value.value,
                    label: value.label,
                    order: value.order,
                    status
                }
            });

            if(this.editing) {
                this.$apollo.mutate({
                    mutation: UPDATE_INDICATOR,
                    variables: {
                        id: this.configuringIndicator.id,
                        name: this.configuringIndicator.name,
                        description: this.configuringIndicator.description,
                        answerType: this.configuringIndicator.answerType,
                        displayType: this.configuringIndicator.displayType,
                        unit: this.configuringIndicator.unit,
                        formtemplateIds: this.configuringIndicator.formtemplateIds,
                        projecttemplateIds: this.configuringIndicator.projecttemplateIds,
                        required: this.configuringIndicator.required,
                        mandatory: this.configuringIndicator.mandatory,
                        canFillComment: this.configuringIndicator.canFillComment,
                        canFillNotApplicable: this.configuringIndicator.canFillNotApplicable,
                        status: this.configuringIndicator.status,
                        answerValues: parsedAnswerValues,
                        showScore: this.configuringIndicator.showScore
                    }
                })
                .then(result => {
                    this.loading = false;
                    this.$store.commit('notify', { type: 'success', message: 'Wijzigingen zijn doorgevoerd' });
                    if(deleting) this.$emit('delete', this.configuringIndicator);
                    else this.$emit('save', this.configuringIndicator);
                    return
                })
                .catch(error => {
                    this.loading = false;
                    this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het opslaan van de wijzigingen' });
                    this.$emit('cancel');
                    return
                })
            } else {
                const { id: organisationId } = this.$store.getters.getCurrentOrganisation;
                this.configuringIndicator.context = this.configuringIndicator.labelIds[0];
    
                this.$apollo.mutate({
                    mutation: CREATE_INDICATOR,
                    variables: {
                        organisationId,
                        indicator: {
                            name: this.configuringIndicator.name,
                            description: this.configuringIndicator.description,
                            answerType: this.configuringIndicator.answerType,
                            displayType: this.configuringIndicator.displayType,
                            unit: this.configuringIndicator.unit,
                            formtemplateIds: this.configuringIndicator.formtemplateIds,
                            projecttemplateIds: this.configuringIndicator.projecttemplateIds,
                            required: this.configuringIndicator.required,
                            mandatory: this.configuringIndicator.mandatory,
                            canFillComment: this.configuringIndicator.canFillComment,
                            canFillNotApplicable: this.configuringIndicator.canFillNotApplicable,
                            type: this.configuringIndicator.type,
                            context: this.configuringIndicator.context,
                            status: this.configuringIndicator.status,
                            answerValues: parsedAnswerValues,
                            showScore: this.configuringIndicator.showScore
                        }
                    }
                })
                .then(result => {
                    this.configuringIndicator.id = result.data.indicator_create.id;
                    this.$store.commit('notify', { type: 'success', message: `${this.typeName} succesvol aangemaakt` });
                    this.$emit('create', this.configuringIndicator)
                })
                .catch(error => {
                    this.$store.commit('notify', { type: 'danger', message: `Er ging iets fout tijdens het aanmaken van dit ${this.typeName}` });
                })
            }
        },
        handleDelete() {
            this.loadingDelete = true;

            try {
                if(!this.contextName || this.contextName === 'prestatie') {
                    this.$apollo.mutate({
                        mutation: REMOVE_INDICATOR,
                        variables: {
                            id: this.configuringIndicator.id
                        }
                    })
                    .then(result => {
                        this.$store.commit('notify', { type: 'success', message: `${this.typeName} succesvol verwijderd` });
                        this.loadingDelete = false;
                        this.$emit('deleted', this.configuringIndicator)
                    })
                    .catch(error => {
                        throw new Error(`Er ging iets fout tijdens het verwijderen van dit ${this.typeName}`)
                    })
                } else if(this.contextName === 'formulier') {
                    const formtemplateIndex = this.configuringIndicator.formtemplateIds.indexOf(this.$route.params.id)
                    if(formtemplateIndex === -1) throw new Error('Kenmerk niet gevonden in dit formuliertemplate')
                    this.configuringIndicator.formtemplateIds.splice(formtemplateIndex, 1)
                    this.handleSave(true);
                    this.$emit('deleted', this.configuringIndicator)
                } else if(this.contextName === 'projecttemplate') {
                    const projecttemplateIndex = this.configuringIndicator.projecttemplateIds.indexOf(this.$route.params.id)
                    if(projecttemplateIndex === -1) throw new Error('Kenmerk niet gevonden in dit projecttemplate')
                    this.configuringIndicator.projecttemplateIds.splice(projecttemplateIndex, 1)
                    this.handleSave(true);
                    this.$emit('deleted', this.configuringIndicator)
                }
            } catch(error) {
                this.loadingDelete = false;
                this.$store.commit('notify', { type: 'danger', message: error.message });
            }
            

        },
        getFormtemplates() {
            this.loadingFormTemplates = true;
            const { id: organisationId } = this.$store.getters.getCurrentOrganisation;

            this.$apollo.query({
                query: gql`
                    query {
                        formtemplates(where: { AND: [
                            { organisationId: "${organisationId}" },
                            { type: "form" },
                            { status__in: ["active", "disabled"] },
                            { name__nin: ["Works_client_form_template", "Works_contractor_form_template", "Services_client_form_template", "Services_contractor_form_template", "Works_client_form_template", "Works_contractor_form_template", "Services_client_form_template", "Services_contractor_form_template", "Works_client_form_template", "Works_client_form_template"] }
                        ]}){
                            id
                            name
                        }
                    }
                `,
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                this.formTemplateOptions = result.data.formtemplates.map(template => {
                    return {
                        label: template.name,
                        value: template.id
                    }
                });
                this.loadingFormTemplates = false;
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de formulier opties' });
                this.loadingFormTemplates = false;
            })
        },
        getProjecttemplates() {
            this.loadingProjectTemplates = true;
            const { id: organisationId } = this.$store.getters.getCurrentOrganisation;

            this.$apollo.query({
                query: gql`
                    query {
                        projecttemplates(where: {
                            AND: [
                                { organisationId: "${organisationId}" },
                                { masterId__exists: false },
                                { status__in: ["active", "disabled"] }
                            ]
                        }){
                            id
                            name
                        }
                    }
                `,
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                this.projectTemplateOptions = result.data.projecttemplates.map(template => {
                    return {
                        label: template.name,
                        value: template.id
                    }
                });
                this.loadingProjectTemplates = false;
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de projecttemplate opties' });
                this.loadingProjectTemplates = false;
            })
        },
        getMasterIndicator() {
            this.$apollo.query({
                query: GET_INDICATOR,
                variables: {
                    id: this.configuringIndicator.masterId
                }
            })
            .then(result => {
                this.masterIndicator = result.data.indicator;
                this.configuringIndicator.answerValues = this.configuringIndicator.answerValues.map(value => {
                    const indexOfMasterValue = this.masterIndicator.answerValues.findIndex(masterValue => masterValue.value === value.value);
                    if(indexOfMasterValue !== -1) value['disabled'] = true;
                    return value
                })
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout, probeer dit later opnieuw' })
                this.$emit('cancel');
            })
        }
    },
    created() {
        this.isBeingCreated = !Boolean(this.indicator.id);
        this.configuringIndicator = _.cloneDeep(this.emptyConfiguringIndicator);
        if(this.indicator) this.configuringIndicator = { ...this.configuringIndicator, ..._.cloneDeep(this.indicator) };
        if(!this.configuringIndicator.displayType) this.configuringIndicator.displayType = this.indicator.answerType;
        this.configuringIndicator.displayType = this.parseDisplayType(this.configuringIndicator.displayType);
        if(!this.configuringIndicator.answerValues) this.configuringIndicator.answerValues = [];
        if(this.configuringIndicator.masterId) this.getMasterIndicator();
        if(this.indicator.showScore === null) this.configuringIndicator.showScore = true;

        if(this.configuringIndicator.type === 'question') this.getFormtemplates();
        this.getProjecttemplates();
    },
    computed: {
        isMaster: function() {
            return Boolean(this.configuringIndicator.masterId || this.configuringIndicator.isMaster)
        },
        componentType: function() {
            switch(this.configuringIndicator.displayType) {
                case'score':
                    return 'q-score-options'
                case'checkbox':
                    return 'q-checkbox-options'
                case'radio':
                    return 'q-radio-options'
                default:
                    return ''
            }
        },
        typeName: function() {
            return this.configuringIndicator.type === 'question' ? 'Vraag' : 'Projectkenmerk'
        },
        indicatorOptions: function() {
            const suffix = this.configuringIndicator.type === 'question' ? 'e vraag' : ' kenmerk';
            return [
                {
                    label: `Nieuw${suffix}`,
                    value: 'new'
                },
                {
                    label: `Bestaand${suffix}`,
                    value: 'existing'
                }
            ]
        },
        deleteLabel: function() {
            if(!this.contextName || this.contextName === 'prestatie') return `${this.typeName} verwijderen`
            return `${this.typeName} uit ${this.contextName} verwijderen`
        },
        displayTypeOptions: function() {
            const type = this.configuringIndicator.type;
            const options = [
                {
                    label: 'Tekst',
                    value: 'text'
                },
                {
                    label: 'Getal',
                    value: 'number'
                },
                {
                    label: 'Bedrag',
                    value: 'currency'
                },
                {
                    label: 'Getal + eenheid',
                    value: 'numberUnit'
                },
                type === 'question' ? {
                    label: 'Schaal',
                    value: 'score'
                } : null,
                {
                    label: 'Enkele keuze',
                    value: 'radio'
                },
                {
                    label: 'Meerkeuze',
                    value: 'checkbox'
                },
                {
                    label: 'Datum',
                    value: 'date'
                },
                {
                    label: 'Bijlage',
                    value: 'upload'
                }
            ]

            return options.filter(option => option)
        }
    },
    watch: {
        configuringIndicator: function() {
            this.handleConfigurationValidation()
        }
    }
}
</script>

<style lang="scss" scoped>

.modal {
    width: 800px;

    .header,
    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header {
        padding-bottom: 32px;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 0 10px 24px 0;
        max-height: calc(80vh - 160px);
        overflow-x: hidden;
        overflow-y: auto;

        .name {
            font-weight: 500;
        }

        .line {
            display: flex;
            align-items: center;

            .inline {
                display: flex;
                align-items: center;
                gap: 8px;
                min-width: 200px;
                width: 200px;

                .optional-field {
                    color: grey;
                    font-size: 12px;
                }
            }

            .user-input {
                padding: 0;
            }

            .text-input {
                flex-grow: 1;
                margin-bottom: -7.5px;
            }

            .user-options {
                display: flex;
                align-items: center;

                .radio {
                    width: 100px !important;
                    margin: 0 !important;
                }
            }
        }

        .answer-type-configuration {
            .inline {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .answer-type-options {
                padding-top: 16px;
            }
        }
    }

    .footer {
        padding-top: 32px;
        border-top: 1px solid #DEE2E6;

        .mandatory-fields {
            display: flex;
            align-items: center;
            gap: 12px;

            .tooltip {
                opacity: 0;
                scale: .5;
                pointer-events: none;
                transition: .2s ease;

                &.show {
                    scale: 1;
                    opacity: 1 !important;
                    pointer-events: all !important;
                }
            }
        }
    }
}

</style>