
/**
 * Object responsible for converting a filter object with id - value pairs and label averages to a where query for the api-gateway
 */
class Query {

    constructor() {
        console.log('%cinitiated new query object', 'color: aqua');
    }

    /**
     * Adds reference filter to query;
     * @param {Object} filter object with masterId - value pairs
     */
    addFilter(filter) {
        this.referenceFilter = filter;

        return this;
    }

    /**
     * Adds label average filter to query
     * @param {Object} averages object with labelId - [min, max] pairs
     */
    addLabelAverage(averages) {
        this.labelAverages = averages;

        return this;
    }

    /**
     * Howmany records to get (limit)
     */
    first(first_) {
        this.first_ = first_;

        return this;
    }

    /**
     * howmany records to skip (offset)
     */
    skip(skip_) {
        this.skip_ = skip_;

        return this;
    }

    /**
     * Generates query based on current filter options, skip and first
     */
    get() {
        let labelAverageFilters = []
        let andReferenceFilters = []
        let orReferenceFilters = []
        let andFilters = [{
            AND: [ 
                { info_status: 'finished' },
                {
                    OR: [
                        { inTrial__exists: false },
                        { inTrial__in: [ null, false ] }
                    ]
                }
            ]
        }]

        let postcode = undefined;
        let radiusTo = undefined;

        // Reference filter 
        Object.keys(this.referenceFilter)
            .forEach(masterId => {
                const filter = this.referenceFilter[masterId];

                let firstDate;
                let secondDate;
            
                switch (masterId) {
                    case 'project-start-date':
                        firstDate = new Date(filter[0] + '-1-1').getTime();
                        secondDate = new Date(filter[1] + '-12-31').getTime();

                        andReferenceFilters.push({
                            components__some: {
                                masterIndicatorId: masterId,
                                responses__some: {
                                    value__gte: firstDate,
                                    value__lte: secondDate
                                }
                            }
                        })
                        break;
                    case 'project-end-date':
                        firstDate = new Date(filter[0] + '-1-1').getTime();
                        secondDate = new Date(filter[1] + '-12-31').getTime();

                        andReferenceFilters.push({
                            components__some: {
                                masterIndicatorId: masterId,
                                responses__some: {
                                    value__gte: firstDate,
                                    value__lte: secondDate
                                }
                            }
                        })
                        break;
                    case 'review':
                        andFilters.push({
                            clientAverage__gte: filter[0] === 0 ? 0.001 : filter[0],
                            clientAverage__lte: filter[1]
                        })
                        break;
                    case 'budget':
                        andReferenceFilters.push({
                            components__some: {
                                masterIndicatorId: masterId,
                                responses__some: {
                                    value__gte: filter[0] === 0 ? 0.001 : filter[0],
                                    value__lte: filter[1]
                                }
                            }
                        })
                        break;
                    case 'radius':
                        radiusTo = this.referenceFilter[masterId]
                        break;
                    case 'postcode':
                        postcode = this.referenceFilter[masterId]
                        break;
                    case 'client':
                        if (filter && filter.length > 0)
                            andReferenceFilters.push({
                                components__some: {
                                    masterIndicatorId: 'client',
                                    responses__some: {
                                        value__in: filter
                                    }
                                }
                            })
                        break;
                    case 'contractor':
                        if (filter && filter.length > 0)
                            andReferenceFilters.push({
                                components__some: {
                                    masterIndicatorId: 'contractor',
                                    responses__some: {
                                        value__in: filter
                                    }
                                }
                            })
                        break;
                    default:
                        if (typeof filter === 'object' && filter.length === 0) break;
                        filter.forEach(value => {
                            orReferenceFilters.push({
                                components__some: {
                                    masterIndicatorId: masterId,
                                    responses__some: {
                                        value
                                    }
                                }
                            })
                        })
                        break;
                }
            })

        // Label averages
        Object.keys(this.labelAverages)
            .forEach(labelId => {

                const greaterThan = this.labelAverages[labelId][0] === 0 ?
                                    0.0001 :
                                    this.labelAverages[labelId][0]
                const lessThan = this.labelAverages[labelId][1]

                labelAverageFilters.push({
                    labels__some: {
                        labelId,
                        average__gte: greaterThan,
                        average__lte: lessThan
                    }
                })
            })

        const where = {
            AND: [
                ...andFilters,
                {
                    forms__some: {
                        AND: [
                            {
                                categoryId: "final-measurement"
                            },
                            ...labelAverageFilters
                        ]
                    }
                },
                {
                    forms__some: {
                        AND: [
                            {
                                AND: andReferenceFilters
                            },
                            {
                                OR: orReferenceFilters
                            }
                        ]
                    }
                }
            ]
        }
        
        return {
            where,
            skip: this.skip_,
            first: this.first_,
            postcode,
            radiusTo
        };
    }
}

export default Query;