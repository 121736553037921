<template>
    <!-- skipping form popup -->
    <q-popup>
        <div class="modal">
            <div class="vertical-center">
                <h2>Tussentijdse meting overslaan</h2>
            </div>

            <div class="modal-content">
                <p>
                    Als je de tussentijdse Better Performance meting overslaat kun je direct de eindmeting starten.
                </p>
                <br />
                <p>
                    Let op! Als je aan de eindmeting bent begonnen kun je geen tussentijdse metingen meer toevoegen aan het
                    project.
                </p>
                <br />
                <p>Licht hieronder toe waarom je de tussentijdse meting voor dit project wil overslaan.</p>
                <div class="input flex-end">
                    <q-tag
                        class="required-tag"
                        :class="skippedReason && skippedReason.length !== 0 ? 'hide' : 'show'"
                        size="small"
                        >verplicht</q-tag
                    >
                </div>
                <q-text
                    autofocus
                    class="input-text"
                    placeholder="Type hier je toelichting"
                    v-model="skippedReason"
                    maxlength="1000"
                ></q-text>
            </div>
            <div class="footer">
                <q-button variation="blank" class="red-hover" :disabled="loading" @click="closePopup">Annuleren</q-button>
                <q-button :disabled="!skippedReason" @click="handleSkipIntermediateMeasurement" :loading="loading">Ja, tussentijdse meting overslaan</q-button>
            </div>
        </div>
    </q-popup>
</template>

<script>
import { PROJECT_SKIP_INTERMEDIATE_MEASUREMENT } from '@/graphql/mutations';
import { executeParentFunction } from '@/assets/js/utils';

export default {
    name: 'form-skip-review',
    props: {
        project: {
            type: Object,
            required: true
        },
        form: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            skippedReason: '',
            loading: false
        };
    },
    methods: {
        // nieuwe route/mutation: project_skipIntermediateMeasurement(id, formId)

        // moet formulier voor beide partijen verwijderen uit:
        // -mongo
        // -project.formIds 
        // -project.timeline

        async handleSkipIntermediateMeasurement() {
            this.loading = true;

            const id = this.project.id;
            const formId = this.form.id;
            const reason = this.skippedReason;

            this.$apollo.mutate({
                mutation: PROJECT_SKIP_INTERMEDIATE_MEASUREMENT,
                variables: {
                    id,
                    formId,
                    reason
                }
            })
            .then(result => {
                const { timeline } = result.data.project_skipIntermediateMeasurement;
                const formIds = this.project.formIds.filter(projectFormId => projectFormId !== this.form.id && projectFormId !== this.form.companionIds[0]);
                const forms = this.project.forms.filter(form => form.id !== this.form.id && form.id !== this.form.companionIds[0]);

                executeParentFunction(this, 'project', 'handleIntermediateMeasurementSkipped', { formIds, forms, timeline });

                this.$store.commit('notify', { type: 'success', message: 'De tussentijdse meting wordt overgeslagen' });
                this.loading = false;
            })
            .catch(error => {
                console.log(error)
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het overslaan van de tussentijdse meting' });
                this.loading = false;
            })
        },
        closePopup() {
            return this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

.modal {
    width: 600px !important;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px 5px 5px 5px;
        border-top: 1px solid #dee2e6;
    }

    .red-hover :hover {
        color: $color-red;
        transition: color 200ms ease;
    }
}

.modal-content {
    margin: 20px 0 20px 0;
}

.input {
    margin: 0 0 8px 0;
}

.required-tag {
    transition: 0.4s ease;

    &.hide {
        transform: translateY(30px);
    }
}
</style>
