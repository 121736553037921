<template>
    <q-popup>
        <div class="modal">
            <h2 class="pb-s">Formulier verwijderen</h2>
            <jump-transition>
                <div v-if="showResponsesMessage">
                    <p>
                        <strong>Let op!</strong> Er {{ formResponses === 1 ? 'wordt' : 'worden' }} {{ formResponses }} {{ formResponses === 1 ? 'respondent' : 'respondenten' }} mee verwijderd.
                    </p>
                    <br>
                </div>
            </jump-transition>
            <p>
                Weet u zeker dat u het formulier <strong>{{ deleteFormModal.name }}</strong> wilt verwijderen?
            </p>
            <div class="footer space-between">
                <q-button
                    variation="blank"
                    class="red-hover"
                    @click="closePopup"
                    >Annuleren</q-button
                >
                <q-button
                    @click="deleteForm"
                    size="medium"
                    variation="danger"
                    :disabled="projectIsFinished"
                    >Formulier verwijderen</q-button
                >
            </div>
        </div>
    </q-popup>
</template>

<script>
export default {
    name: 'form-delete-modal',
    props: {
        deleteFormModal: {
            type: Object,
            required: false,
        },
        project: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showResponsesMessage: false,
            formResponses: 0
        };
    },
    methods: {
        closePopup() {
            this.$emit('close');
        },
        deleteForm() {
            this.$emit('form', this.deleteFormModal);
            this.closePopup();
        },
    },
    computed: {
        projectIsFinished: function() {
            const statusKey = `${this.$store.getters.getOrganisationType}Status`;
            return this.project[statusKey] === 'finished'
        }
    },
    async mounted() {
        this.formResponses = this.deleteFormModal.responseIds?.length || 0;
        if(!this.formResponses > 0) return
        
        setTimeout(() => {
            this.showResponsesMessage = true;
        }, 500);
    }
};
</script>

<style lang="scss" scoped>
@import '../../../../components/qds/assets/style/_variables.scss';
@import '../../../../components/qds/assets/style/fonts/fonts.css';

$base-color: #eef0f1;
$shine-color: #e3e7e9;
$animation-duration: 2.6s;

.modal {
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;

    .modal-content {
        // height: 260px
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: calc(100% - 80px);
        margin: 0 -40px;
        padding: 20px 40px;
        border-top: 1px solid rgb(222, 226, 230);
    }

    .red-hover :hover {
        color: $color-red;
        transition: color 200ms ease;
    }
}
</style>
