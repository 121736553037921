<template>
    <div class="detail-page-padding" style="max-width: 900px;">
        <div class="space-between">
            <div class="settings-back">
                <arrow-button color="#aeb5bc" direction="left" @click="$router.go(-1)">Beheer</arrow-button>
            </div>
        </div>
        <div class="mb-l">
            <div class="settings-intro">
                <div class="space-between vertical-center mb-m">
                    <h1>Informatiebeveiliging</h1>
                </div>
                <p>
                    Hier kan je alle zaken rondom informatiebeveiliging instellen zoals 2-staps authenticatie, rollen en rechten.
                </p>
            </div>
        </div>

        <div class="q-card pb-xs mb-xl">
            <div class="account-intro mb-s">
                <h3>Twee Staps Authenticatie</h3>
                <p>
                    Twee-staps-authenticatie is een extra beveiligingslaag voor je Qfact account, die is ontworpen om ervoor te zorgen dat jij de enige persoon bent die toegang heeft tot je account, zelfs als iemand anders je wachtwoord weet.<br>
                    Op deze plek kan je Twee-staps-authenticatie voor alle collega’s activeren. Je collega’s kunnen dit ook zelf op individuele basis doen via het menu rechtsboven en vervolgens klikken op ‘Profiel beheren’
                </p>
            </div>

            <q-section hPadding="40" vPadding="13" class="row">
                <div class="col-xs-5 label">
                    <p>Twee Staps Authenticatie</p>
                </div>
                <div :class="['col-xs-6', 'fixed-width']">
                    <div class="row">
                        <p>{{ twofactor.enabled ? 'Ingeschakeld' : 'Uitgeschakeld' }}</p>
                    </div>
                </div>
                <div class="col-xs-1 end">
                    <q-switch
                        :disabled="!ability.get().can('update_details', 'Organisation')"
                        v-model="twofactor.enabled"
                        @input="updateSettings({ twofactor: twofactor.enabled })"
                    ></q-switch>
                </div>
            </q-section>

            <q-section v-if="twofactor.enabled" hPadding="40" vPadding="13" borderTop class="row mt-s">
                <div class="col-xs-5 label">
                    <p>Verplicht 2FA rollen</p>
                </div>
                <div
                    :class="[
                        'fixed-width',
                        editing.includes('slug') ? 'col-xs-4' : 'col-xs-6'
                    ]"
                >
                    <q-input labelPosition="inside"
                        v-if="editing.includes('slug')"
                        size="small"
                        v-model="slug"
                        @input="checkSlug"
                        :state="slugState"
                        :errorMessage="slugErrorMessage"
                        variation="blank"
                        class="no-padding input-margin"
                        placeholder="Voeg rollen toe"
                    ></q-input>
                    <div v-else>
                        <p>{{ twofactor.requiredRoles.length > 0 ? '-' : 'Iedereen' }}</p>
                    </div>
                </div>
                <div :class="['end', editing.includes('slug') ? 'col-xs-3' : 'col-xs-1']">
                    <div>
                        <div v-if="editing.includes('slug')">
                            <q-button variation="blank" size="xsmall" @click="toggle('slug')" class="cancel-button"
                                >Annuleren</q-button
                            >
                            <q-button variation="ghost" size="xsmall" @click="updateSso({ slug })">Opslaan</q-button>
                        </div>
                        <q-button disabled v-else variation="light" size="xsmall" @click="toggle('slug')"
                            >Wijzigen</q-button
                        >
                    </div>
                </div>
            </q-section>
        </div>

        <div class="q-card pb-xs mb-xl">
            <div class="account-intro mb-s">
                <h3>Toegestane e-mailextensies voor gebruikers</h3>
                <p>
                    Gebruikers kunnen eenvoudig toegang krijgen tot uw organisatie door met een whitelisted e-mailextensie in te loggen. 
                    Gebruikers die een van de gedefinieerde email extenties bevatten in hun email, 
                    zullen bij het inloggen een suggestie krijgen voor deze organisatie. 
                    Voor meer informatie hierover neem contact op met Qfact.
                </p>
            </div>

            <q-section hPadding="40" vPadding="13" class="row mt-s">
                <div class="col-xs-5 label">
                    <p>E-mailextensies</p>
                </div>
                <div
                    :class="[
                        'fixed-width',
                        editing.includes('whitelistedEmails') ? 'col-xs-4' : 'col-xs-6'
                    ]"
                >
                    <q-input labelPosition="inside"
                        v-if="editing.includes('whitelistedEmails')"
                        size="small"
                        v-model="emailAccess.showWhitelistedEmails"
                        :state="slugState"
                        :errorMessage="slugErrorMessage"
                        variation="blank"
                        class="no-padding input-margin"
                        placeholder="extensie.nl, bedrijf.nl"
                    ></q-input>
                    <div v-else>
                        <p>{{ emailAccess.showWhitelistedEmails ? emailAccess.showWhitelistedEmails : '-' }}</p>
                    </div>
                </div>
                <div :class="['end', editing.includes('whitelistedEmails') ? 'col-xs-3' : 'col-xs-1']">
                    <div>
                        <div v-if="editing.includes('whitelistedEmails')">
                            <q-button
                                variation="blank"
                                size="xsmall"
                                @click="toggle('whitelistedEmails')"
                                class="cancel-button"
                                >Annuleren</q-button
                            >
                            <q-button variation="ghost" size="xsmall" @click="updateWhitelistedEmails()"
                                >Opslaan</q-button
                            >
                        </div>
                        <q-button
                            :disabled="!ability.get().can('update_details', 'Organisation')"
                            v-else
                            variation="light"
                            size="xsmall"
                            @click="toggle('whitelistedEmails')"
                            >Wijzigen</q-button
                        >
                    </div>
                </div>
            </q-section>

            <q-section v-if="whitelistedEmails && whitelistedEmails.length > 0" hPadding="40" vPadding="13" borderTop class="row">
                <div class="col-xs-5 label">
                    <p>Hoe krijgen ze toegang</p>
                </div>
                <div class="col-xs-7">
                    <div class="row">
                        <q-label-switch 
                            v-model="extentionAccessType" 
                            :options="extentionAccessOptions" 
                            optionWidth="200px" 
                            @input="updateSettings({
                                whitelistedAutoApprove: extentionAccessType === 'automatic-access',
                                whitelistedDefaultRoleId: emailAccess.defaultRoleId
                            })"
                        ></q-label-switch>
                    </div>
                </div>
            </q-section>

            <q-section v-if="whitelistedEmails && whitelistedEmails.length > 0 && extentionAccessType === 'automatic-access'" borderTop hPadding="40" vPadding="13" class="row mt-s">
                <div class="col-xs-5 label">
                    <p>Standaard rol</p>
                </div>
                <div class="'fixed-width col-xs-7">
                    <q-dropdown
                        v-model="emailAccess.defaultRoleId"
                        :options="roleOptions"
                        size="small"
                        @input="handleSaveDefaultRole"
                    ></q-dropdown>
                </div>
            </q-section>
        </div>

        <div v-if="ability.get().can('view', 'Role')" class="panel q-card-small shared pb-xs">
            <div class="head space-between vertical-center">
                <h2>Rollen en rechten</h2>
                <div class="vertical-center">
                    <q-input
                        v-model="searchUserRole"
                        labelPosition="inside" 
                        size="small" 
                        style="width: 170px;" 
                        variation="blank" 
                        placeholder="Zoek een rol"
                    ></q-input>
                </div>
            </div>

            <q-table :data="searchedRoles" :columns="rolesColumns" :loading="rolesLoading" paddingInline="30px">
                <template v-slot:row="{ row, column }">
                    <span v-if="column == 'empty'" class="avatar-row padding-left"> </span>
                    <span v-else-if="column == 'name'">
                        <span>{{ $t(`${row[column]}`) }}</span>

                        <q-tag
                            v-if="defaultRoles.includes(row.id)"
                            style="margin-left: 16px;"
                            size="small"
                            variation="default"
                            >default rol</q-tag
                        >
                    </span>
                    <span v-else-if="column == 'status'">
                        <q-tag size="small" :variation="statusVariation(row.status)">{{
                            $t(`userOrgStatus.${row.status}`)
                        }}</q-tag>
                    </span>
                    <span v-else-if="column == 'actions'" class="action-button">
                        <q-button
                            v-if="!defaultRoles.includes(row.id) && ability.get().can('edit', 'Role')"
                            variation="light"
                            size="xsmall"
                            @click="edit(row)"
                            >Wijzigen</q-button
                        >
                        <q-button
                            v-else-if="defaultRoles.includes(row.id) || (ability.get().can('view', 'Role'))"
                            variation="light"
                            size="xsmall"
                            @click="edit(row)"
                            >Bekijken</q-button
                        >
                    </span>
                    <div v-else>
                        <span>{{ row[column] }}</span>
                    </div>
                </template>
            </q-table>
        </div>

        <q-popup v-if="createRole" showCloseButton @close="createRole = false">
            <role-modal 
                :editRole="editRole" 
                :defaultRole="defaultRole"
                @close="getRoles" 
                @closePopup="createRole = false"
            ></role-modal>
        </q-popup>
    </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton';
import RoleModal from '@/pages/app/settings/RoleModal';

import { ADD_WHITELISTED_EMAIL, REMOVE_WHITELISTED_EMAIL, ORG_UPDATE_LOGIN_SETTINGS } from '@/graphql/mutations';
import { ORG_SETTINGS, ROLES, PERMISSIONS, GET_ORGANISATION_USERS } from '@/graphql/queries';

import { extractError } from '@/assets/js/utils';

export default {
    components: {
        ArrowButton,
        RoleModal
    },
    data() {
        return {
            searchUserRole: '',
            twofactor: {
                enabled: false,
                requiredRoles: []
            },
            whitelistedEmails: [],
            extentionAccessOptions: [
                {
                    label: 'Toegang aanvragen',
                    value: 'request-access'
                },
                {
                    label: 'Automatisch toestaan',
                    value: 'automatic-access'
                }
            ],
            extentionAccessType: 'request-access',
            emailAccess: {
                enabled: false,
                autoApprove: false,
                showWhitelistedEmails: '',
                defaultRoleId: 'user-default',
                defaultRole: 'Gebruiker'
            },
            slugState: null,
            slugErrorMessage: '',
            editing: [],
            roles: [],
            rolesLoading: true,
            createRole: false,
            editRole: null,
            defaultRoles: ['admin-default', 'crow-admin-default', 'user-default'],
            roleOptions: [],
            rolesColumns: [
                {
                    field: 'name',
                    label: 'Rol',
                    width: '400px'
                },
                {
                    field: 'status',
                    label: 'Status',
                    width: '60px'
                },
                {
                    field: 'actions',
                    label: '',
                    align: 'right',
                    width: '60px'
                }
            ]
        };
    },
    watch: {
        whitelistedEmails() {
            const emails = this.whitelistedEmails || [];
            this.emailAccess.showWhitelistedEmails = emails.join(', ');
        }
    },
    methods: {
        handleSaveDefaultRole() {
            this.updateSettings({ 
                whitelistedAutoApprove: true,
                whitelistedDefaultRoleId: this.emailAccess.defaultRoleId
            });
        },
        toggle(type) {
            if (this.editing.includes(type)) this.editing = this.editing.filter(item => item !== type);
            else this.editing.push(type);
        },
        statusVariation(status) {
            switch (status) {
                case 'active':
                    return 'success';
                    break;
                default:
                    return 'danger';
                    break;
            }
        },
        getUsers() {
            const organisationId = this.$store.getters.getCurrentOrganisation.id
            this.$apollo 
                .query({
                    query: GET_ORGANISATION_USERS, 
                    variables: {
                        organisationId: organisationId,
                    }
                })
                .then(response => {
                   this.organisationUsers = response.data.organisation.users;
                })
                .catch(err => {
                    console.log(err);
                })
        },  
        edit(role) {
            this.getUsers();
            this.editRole = role;
            this.createRole = true;
        },
        closePopup() {
            this.createRole = false;
            this.editRole = null;
        },
        updateWhitelistedEmails() {
            const currentEmails = this.whitelistedEmails || [];
            const trimmedEmails = this.emailAccess.showWhitelistedEmails.replace(/\s/g, '');
            const emailsArray = trimmedEmails.split(',');
            const newEmails = emailsArray.filter(e => !currentEmails.includes(e));
            const removedEmails = currentEmails.filter(e => !emailsArray.includes(e));

            newEmails.forEach(email => this.addWhitelistedEmail(email).catch(err => console.log(err)));
            removedEmails.forEach(email => this.removeWhitelistedEmail(email).catch(err => console.log(err)));
        },
        async addWhitelistedEmail(email) {
            this.editing = [];
            if (!email) return;

            return this.$apollo
                .mutate({
                    mutation: ADD_WHITELISTED_EMAIL,
                    variables: { id: this.$store.getters.getCurrentOrganisation.id, email }
                })
                .then(response => {
                    this.whitelistedEmails = response.data.org_addWhitelistedEmail.whitelistedEmails || [];
                    this.$store.commit('notify', {
                        type: 'success',
                        message: `E-mailextensie ${email} is toegevoegd.`
                    });
                })
                .catch(err => {
                    this.emailAccess.showWhitelistedEmails = this.whitelistedEmails.join(', ');
                    this.$store.commit('notify', {
                        type: 'danger',
                        message: `Er ging iets mis bij het opslaan. Heeft u een correcte e-mailextensie ingevoerd?`
                    });
                });
        },
        async removeWhitelistedEmail(email) {
            this.editing = [];
            if (!email) return;

            return this.$apollo
                .mutate({
                    mutation: REMOVE_WHITELISTED_EMAIL,
                    variables: { id: this.$store.getters.getCurrentOrganisation.id, email }
                })
                .then(response => {
                    this.whitelistedEmails = response.data.org_removeWhitelistedEmail.whitelistedEmails || [];
                    this.$store.commit('notify', {
                        type: 'success',
                        message: `E-mailextensie ${email} is verwijderd.`
                    });
                })
                .catch(err => {
                    this.emailAccess.showWhitelistedEmails = this.whitelistedEmails.join(', ');
                    this.$store.commit('notify', {
                        type: 'danger',
                        message: `Er ging iets mis bij het opslaan. Heeft u een correcte e-mailextensie ingevoerd?`
                    });
                });
        },
        updateSettings({ twofactor, twofactorRoles, whitelistedAutoApprove, whitelistedDefaultRoleId }) {
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            return this.$apollo
                .mutate({
                    mutation: ORG_UPDATE_LOGIN_SETTINGS,
                    variables: {
                        organisationId,
                        twofactor,
                        twofactorRoles,
                        whitelistedAutoApprove,
                        whitelistedDefaultRoleId
                    }
                })
                .then(response => {
                    this.editing = [];
                    this.$store.commit('notify', { type: 'success', message: `Instelling opgeslagen` });
                    if(whitelistedDefaultRoleId) {
                        this.emailAccess.defaultRoleId = whitelistedDefaultRoleId;
                        this.emailAccess.defaultRole = this.roleOptions.find(option => option.value === whitelistedDefaultRoleId).label;
                    }
                })
                .catch(err => {
                    this.emailAccess.showWhitelistedEmails = this.whitelistedEmails.join(', ');
                    this.$store.commit('notify', {
                        type: 'danger',
                        message: `Er ging iets mis bij het opslaan`
                    });
                });
        },
        getSettings() {
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            this.$apollo
                .query({
                    query: ORG_SETTINGS,
                    variables: {
                        organisationId
                    }
                })
                .then(response => {
                    const { whitelistedEmails } = response.data.organisation;
                    const loginSettings =
                        response.data.organisation.settings && response.data.organisation.settings.login
                            ? response.data.organisation.settings.login
                            : {};
                    const { twofactor, twofactorRoles, whitelistedAutoApprove, whitelistedDefaultRole, whitelistedDefaultRoleId } = loginSettings;

                    this.whitelistedEmails = whitelistedEmails || [];
                    this.extentionAccessType = whitelistedAutoApprove ? 'automatic-access' : 'request-access';
                    this.twofactor.enabled = twofactor || false;
                    this.emailAccess.defaultRoleId = whitelistedDefaultRoleId || 'user-default';
                    this.emailAccess.defaultRole = whitelistedDefaultRole
                        ? this.$t(`${whitelistedDefaultRole.name}`)
                        : '-';
                })
                .catch(err => {
                    this.$store.commit('notify', extractError(err));
                });
        },
        getRoles() {
            this.rolesLoading = true;
            this.createRole = false;

            this.$apollo
                .query({
                    query: ROLES,
                    fetchPolicy: 'no-cache'
                })
                .then(res => {
                    this.rolesLoading = false;
                    this.roles = res.data.roles;
                    this.roleOptions = res.data.roles.map(role  => {
                        return {
                            label: role.name,
                            value: role.id
                        }
                    });
                })
                .catch(err => {
                    this.rolesLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        validateSsoInput({ slug }) {
            if (slug === '') {
                this.$store.commit('notify', { type: 'danger', message: 'slug cannot be empty' });
                return false;
            }
            return true;
        }
    },
    computed: {
        searchedRoles() {
            return this.roles.filter(role => {
                const includedRole = role.name ? role.name.toLowerCase().includes(this.searchUserRole.toLowerCase()) : false
                return includedRole;
            })
        },
        opacity() {
            return this.twofactor.enabled ? {} : { opacity: '0.2' };
        },
        ssoEnabledTag() {
            return this.twofactor.enabled ? 'Ingeschakeld' : 'Uitgeschakeld';
        },
        ssoStatusColor() {
            return this.twofactor.enabled ? 'success' : 'default';
        },
        slugPlaceholder() {
            const orgName = this.$store.getters.getCurrentOrganisation.name;
            const slug = orgName
                .split(' ')
                .join('-')
                .toLowerCase();
            return slug;
        },
        loginUrlPlaceholder() {
            return 'https://sso.login.url';
        },
        getEntityId() {
            return process.env.API_GATEWAY_URI;
        },
        defaultRole() {
            return this.editRole ? this.defaultRoles.includes(this.editRole.id) : false;
        }
    },
    created() {
        this.getSettings();
        this.getRoles();
    }
};
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

span {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

.divider {
    margin-left: -3px;
}

.panel {
    max-width: 900px;
    width: 100%;
    // padding-bottom: 10px;
    margin-bottom: 40px;

    &.shared {
        .head {
            padding: 40px;
        }
    }

    & .padding-left {
        padding-left: 40px;
    }

    & .padding-right {
        padding-right: 40px;
    }

    .action-button {
        display: flex;
        justify-content: flex-end;
    }

    .avatar-row {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: -3px;
    }
}

.no-padding {
    padding: 0 !important;
}

.account-intro {
    padding: 40px 40px 0px 40px;
}

.account-intro > p {
    margin-top: 15px;
}

.row > .label > p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
}

.fixed-width p {
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fixed-width {
    height: 28px;
    display: flex;
    align-items: center;
}

// .fixed-width > div {
//     display: flex;
//     align-items: center;
// }

.end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.input-margin {
    margin: -1px 0px 0px -7px;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 150ms;
}

.cancel-button {
    margin-right: 8px;
}
* {
    font-family: Gotham;
}
</style>
