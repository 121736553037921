import _, { isString } from 'lodash';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Papa from 'papaparse';
import { REFRESH, ACCESS, EMVI_TOKEN } from '@/graphql/mutations';
import { ORGANISATION, GET_PROJECTS, ORG_MINE } from '@/graphql/queries';
import { store } from '@/store';

export const userInitials = (firstName, lastName) => {
    if (!firstName && !lastName) return '';
    const first = firstName ? firstName.charAt(0).toUpperCase() : '';
    if (lastName.includes(' ')) lastName = lastName.split(' ').pop();
    const last = lastName ? lastName.charAt(0).toUpperCase() : '';
    return `${first}${last}`;
};

// initials = amount of initials to be returned
export const companyInitials = (companyName, initials = 2) => {
    if(!companyName) return '';
    const re = new RegExp(" *gemeente *");
    const name = companyName.toLowerCase().replace(re, '');
    return name.substr(0, initials).toUpperCase()
};

export const capitalize = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isAdminRole = (roleId) => {
    const adminRoles = ['admin-default', 'crow-admin-default'];
    return adminRoles.includes(roleId);
}

export const getAddress = address => {
    if (!address) return '';

    const line1 = address.line1 ? address.line1 : '';
    const line2 = address.line2 ? address.line2 : '';
    const city = address.city ? address.city : '';

    let text = '';

    if (line1) text += line1;
    if (line2) text += ` ${line2}`;
    if (city) {
        if (text === '')
            text += city;
        else
            text += ` in ${city}`;
    }

    return text.replace(/ null | undefined /g, ' ');
};

/**
 * This method should be compatible to send any paramter. It will extract the right date or return an empty string
 *
 * JS generates timestamps in MILISECONDS with "new Date().getTime()"
 * This method is compatible with either, However it is a convention to use timestamps SECONDS
 */
export const getDate = timestamp => {
    if (!timestamp) return '';
    const digitsInTimestamp = timestamp.toString().length;
    // const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const shortMonthsNL = [
        'jan.',
        'feb.',
        'maart',
        'apr.',
        'mei',
        'jun.',
        'jul.',
        'aug.',
        'sep.',
        'okt.',
        'nov.',
        'dec.'
    ];

    /**
     * if timestamp is in miliseconds
     */
    const date = digitsInTimestamp <= 10 ? new Date(timestamp * 1000) : new Date(timestamp);
    return `${date.getDate()} ${shortMonthsNL[date.getMonth()]} ${date.getFullYear()}`;

    // if (digitsInTimestamp <= 10) {
    //     date = new Date(timestamp * 1000);
    // } else {
    //     date = new Date(timestamp);

    //     // return date.toLocaleDateString('nl-NL');
    // }
};

export const getNumericDate = timestamp => {
    if (!timestamp) return '';
    const digitsInTimestamp = timestamp.toString().length;
    const date = digitsInTimestamp <= 10 ? new Date(timestamp * 1000) : new Date(timestamp);
    
    return date.toLocaleDateString('nl-NL', { day: 'numeric', month: 'numeric', year: 'numeric' });
};

/**
 *
 * @param {Number | String} number
 */
export const getMoney = number => {
    if (isNaN(number) || number == 'NaN') return '';

    return new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    }).format(parseFloat(number));
};

export const isValidAnswer = answerValue => {
    return !(answerValue === null ||
        answerValue === undefined ||
        answerValue === '' ||
        (Array.isArray(answerValue) && answerValue.length === 0) ||
        (typeof answerValue === 'object' && !Object.values(answerValue).some(value => isValidAnswer(value))))
};

// finds parent with a name matching the specified componentName
// and executes the specified function (name) with the passed params
// vm = this
// componentName = Name of component to execute function in
// functionName = Name of the function to execute (function must exist in specified component!)
// params = Params given with the function (object)
export const executeParentFunction = (vm, componentName, functionName, params = {}) => {
    const MAX = 10;

    try {
        let component = vm;
        for(let i = 0; i < MAX; i++) {
            component = component.$parent;
            const name = component.$options.name;
            if(componentName && name === componentName) return component[functionName](params)
        }
    } catch(error) {
        console.log(error)
        return
    }
    console.warn(`No close parent found matching name '${componentName}'\nPerhaps your component is rendered in a different DOM structure (via Vue Portal)?`);
    return
};

const MESSAGES = {
    'Cannot find user associated with this email address': 'De logingegevens lijken niet te kloppen', //login
    'Incorrect password': 'Het wachtwoord is niet correct', //login
    'Too many requests': 'Er zijn teveel verzoeken ingediend.',
    'password must contain at least 8 characters, 1 number, 1 special character and both upper and lowercase characters':
        'Wachtwoord moet 8 karakters, 1 nummer, 1 speciaal ...', //register
    'provided password and confirmPassword do not match': 'Opgegeven wachtwoorden zijn niet hetzelfde', //register
    'should match format "email"': 'Er is geen correct e-mailadres opgegeven', //register
    'User is already related to this organisation': 'U bent reeds gerelateerd aan dit project',
    'No user found': 'E-mailadres niet gevonden', //password reset
    'Token incorrect': 'De opgegeven code is niet correct', //2FA,

    'name is not valid': 'Naam is niet valide'
};

const UNKNOW_MESSAGE = 'Er is iets mis gegaan';
const SERVER_ERROR_MESSAGE = 'Server error';

export const extractError = err => {
    let errorObject = {
        type: null,
        message: null
    };

    const errors = err.graphQLErrors;

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            if (error.path && error.path.includes('user_refreshJwt')) {
                return;
            }

            if (error.message.split(',').includes('Too many requests')) {
                errorObject = {
                    type: 'warning',
                    message: MESSAGES['Too many requests']
                };
                return;
            }

            if (error.message) {
                const errorLogging = localStorage.getItem('logging');
                errorObject = {
                    type: 'danger',
                    message:
                        errorLogging && errorLogging === 'verbose'
                            ? error.message
                            : 'Oeps, er ging iets mis. Probeer het nogmaals of neem contact op met de helpdesk.'
                };
            } else {
                if (!error.extensions || !error.extensions.response) return;

                const body = error.extensions.response.body;

                if (body instanceof Object)
                    errorObject = {
                        type: 'danger',
                        message: MESSAGES[body.message]
                    };
                else if (body instanceof Array)
                    errorObject = {
                        type: 'danger',
                        message: errorObject
                    };
                else if (body instanceof String)
                    errorObject = {
                        type: 'danger',
                        message: MESSAGES[body]
                    };
                else {
                    if (isJson(body)) {
                        const obj = JSON.parse(body);

                        errorObject = {
                            type: 'danger',
                            message: MESSAGES[obj[0].message]
                        };
                    } else {
                        errorObject = {
                            type: 'danger',
                            message: MESSAGES[body]
                        };
                    }
                }
            }
        });
    }

    if (!errorObject.message) {
        if (process.env.NODE_ENV !== 'production') {
            console.error("Error message, did you forget to add it to 'MESSAGES'?");
            console.error(err);
        }
        errorObject = {
            type: 'danger',
            message: 'Er ging iets fout, probeer dit later opnieuw'
        };
        return errorObject
    }

    store.commit('notify', errorObject);
    // else callBack(errorObject);
};

export const validateEmail = email => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
};

export const numbersOnly = string => {
    // var re = /^[0-9]{9,11}$/;
    var re = /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/;
    return re.test(string);
};

export const validatePhoneNumber = phone => {
    const parsedPhone = parsePhoneNumberFromString(phone, 'NL');
    return numbersOnly(phone) && parsedPhone ? parsedPhone.number : '';
};

const isJson = str => {
    try {
        JSON.parse(str);
    } catch (err) {
        return false;
    }
    return true;
};

// Refreshing jwt based on refreshToken.
// Using lodash throttle to prevent multiple graphql calls to call refresh unnecessarily
export const refreshJwt = _.throttle(
    function(vm) {
        if (!vm) return;
        const refreshToken = vm.$store.getters.getRefreshToken;
        if(!refreshToken) return;

        vm.$apollo
            .mutate({
                mutation: REFRESH,
                variables: { refreshToken },
                fetchPolicy: 'no-cache'
            })
            .then(res => {
                vm.$store.commit('setJwt', res.data.user_refreshJwt.jwt);
                vm.$store.commit('setRefreshToken', res.data.user_refreshJwt.refreshToken);
            })
            .catch(err => extractError(err, vm.$store));
    },
    5000,
    { trailing: false }
);

// Handle successful login
// Extract permissions
// Check token type
// Extract organisation settings/products
export const handleLoginSuccess = (vm, authPayload) => {
    vm.$store.commit('setJwt', authPayload.jwt);

    vm.$store.commit('setRefreshToken', authPayload.refreshToken);
    vm.$store.commit('setUser', authPayload.user);
    vm.$store.commit('resetNotifs');

    vm.ability.set(vm.$store.getters.getJwtPayload);

    if (authPayload.twofactorRequired) {
        vm.$store.commit('setTwofactorJwt', authPayload.jwt);

        if (authPayload.twofactorQRcode) {
            vm.$store.commit('setTwofactorQrCode', authPayload.twofactorQRcode);
            vm.$router.push('/twofactorconfig');
        } else {
            vm.$router.push('/twofactor');
        }
    } else {
        return initializeAccessOrganisation(vm);
    }
};

export const initializeAccessOrganisation = async vm => {
    const { organisationId, roleId } = vm.$store.getters.getJwtPayload.data.authMeta;
    const routeBeforeLogout = localStorage.getItem('lastPath');
    const lastLogoutTime = localStorage.getItem('lastLogoutTime') || 0;
    const user = vm.$store.getters.getUser;
    let targetUrl = localStorage.getItem('targetUrl');


    if(vm.$store.getters.getJwtPayload.data.authMeta.accessUserId) targetUrl = null;
    const now = Date.now() / 1000;

    // check token type
    if (!organisationId) {
        const results = await vm.$apollo.query({
            query: ORG_MINE,
            fetchPolicy: 'no-cache'
        }).catch(err => {
            console.log(err);
            return [];
        });
        const organisations = results.data.org_mine;


        // if token does not specify an organisation, go to /acces
        if (targetUrl) {
            console.log('/targeturl')
            localStorage.removeItem('targetUrl');
            vm.$router.push(targetUrl);
        } else if (routeBeforeLogout && lastLogoutTime > now - 3600) {
            console.log('/routebeforelogout')
            localStorage.removeItem('lastPath');
            localStorage.removeItem('lastLogoutTime');
            vm.$router.push(routeBeforeLogout);
        } else {
            console.log('/access')
            vm.$router.push('/access').catch(err => undefined);
        }
    } else {
        // if token specifies an organisationId
        // Query organisation products
        return vm.$apollo
            .query({
                query: ORGANISATION,
                variables: { organisationId },
                fetchPolicy: 'no-cache'
            })
            .then(async res => {
                const { organisation } = res.data;

                // Store name + logo
                vm.$store.commit('setCurrentOrganisation', organisation);

                // Check if organisation
                const { hasActiveQfactProducts, hasActiveHybridMode, hasActiveEmviProducts, hasPDProduct, hasQfactPD, getJwt } = vm.$store.getters;
                // skip the check for active Qfact project IF Qfact is activated
                const hasQfactAccess = isAdminRole(roleId) ? true : await vm.$apollo
                          .query({
                              query: GET_PROJECTS,
                              variables: { first: 1, where: {} },
                              fetchPolicy: 'no-cache'
                          })
                          .then(res => res.data.projects.length > 0)
                          .catch(err => {
                              console.log(err);
                              return false;
                          });

                vm.$store.commit('setHasQfactAccess', hasQfactAccess);
                console.log(vm.$store.getters.getJwtPayload.data.authMeta.accessUserId, targetUrl)

                if(vm.$store.getters.getJwtPayload.data.authMeta.accessUserId) targetUrl = null;

                if ((hasActiveHybridMode || hasPDProduct) && !hasQfactPD) {
                    console.log(`User redirected to EMVI trying to access organisation "${organisationId}" in HYBRID MODE`);
                    const { user_emviToken } = (await vm.$apollo.mutate({ mutation: EMVI_TOKEN })).data;
                    const targetUrl = `${process.env.EMVI_APP_URL}/authentication/login?token=${user_emviToken}&qfact_access=${hasQfactAccess}`;
                    window.location = targetUrl;
                } else if (hasActiveQfactProducts || hasQfactAccess || hasQfactPD) {
                    // Check target url else push root page '/'
                    if (targetUrl) {
                        localStorage.removeItem('targetUrl');
                        vm.$router.push(targetUrl);
                    } else if (routeBeforeLogout && lastLogoutTime > now - 3600) {
                        localStorage.removeItem('lastPath');
                        localStorage.removeItem('lastLogoutTime');
                        vm.$router.push(routeBeforeLogout);
                    } else {
                        if (
                            vm.$route.path.includes('/access') ||
                            vm.$route.path.includes('/sso') ||
                            vm.$route.path.includes('/twofactor') ||
                            vm.$route.path.includes('/login')
                        )
                            vm.$router.push('/').catch(err => undefined);
                        else vm.$router.go(vm.$router.currentRoute).catch(err => undefined);
                    }
                } else if (hasActiveEmviProducts) {
                    console.log(`User redirected to EMVI trying to access organisation "${organisationId}" in NON-HYBRID MODE`);
                    const { user_emviToken } = (await vm.$apollo.mutate({ mutation: EMVI_TOKEN })).data;
                    const targetUrl = `${process.env.EMVI_APP_URL}/authentication/login?token=${user_emviToken}&qfact_access=${hasQfactAccess}`;
                    window.location = targetUrl;
                } else {
                    if (targetUrl) {
                        localStorage.removeItem('targetUrl');
                        vm.$router.push(targetUrl);
                    } else {
                        vm.$router.push('/access').catch(err => undefined);
                        vm.$store.commit('notify', {
                            type: 'info',
                            message: `${organisation.name} is momenteel inactief. Neem contact op met de helpdesk.`
                        });
                    }
                }
            })
            .catch(err => {
                console.log(err);
                extractError(err, vm.$store);
            });
    }
};

export const getSmileyForCrowScore = average => {
    if(average == null) return 'unknown'
    return average === 0 ? 'unknown' : average < 5.5 ? 'sad' : average < 7 ? 'neutral' : 'happy';
};

export const updateDropdownPanels = _.debounce((vm, forceRecalculate = false) => {
    vm.$root.$emit('recalculatePanelPosition', forceRecalculate)
}, 250)

export const updateMenuState = function(vm, folded) {
    vm.$root.$emit('menuFolded', folded)
}

export const crowExportFileName = form => {
    let date = new Date()
    date.toLocaleString('en-GB', { hour: 'numeric' })

    const hour = date.getHours()
    const minutes = date.getMinutes()
    
    date = date.toISOString().split('T')[0]

    let formName = form.name
    let projectName = form.project.name

    return `${date}_${hour}-${minutes}_${formName}-${projectName}.pdf`;
};

export const canCreateNewProject = () => {
    const organisationType = store.getters.getOrganisationType;

    if(organisationType === 'contractor' && 
        (store.getters.hasPDBasisProduct || 
        store.getters.hasPDFullProduct ||
        store.getters.hasActiveQfactProducts || 
        store.getters.hasActiveHybridMode)) return true

    return organisationType === 'client' && !store.getters.hasActiveHybridMode && store.getters.hasActiveQfactProducts;
}

export const freemiumOrganisation = () => {
    const { products } = store.getters.getCurrentOrganisation;
    const slugs = products.map(product => product.slug);
    const licencedProducts = ['crow_contractor', 'pd_basis', 'pd_full', 'crow_client'];
    return !licencedProducts.some(product => slugs.includes(product));
}

export const getSurveyExportName = (survey) => {
    const date = new Date().toLocaleDateString('nl-BE').replace(/\//g, '-');
    return `${survey.name}-${date}.pdf`;
}

export const getReferenceletterExportName = (projectName) => {
    let date = new Date()
    date.toLocaleString('en-GB', { hour: 'numeric' })

    const hour = date.getHours()
    const minutes = date.getMinutes()

    date = date.toISOString().split('T')[0]

    let name = `${date}_${hour}-${minutes}_referentie-${projectName}`;

    return `${name}.pdf`
}

export const getAnswerTypeLabel = (displayType) => {
    switch(displayType) {
        case'text':
            return 'Tekst'
        case'number':
            return 'Getal'
        case'numberUnit':
            return 'Getal + eenheid'
        case'score':
            return 'Schaal'
        case'radio':
            return 'Enkele keuze'
        case'checkbox':
            return 'Meerkeuze'
        case'date':
            return 'Datum'
        case'upload':
            return 'Bijlage'
        case'currency':
            return 'Bedrag'
    }
}

export const getFullName = (firstName, lastName) => {
    if(!lastName || lastName == null) return firstName
    return `${firstName} ${lastName}`
}

export const parseSize = (size) => {
    const units = [' bytes', ' kb', ' mb', ' gb'];
    const step = 1000;
    let newSize = size;
    let i = 0;

    while(newSize > step) {
        newSize = newSize / step;
        i++;
    }

    return newSize.toFixed(1).replace('.0','') + units[i]
}

export const sortComponents = (components = []) => {
    return components.sort((component, nextComponent) => {
        const indicatorIndex = component.indicator?.index || 0;
        const nextIndicatorIndex = nextComponent.indicator?.index || 0;

        if(indicatorIndex < nextIndicatorIndex || indicatorIndex == null) return -1
        if(indicatorIndex === nextIndicatorIndex) return 0
        if(indicatorIndex > nextIndicatorIndex) return 1
    })
}

export const sortIndicators = (indicators = []) => {
    return indicators.sort((indicator, nextIndicator) => {
        const indicatorIndex = indicator?.index || 0;
        const nextIndicatorIndex = nextIndicator?.index || 0;

        if(indicatorIndex < nextIndicatorIndex) return -1
        if(indicatorIndex === nextIndicatorIndex) return 0
        if(indicatorIndex > nextIndicatorIndex) return 1
    })
}

// returns the max amount of rows to render in a paginated table
// for the best user experience across screen sizes
export const getCalculatedRows = (defaultRows = 10) => {
    const element = document.getElementById('qtable');
    if(!element) return defaultRows

    const { top } = element.getBoundingClientRect();
    const footerTop = 0 + (window.innerHeight * 0.03);
    const maxHeight = window.innerHeight - top - footerTop;

    const head = 26.5;
    const rows = Math.floor((maxHeight - head) / 63);

    return rows
}

export const isDefined = (value) => {
    return value !== null && value !== undefined
}

export const getRoleNameFromCurrentOrganisation = (roleId) => {
    const organisation = this.$store.getters.getCurrentOrganisation;
    const users = organisation.users || [];
    const roles = users.map(user => user.role).filter(role => !!role);
    
    const role = roles.find(role => role.id === roleId);
    if(role) return role.name;

    if(roleId === 'admin-default') return 'Beheerder';
    if(roleId === 'user-default') return 'Gebruiker';
}

function cleanArray(array, columns) {
    columns = columns.filter((column) => column.label !== '');

    array = array.map((item) => {
        let newItem = {};
        columns.forEach((column) => {
            newItem[column.label] = item[column.field];
        });
        return newItem;
    });

    return array;
}

/**
 * Builds a CSV (.csv) file from the report
 * @param {Array} resultset
 */
export const handleExportCsv = (array, columns, fileName) => {
    const cleanedArray = cleanArray(array, columns);
    const csv = Papa.unparse(cleanedArray, {
        quotes: false, //or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ";",
        header: true,
        newline: "\r\n",
        skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
        columns: null //or array of strings
    });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.click();
}