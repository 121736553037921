<template>
    <q-popup v-if="show" @close="handleClose" showCloseButton width="800px">
        <div class="modal">
            <div class="header">
                <h2>Project overzicht beheren</h2>
            </div>
            <div class="header-info mt-s mb-m">
                <p>Bekijk en wijzig hier de kenmerken uit je projectenoverzicht. Beheer de kenmerken die je wilt toevoegen of verwijderen uit het projectenoverzicht en pas de volgorde aan.</p>
            </div>
            <div class="content">
                <p class="label">Nieuw kenmerk toevoegen</p>
                <div class="indicator-selector flex">
                    <q-dropdown v-model="selectedLabelId" :options="labelOptions" :loading="loadingLabels" size="small" placeholder="Selecteer prestatie" @input="handleLabelSelected" autocomplete></q-dropdown>
                    <q-dropdown v-model="selectedIndicatorId" :options="indicatorOptions" :loading="loadingIndicators" size="small" placeholder="Selecteer kenmerk uit prestatie" autocomplete></q-dropdown>
                    <div class="center">
                        <q-button variation="secondary" size="small" @click="handleAddIndicator">Toevoegen</q-button>
                    </div>
                </div>
                <draggable :forceFallback="true" :list="configuration.columns" :animation="300" group="options" ghost-class="dragged-column-option" handle=".hamburger-icon" @change="handleUpdateConfiguration">
                    <transition-group name="list" class="options-container flex-column mt-s mb-s">
                        <div v-for="column in configuration.columns" class="column-option flex align-center space-between" :key="column.key">
                            <div class="left flex align-center">
                                <!-- <q-icon type="hamburger-menu" width="16" height="16" class="hamburger-icon" color="#ADB5BD"></q-icon> -->
                                <q-icon-button icon="hamburger-menu" iconWidth="16" iconHeight="16" class="hamburger-icon" color="#ADB5BD"></q-icon-button>
                                <span>{{ $t(column.info.name) }}</span>
                            </div>
                            <div class="right flex align-center">
                                <div class="flex align-center alignment-button">
                                    <q-tooltip>
                                        <q-icon-button icon="dynamic-alignment" :variation="column.align" @click="handleUpdateColumnAlignment(column)" iconWidth="16" iconHeight="12"></q-icon-button>
                                        <template v-slot:tooltip>
                                            <span>Hiermee wissel je tussen links of rechts uitlijnen</span>
                                        </template>
                                    </q-tooltip>
                                </div>
                                <div v-if="column.id !== 'name'">
                                    <q-button variation="danger" size="xsmall" @click="handleRemoveColumn(column)">Verwijderen</q-button>
                                </div>
                                <q-tooltip v-else>
                                    <q-icon type="QuestionCircle"></q-icon>
                                    <template v-slot:tooltip>
                                        <span>Het is niet mogelijk om de projectnaam te<br>verwijderen uit het projectenoverzicht</span>
                                    </template>
                                </q-tooltip>
                            </div>
                        </div>
                    </transition-group>
                </draggable>
            </div>
            <div class="footer flex space-between">
                <q-button variation="blank" @click="handleClose">Annuleren</q-button>
                <q-button variation="primary" @click="handleSaveConfiguration" :loading="savingChanges">Wijzigingen opslaan</q-button>
            </div>
        </div>
    </q-popup>
</template>

<script>
import _ from 'lodash';
import draggable from 'vuedraggable';

import { GET_LABELS, GET_INDICATORS } from '../../graphql/queries';
import { ORG_UPDATE_PROJECT_CONFIGURATION } from '../../graphql/mutations';

export default {
    name: 'project-configuration-popup',
    components: {
        draggable
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            configuration: null,
            labelOptions: [],
            indicatorOptions: [],
            cachedConfiguration: null,
            selectedLabelId: '',
            selectedIndicatorId: '',
            alignVariation: 'left',
            loadingLabels: false,
            loadingIndicators: false,
            savingChanges: false
        }
    },
    methods: {
        handleClose() {
            this.configuration = this.cachedConfiguration;
            this.handleUpdateConfiguration();
            this.$emit('close');
        },
        getParsedConfiguration(configuration) {
            if(!configuration) configuration = this.$store.getters.getProjectConfiguration;

            const parsedConfiguration = {
                columns: configuration.columns.map(column => {
                    return {
                        type: column.type,
                        key: column.key,
                        info: column.info,
                        id: column.id,
                        align: column.align
                    };
                })
            };
            return parsedConfiguration
        },
        handleUpdateConfiguration() {
            const configuration = this.getParsedConfiguration();
            this.$store.commit('setProjectConfiguration', this.configuration);
            this.$emit('configurationUpdated');
        },
        handleSaveConfiguration() {
            this.savingChanges = true;
            const currentOrganisation = this.$store.getters.getCurrentOrganisation;
            const configuration = this.getParsedConfiguration(this.configuration);

            this.$apollo.mutate({
                mutation: ORG_UPDATE_PROJECT_CONFIGURATION,
                variables: {
                    id: currentOrganisation.id,
                    configuration
                }
            })
            .then(result => {
                this.savingChanges = false;
                this.handleUpdateConfiguration(this.configuration);
                this.$emit('configurationSaved');
                this.$store.commit('notify', { type: 'success', message: 'Configuratie succesvol opgeslagen' });
            })
            .catch(error => {
                this.savingChanges = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het opslaan van de configuratie' });
            })
        },
        handleRemoveColumn(column) {
            const index = this.configuration.columns.findIndex(configColumn => configColumn.key === column.key);
            if(index !== -1) this.configuration.columns.splice(index, 1);
            this.handleUpdateConfiguration();
        },
        setConfiguration() {
            const configuration = this.$store.getters.getProjectConfiguration;
            this.configuration = configuration;
            this.cachedConfiguration = _.cloneDeep(configuration);
        },
        getLabelOptions() {
            this.loadingLabels = true;

            const { id } = this.$store.getters.getCurrentOrganisation;
            this.$apollo.query({
                query: GET_LABELS,
                variables: {
                    where: {
                        AND: [
                            { organisationId: id },
                            { status__in: ['active', 'disabled'] },
                            { masterId__not_contains: 'crow-' }
                        ]
                    },
                    sort: ['order__ASC']
                }
            })
            .then(result => {
                const labels = result.data.labels;
                const options = labels.map(label => {
                    return {
                        label: this.$t(label.name),
                        value: label.id,
                        masterId: label.masterId
                    }
                });
                const defaultLabel = {
                    label: 'Algemeen',
                    value: 'default'
                };
                this.labelOptions = [ defaultLabel, ...options ];
                this.loadingLabels = false;
            })
            .catch(error => {
                this.loadingLabels = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de prestaties' })
            })
        },
        getIndicatorOptions(option) {
            const { value: labelId, masterId } = option;
            const { id } = this.$store.getters.getCurrentOrganisation;
            const organisationType = this.$store.getters.getOrganisationType;
            if(labelId === 'default') return this.indicatorOptions = this.defaultOptions;

            this.loadingIndicators = true;

            this.$apollo.query({
                query: GET_INDICATORS,
                variables: {
                    where: {
                        AND: [
                            {
                                type: 'reference'
                            },
                            {
                                OR: [
                                    { labelIds: labelId },
                                    { 
                                        AND: [
                                            { labelIds: masterId },
                                            { organisationId: id }
                                        ] 
                                    }
                                ]
                            }
                        ]
                    }
                }
            })
            .then(result => {
                let indicators = result.data.indicators;
                if(masterId === 'default-context') indicators = indicators.filter(indicator => {
                    if(organisationType !== 'contractor' && indicator.masterId === 'contractor-branch') return false
                    return !['client','contractor'].includes(indicator.masterId)
                });
                if(masterId === 'crow-project-details') indicators = indicators.filter(indicator => !['discussed-psu'].includes(indicator.masterId));

                this.indicatorOptions = indicators.map(indicator => {
                    const useMasterId = ['default-context','crow-project-details'].includes(indicator.labelIds[0]);
                    const id = useMasterId ? indicator.masterId : indicator.id;
                    return {
                        label: this.$t(indicator.name),
                        value: id,
                        key: id,
                        id,
                        type: 'indicator',
                        align: 'left',
                        indicator
                    }
                });

                this.loadingIndicators = false;
            })
            .catch(error => {
                this.loadingIndicators = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de kenmerken' })
            })
        },
        handleLabelSelected(labelId) {
            const option = this.labelOptions.find(option => option.value === labelId);
            this.getIndicatorOptions(option);
        },
        handleAddIndicator() {
            let options = this.indicatorOptions;
            if(this.selectedLabelId === 'default') options = this.defaultOptions;

            const { value, key, id, type, indicator, align, stretch } = options.find(option => option.value === this.selectedIndicatorId) || {};
            const column = {
                type,
                key,
                id,
                info: {
                    name: indicator.name,
                    answerType: indicator.answerType,
                    displayType: indicator.displayType,
                    stretch
                },
                align,
                indicator
            };
            this.configuration.columns.push(column);

            this.handleUpdateConfiguration(this.configuration);
        },
        handleUpdateColumnAlignment(column) {
            const newAlign = column.align === 'left' ? 'right' : 'left';
            const columnIndex = this.configuration.columns.findIndex(configurationColumn => configurationColumn.id === column.id);
            if(columnIndex !== -1) this.configuration.columns[columnIndex].align = newAlign;

            this.handleUpdateConfiguration(this.configuration);
        }
    },
    created() {
        this.setConfiguration();
    },
    computed: {
        defaultOptions: function() {
            const organisationType = this.$store.getters.getOrganisationType;
            const oppositeType = organisationType === 'client' ? 'contractor' : 'client';
            const configuration = this.configuration;

            let options = [
                {
                    label: 'Norm',
                    value: 'usesCrowFlow',
                    key: 'info.usesCrowFlow',
                    type: 'custom',
                    id: 'norm',
                    align: 'left',
                    indicator: {
                        name: 'Norm',
                        answerType: 'boolean',
                        displayType: 'image'
                    }
                },
                {
                    label: this.$t('indicators.'+oppositeType),
                    value: oppositeType,
                    key: oppositeType,
                    type: 'indicator',
                    id: oppositeType,
                    align: 'left',
                    indicator: {
                        name: this.$t('indicators.'+oppositeType),
                        answerType: 'organisation',
                        displayType: 'organisation'
                    }
                },
                {
                    label: 'Aanmaakdatum',
                    value: 'creationDate',
                    key: 'info.creationDate',
                    type: 'custom',
                    id: 'creationDate',
                    align: 'left',
                    indicator: {
                        name: 'Aanmaakdatum',
                        answerType: 'date',
                        displayType: 'date'
                    }
                },
                {
                    label: 'Projectleden',
                    value: 'members',
                    key: 'info.members',
                    type: 'custom',
                    id: 'members',
                    align: 'left',
                    indicator: {
                        name: 'Projectleden',
                        answerType: 'user',
                        displayType: 'user'
                    }
                },
                {
                    label: 'Bijlage',
                    value: 'referenceLetterDocuments',
                    key: 'info.referenceLetterDocuments',
                    type: 'custom',
                    id: 'referenceLetterDocuments',
                    align: 'left',
                    indicator: {
                        name: 'Bijlage',
                        answerType: 'upload',
                        displayType: 'document'
                    }
                },
                {
                    label: 'Status',
                    value: `status`,
                    key: `info.${organisationType}Status`,
                    type: 'custom',
                    id: 'status',
                    align: 'right',
                    indicator: {
                        name: `Status`,
                        answerType: 'text',
                        displayType: 'text'
                    }
                }
            ];
            const canAddDocuments = this.$store.getters.hasQfactPD;
            if(!canAddDocuments) options = options.filter(option => option.id !== 'referenceLetterDocuments');

            return options.filter(option => !configuration.columns.find(column => column.key.toLowerCase().includes(option.value.toLowerCase())));
        }
    },
    watch: {
        show: function() {
            if(this.labelOptions.length === 0) this.getLabelOptions();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.content {
    .label {
        color: $color-grey-7;
        font-weight: 500;
    }

    .indicator-selector {
        gap: 10px;
    }

    --options-gap: 8px;

    .options-container {
        gap: var(--options-gap);
        isolation: isolate;
        user-select: none;

        .column-option {
            padding: 8px 14px;
            background: $color-grey-1;
            border-radius: 8px;
            // transition: scale 200ms cubic-bezier(.15,.75,.2,1);

            &:hover,
            &:focus-within {
                .right {
                    // scale: 1;
                    opacity: 1;
                    transition: 300ms cubic-bezier(.15,.75,.2,1) 150ms;
                }
            }

            &:hover {
                .hamburger-icon {
                    // scale: 1.2;
                }
            }

            &.sortable-chosen {
                // scale: 1.02;
            }

            .left {
                gap: 16px;
            }

            .right {
                gap: 8px;
                scale: .9;
                opacity: 0;
                transition: 250ms cubic-bezier(.15,.75,.2,1);

                .alignment-button {
                    
                }
            }
        }
    }
}

.dragged-column-option {
    // scale: 2;
}

.list-enter-active, .list-leave-active {
    transition: 400ms cubic-bezier(.15,.75,.2,1) !important;
    -webkit-transition: 400ms cubic-bezier(.15,.75,.2,1) !important;
    z-index: -1;
}
.list-enter, .list-leave-to {
    margin-top: calc((var(--options-gap) + 38px) * -1);
    opacity: .5;
    scale: .8;
}


</style>