<template>
    <router-link 
        :id="`timeline-point-${point.id}-${point.phaseId}`"
        :to="pointHref"
        class="show-focus-state"
        custom
        v-slot="{ href, navigate }"
    >
        <component
            ref="TimelinePoint" 
            class="timeline-point" 
            :class="[
                { 
                    first: pointsContext.index === 0,
                    last: pointsContext.index !== 0 && pointsContext.index === pointsContext.length-1,
                    selectable: pointHref !== '',
                    disabled: (point.isCreatePoint && enableDrag),
                    'timeline-point-draggable': enableDrag && canDragPoint
                }, 
                point.able && (!enableDrag || canDragPoint)
                    ? 'active'
                    : 'inactive',
            ]"
            data-point="point"

            :is="pointHref ? 'a' : 'div'"
            :tabIndex="pointHref ? 0 : -1"
            tag="div"
            :href="href"
            @click="navigate"
            @mouseenter="handleUpdateHover(true)"
            @mouseleave="handleUpdateHover(false)"
        >
            <div class="bullet">
                <!-- action bullet point (+/x) -->
                <jump-transition v-if="point.isCreatePoint || point.isBeingNamed || point.isDuplicating" group="bullets" jump="big">
                    <div class="action-point">
                        <q-icon
                            v-if="point.able"
                            :class="[
                                'add-form-circle',
                                { cancel: point.isBeingCreated || point.isBeingNamed || point.isDuplicating },
                                { disabled: point.disabled }
                            ]"
                            type="plusCircle"
                            @click.stop="handleClickActionPoint"
                        ></q-icon>
                        <q-tooltip v-else :disabled="point.reasons.length === 0" position="top">
                            <template v-slot:tooltip>
                                <p>{{ point.reasons.join(', ') }}</p>
                            </template>
                            <q-icon :class="['add-form-circle', 'disabled']" type="plusCircle"></q-icon>
                        </q-tooltip>
                    </div>
                </jump-transition>

                <!-- tooltip point -->
                <jump-transition v-else-if="point.tooltip" group="bullets" jump="big">
                    <div class="question-circle-point">
                        <q-tooltip position="top">
                            <template v-slot:tooltip>
                                <p v-html="point.tooltip"></p>
                            </template>
                            <q-icon type="question-circle" color="#AEB5BC"></q-icon>
                        </q-tooltip>
                    </div>
                </jump-transition>

                <!-- drag point -->
                <jump-transition v-else-if="canDragPoint" group="bullets" jump="big">
                    <div class="hamburger-icon-placeholder">
                        <q-icon-button
                            class="hamburger-icon"
                            icon="hamburger-menu"
                            iconColor="#ADB5BD"
                            iconWidth="18"
                            iconHeight="18"
                        ></q-icon-button>
                    </div>
                </jump-transition>

                <!-- default bullet point -->
                <jump-transition v-else group="bullets" jump="big">
                    <div class="point"></div>
                </jump-transition>
            </div>

            <div class="point-content" :class="{ hide: !showPoint }" :style="`--index: ${point.index}`">
                <component
                    ref="pointType"
                    :is="pointComponent"
                    :point="point"
                    :project="project"
                    :hover="hover"
                ></component>
            </div>
        </component>
    </router-link>
</template>

<script>
import { executeParentFunction } from '@/assets/js/utils';

import ReferenceLetterPoint from './points/ReferenceLetterPoint.vue';
import ComponentPoint from './points/ComponentPoint.vue';
import SurveyPoint from './points/SurveyPoint.vue';
import CrowFormPoint from './points/CrowFormPoint.vue';

export default {
    name: 'timeline-point',
    components: {
        ReferenceLetterPoint,
        ComponentPoint,
        SurveyPoint,
        CrowFormPoint
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        project: {
            type: Object,
            required: true
        },
        enableDrag: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            point: this.data,
            pointsContext: {
                length: null,
                index: null
            },
            pointComponent: null,
            showPoint: false,
            hover: false
        }
    },
    methods: {
        handleClickActionPoint() {
            if(this.point.isBeingCreated || this.point.isDuplicating) {
                this.$refs.pointType.handleCancelNewForm();
            } else if(this.point.isBeingNamed) {
                this.$refs.pointType.handleCancelChangeName(true);
            } else {
                this.$refs.pointType.handleStartNewForm();
            }
        },
        getPointComponent() {
            if(this.point.isFinish) return

            if(this.point.type === 'referenceletter') return 'reference-letter-point'
            if(this.point.type.includes('component')) return 'component-point'
            if(this.point.type === 'survey') return 'survey-point'
            if(this.point.type.includes('form')) return 'crow-form-point'
        },
        async startAnimation() {
            // trigger animation class so no animation forwards has to be used
            await new Promise((r) => setTimeout(r, 1));
            this.showPoint = true;
        },
        handleUpdateHover(value) {
            if(this.enableDrag) return this.hover = false;
            this.hover = value;
        }
    },
    computed: {
        canDragPoint: function() {
            return this.enableDrag && this.point.type === 'survey'
        },
        pointHref: function() {
            if(
                this.enableDrag || 
                this.point.disabled || 
                !this.point.able || 
                this.point.isCreatePoint ||
                this.point.id.includes('create') ||
                this.point.isBeingNamed
            ) return ''

            if(this.point.type === 'referenceletter') return `/projects/${this.project.id}/letter/${this.point.id}`
            if(this.point.type === 'form') return `/forms/${this.point.id}`
            if(this.point.type === 'survey') return `/enquetes/${this.point.id}`

            return ''
        }
    },
    watch: {
        data: {
            handler() {
                this.point = this.data;
            },
            deep: true
        },
        enableDrag: function() {
            if(this.point.isBeingCreated) this.$refs.pointType.handleCancelNewForm();
        }
    },
    created() {
        this.pointComponent = this.getPointComponent();
    },
    mounted() {
        const parentElement = this.$refs.TimelinePoint.parentElement;
        const children = [ ...parentElement.children ];
        const points = children.filter(node => node.dataset.point === "point");
        const index = points.findIndex(point => point.contains(this.$refs.TimelinePoint));
        this.pointsContext = {
            length: points.length,
            index
        }

        this.startAnimation();
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../components/qds/assets/style/_variables.scss';
@import '../../../../components/qds/assets/style/fonts/fonts.css';

.timeline-point {
    --background-color: #ffffff;
    --transition-duration: 200ms;

    position: relative;
    display: flex;
    align-items: center;
    padding-left: var(--white-space);
    padding-right: 12px;
    padding-block: var(--padding-block);
    min-height: var(--min-height);
    border-radius: 8px;
    background-color: var(--background-color);

    transition: var(--transition-duration) ease;
    -webkit-transition: var(--transition-duration) ease;

    &.disabled {
        pointer-events: none;
        // opacity: 0.7;
    }

    &.selectable {
        cursor: pointer;

        &:hover {
            --background-color: #F7F7F7;
        }
    }

    &.active {
        .point {
            background: $color-grey-5;
        }
    }
    &.inactive {
        .point-content {
            opacity: 0.8;
        }
    }

    .bullet {
        position: absolute;
        top: calc(var(--point-position-top) + (var(--point-diameter) / 2));
        left: calc(var(--white-space) / 2);
        transform: translate(-50%, -50%);
        transform-origin: top left;
        z-index: var(--point-z-index);
    }

    .action-point {
        display: grid;
        place-items: center;

        .add-form-circle {
            position: relative;
            background-color: var(--background-color);
            border-radius: 50%;
            cursor: pointer;
            transition: 300ms cubic-bezier(0.51, 1.8, 0.45, 1.08);
            -webkit-transition: 300ms cubic-bezier(0.51, 1.8, 0.45, 1.08);
            color: $color-grey-5;
            user-select: none;

            &:before {
                content: '+';
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                inset: 0;
                color: white;
                background-color: $color-red;
                border-radius: 50%;
                font-size: 14px;
                line-height: 20px;
                opacity: 0;
                transition: 200ms ease;
                -webkit-transition: 200ms ease;
                overflow: hidden;
            }

            &:hover {
                color: $color-blue-dark;
            }

            &.disabled {
                color: #d6dadd;
                pointer-events: none;
            }

            &.cancel {
                rotate: 45deg;
                scale: 1.2;
                color: white;

                &:before {
                    opacity: 1;
                    font-size: 22px;
                }
            }
        }
    }

    .question-circle-point {
        background-color: var(--background-color);
    }

    .hamburger-icon-placeholder {
        background-color: var(--background-color);
        z-index: var(--point-z-index);
        cursor: grab;
    }

    .point {
        width: var(--point-diameter);
        height: var(--point-diameter);
        background: $color-grey-3;
        border-radius: 50%;
        transition: 200ms ease;
    }

    .point-content {
        flex-grow: 1;
        --stagger-delay: 50ms;
        --animation-delay: calc(var(--stagger-delay) * var(--index));

        transition: 
            all 250ms ease-out calc(var(--stagger-delay) * var(--index)),
            transform 400ms cubic-bezier(.51,1.25,.45,1.08) var(--animation-delay);
        -webkit-transition: 
            all 250ms ease-out calc(var(--stagger-delay) * var(--index)),
            transform 400ms cubic-bezier(.51,1.25,.45,1.08) var(--animation-delay);

        &.hide {
            transform: translateX(-32px);
            opacity: 0;
        }

        .finish-point {
            p {
                font-weight: 500;
                color: $color-grey-7;
            }
        }
    }
}

.disabled {
    pointer-events: none;
}

.timeline-point-draggable {
    cursor: grab;

    .point-content {
        pointer-events: none !important;
    }
}

</style>