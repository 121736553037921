var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"show-focus-state",attrs:{"id":`timeline-point-${_vm.point.id}-${_vm.point.phaseId}`,"to":_vm.pointHref,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c(_vm.pointHref ? 'a' : 'div',{ref:"TimelinePoint",tag:"component",staticClass:"timeline-point",class:[
            { 
                first: _vm.pointsContext.index === 0,
                last: _vm.pointsContext.index !== 0 && _vm.pointsContext.index === _vm.pointsContext.length-1,
                selectable: _vm.pointHref !== '',
                disabled: (_vm.point.isCreatePoint && _vm.enableDrag),
                'timeline-point-draggable': _vm.enableDrag && _vm.canDragPoint
            }, 
            _vm.point.able && (!_vm.enableDrag || _vm.canDragPoint)
                ? 'active'
                : 'inactive',
        ],attrs:{"data-point":"point","tabIndex":_vm.pointHref ? 0 : -1,"tag":"div","href":href},on:{"click":navigate,"mouseenter":function($event){return _vm.handleUpdateHover(true)},"mouseleave":function($event){return _vm.handleUpdateHover(false)}}},[_c('div',{staticClass:"bullet"},[(_vm.point.isCreatePoint || _vm.point.isBeingNamed || _vm.point.isDuplicating)?_c('jump-transition',{attrs:{"group":"bullets","jump":"big"}},[_c('div',{staticClass:"action-point"},[(_vm.point.able)?_c('q-icon',{class:[
                            'add-form-circle',
                            { cancel: _vm.point.isBeingCreated || _vm.point.isBeingNamed || _vm.point.isDuplicating },
                            { disabled: _vm.point.disabled }
                        ],attrs:{"type":"plusCircle"},on:{"click":function($event){$event.stopPropagation();return _vm.handleClickActionPoint.apply(null, arguments)}}}):_c('q-tooltip',{attrs:{"disabled":_vm.point.reasons.length === 0,"position":"top"},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.point.reasons.join(', ')))])]},proxy:true}],null,true)},[_vm._v(" "),_c('q-icon',{class:['add-form-circle', 'disabled'],attrs:{"type":"plusCircle"}})],1)],1)]):(_vm.point.tooltip)?_c('jump-transition',{attrs:{"group":"bullets","jump":"big"}},[_c('div',{staticClass:"question-circle-point"},[_c('q-tooltip',{attrs:{"position":"top"},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('p',{domProps:{"innerHTML":_vm._s(_vm.point.tooltip)}})]},proxy:true}],null,true)},[_vm._v(" "),_c('q-icon',{attrs:{"type":"question-circle","color":"#AEB5BC"}})],1)],1)]):(_vm.canDragPoint)?_c('jump-transition',{attrs:{"group":"bullets","jump":"big"}},[_c('div',{staticClass:"hamburger-icon-placeholder"},[_c('q-icon-button',{staticClass:"hamburger-icon",attrs:{"icon":"hamburger-menu","iconColor":"#ADB5BD","iconWidth":"18","iconHeight":"18"}})],1)]):_c('jump-transition',{attrs:{"group":"bullets","jump":"big"}},[_c('div',{staticClass:"point"})])],1),_vm._v(" "),_c('div',{staticClass:"point-content",class:{ hide: !_vm.showPoint },style:(`--index: ${_vm.point.index}`)},[_c(_vm.pointComponent,{ref:"pointType",tag:"component",attrs:{"point":_vm.point,"project":_vm.project,"hover":_vm.hover}})],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }