<template>
    <Transition
        name="collapse"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
        @after-leave="afterLeave"
    >
        <slot></slot>
    </Transition>
</template>

<script>
export default {
    name: 'collapse-transition',
    data() {
        return {
        }
    },
    methods: {
        beforeEnter(element) {
            requestAnimationFrame(() => {
                if (!element.style.height) {
                    element.style.height = '0px';
                }

                element.style.display = null;
            });
        },
        enter(element) {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    element.style.height = `${element.scrollHeight}px`;
                });
            });
        },
        afterEnter(element) {
            element.style.height = null;
        },
        beforeLeave(element) {
            requestAnimationFrame(() => {
                if (!element.style.height) {
                    element.style.height = `${element.offsetHeight}px`;
                }
            });
        },
        leave(element) {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    element.style.height = '0px';
                });
            });
        },
        afterLeave(element) {
            element.style.height = null;
        }
    }
}
</script>

<style lang="scss" scoped>

.collapse-enter-active,
.collapse-leave-active {
    overflow: hidden;
    transition: height 500ms cubic-bezier(.8,0,.2,1);
}
    
</style>