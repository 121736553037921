<template>
    <div class="detail-page-padding" style="max-width: 900px">
        <div class="space-between">
            <div class="settings-back">
                <arrow-button color="#aeb5bc" direction="left" @click="$router.push(`/projects/${$route.params.id}`)">Naar project</arrow-button>
            </div>
        </div>
        <div class="mb-l">
            <div class="settings-intro">
                <div class="space-between vertical-center mb-m">
                    <h1>Beheer en toegang</h1>
                </div>
                <p>
                    Bekijk en beheer hier de projectleden en de prestaties in je project. Mocht je een formulier of het project willen verwijderen 
                    dan kun je dat ook hier doen. 
                </p>
                
            </div>
        </div>

        <div class="settings">
            <div class="panel q-card-small pb-xs">
                <h3>Projectleden beheren</h3>
                <p>
                    Hier kun je andere personen toegang geven tot het project. Je kan collega's en contactpersonen van
                    de opdrachtnemer met en zonder account uitnodigen en bepalen.
                </p>
                <project-user-settings :project="project" :projectJwt="projectJwt"></project-user-settings>
            </div>

            <label-settings v-if="isContractor && hasQfactPD"></label-settings>

            <project-change-name 
                :project="project" 
                :loading="projectLoading"
                @changeName="handleChangeName">
            </project-change-name>

            <div class="panel q-card-small pb-xs" v-if="projectAbilityIsSet" v-project-can="'admin__Form__delete'">
                <h3>Formulieren verwijderen</h3>
                <p>
                    Per ongeluk een formulier te veel aangemaakt? Hieronder kan je deze verwijderen. <br />
                    Alleen formulieren waarbij nog niet gestart is met invullen (concept) kunnen worden verwijderd.
                </p>

                <div class="items" v-if="formsLoading">
                    <div class="row" v-for="n in 2" :key="n">
                        <span class="name blank-row skeleton-line"></span>
                        <span class="content blank-row skeleton-line"></span>
                        <span class="action"> </span>
                    </div>
                </div>
                <div class="items" v-else-if="phasesWithForms.length">
                    <div class="row" v-for="phase in phasesWithForms" :key="phase.id">
                        <div class="phase">
                            <span v-if="phase.name" class="title">{{ phase.name }}</span>
                            <div v-for="form in phase.forms" :key="form.id" class="form">
                                <span class="name">{{ form.name }}</span>
                                <span class="content">{{ getDeleteFormContent(form, phase) }}</span>
                                <span class="action">
                                    <q-button
                                        class="edit"
                                        variation="danger"
                                        size="xsmall"
                                        :disabled="!canDeleteForm(form, phase).status"
                                        @click="deleteFormModal = form"
                                        :loading="loadingDeleteForm"
                                        >Verwijderen</q-button
                                    >
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="items" v-else>
                    <div class="row">
                        <span class="content">Momenteel geen formulieren aanwezig in dit project</span>
                    </div>
                </div>
            </div>

            <div class="panel q-card-small" v-if="projectAbilityIsSet && isInitiator" v-project-can="'admin__Project__delete'">
                <h3 class="delete-color">Project verwijderen</h3>
                <p>
                    Let op! Deze actie zal het project <strong>{{ title }}</strong> definitief verwijderen.
                    <br />
                    Projectgegevens zullen niet meer inzichtelijk zijn voor zowel de opdrachtgever als de opdrachtnemer.
                    <span v-if="project.status === 'finished'"><br/><br/>Afgeronde projecten kunnen niet verwijderd worden.</span>
                </p>

                <div class="delete mt-m">
                    <q-button
                        @click="deleteProjectModal = true"
                        size="small"
                        variation="danger"
                        :disabled="project.status === 'finished'"
                    >Project verwijderen</q-button>
                </div>
            </div>
        </div>

        <form-delete-modal
            v-if="deleteFormModal"
            :deleteFormModal="deleteFormModal"
            @close="deleteFormModal = null"
            @form="deleteForm(deleteFormModal)"
            :project="project"
        ></form-delete-modal>
        <project-delete-modal
            :deleteProjectModal="deleteProjectModal"
            @close="deleteProjectModal = null"
            @project="deleteProject"
            :project="project"
        ></project-delete-modal>
    </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton';
import FormDeleteModal from './FormDeleteModal.vue';
import ProjectDeleteModal from './ProjectDeleteModal.vue';
import ProjectUserSettings from './ProjectUserSettings.vue';
import LabelSettings from './LabelSettings.vue';
import ProjectChangeName from './ProjectChangeName.vue';

import { DELETE_PROJECT, DELETE_FORM, UPDATE_PROJECT } from '../../../../graphql/mutations';
import { ACCESS_PROJECT, GET_PROJECT, GET_PROJECT_FORMS } from '../../../../graphql/queries';

import { formStatus } from '../../form/utils';
import { extractError, isAdminRole } from '@/assets/js/utils';
import gql from 'graphql-tag';

export default {
    components: {
        ArrowButton,
        FormDeleteModal,
        ProjectDeleteModal, 
        ProjectUserSettings, 
        LabelSettings,
        ProjectChangeName
    },
    data() {
        return {
            loadingDeleteForm: false,
            project: {},
            editing: '',
            editUser: null,
            searching: false,
            search: '',
            forms: [],
            phasesWithForms: [],
            deleteFormModal: null,
            deleteProjectModal: false,
            members: [],
            membersLoading: true,
            projectLoading: false,
            formsLoading: true,
            projectAbilityIsSet: false,
            projectJwt: undefined
        };
    },
    methods: {
        getDeleteFormContent(form, phase) {
            const responsesLength = form.responses?.length || 0;
            const canDelete = this.canDeleteForm(form, phase);
            if(canDelete.reason) return canDelete.reason

            let message = '';
            if (['bp-intermediate','bp-final'].includes(form.formType)) {
                if(form.formType === 'bp-final') message = 'Better Performance eindmeting kan niet worden verwijderd';
                else if(canDelete.status) message = 'Better Performance meting bevat nog geen antwoorden';
                else message = 'Better Performance meting bevat antwoorden';
            }
            else if (form.status === 'concept') message = 'Dit formulier is nog niet verstuurd'
            else if (form.status !== 'finished') message = 'Rond het formulier af voordat je deze kunt verwijderen';
            else message = `Dit formulier heeft ${responsesLength} respondent${responsesLength !== 1 ? 'en' : ''}`
            
            return message
        },
        back() {
            this.$router.push('/projects/' + this.$route.params.id);
        },
        setForms(forms) {
            const allForms = forms.filter(form => form.formType !== 'references');
            const phases = this.project.timeline.phases || [];
            const relevantPhases = phases.filter(phase => phase.id !== 'reference-letter');

            this.phasesWithForms = relevantPhases.map(phase => {
                const forms = allForms.filter(form => phase.pointIds.includes(form.id));
                phase.forms = forms;
                return phase
            })
            .filter(phase => phase.forms.length > 0);
        },
        getProjectForms(fetchPolicy = 'no-cache') {
            const id = this.$route.params.id;
            this.formsLoading = true;

            this.$apollo
                .query({
                    query: GET_PROJECT_FORMS,
                    variables: {
                        id,
                        projectJwt: this.projectJwt
                    },
                    fetchPolicy,
                })
                .then((result) => {
                    this.formsLoading = false;
                    this.setForms(result.data.project.forms);
                })
                .catch((err) => {
                    this.formsLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        getProject() {
            const id = this.$route.params.id;
            this.projectLoading = true;

            this.$apollo
                .query({
                    query: ACCESS_PROJECT,
                    variables: {
                        id,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((result) => {
                    this.project = result.data.project_access.project;
                    this.projectJwt = result.data.project_access.jwt;

                    this.updateMembers();
                    this.updateUserRoles();
                    this.getProjectForms();

                    this.projectLoading = false;
                })
                .catch((err) => {
                    this.$store.commit('notify', extractError(err));
                });
        },
        deleteProject() {
            const id = this.$route.params.id;

            this.$apollo
                .mutate({
                    mutation: DELETE_PROJECT,
                    variables: {
                        id,
                    },
                })
                .then((result) => {
                    this.$router.push('/projects');
                    this.$store.commit('notify', {
                        message: `Project ${this.project.name} is verwijderd`,
                        type: 'success',
                    });
                })
                .catch((err) => {
                    this.$store.commit('notify', {
                        message: `Er is iets mis gegaan bij het verwijderen van het project ${this.project.naame}`,
                        type: 'warning',
                    });
                });
        },
        deleteForm(form) {
            this.loadingDeleteForm = true;
            const id = form.id;

            this.$apollo
                .mutate({
                    mutation: DELETE_FORM,
                    variables: {
                        id,
                    },
                })
                .then(async (result) => {
                    this.loadingDeleteForm = false;

                    this.$store.commit('notify', {
                        message: 'Het formulier is succesvol verwijderd',
                        type: 'success',
                    });
                    this.getProjectForms('no-cache');
                })
                .catch((err) => {
                    this.loadingDeleteForm = false;

                    this.$store.commit('notify', {
                        message: 'Er is iets mis gegaan bij het verwijderen van het formulier',
                        type: 'warning',
                    });
                });
        },
        updateMembers() {
            let items = [];

            if (this.project.contractor) {
                items.push({
                    user: {
                        avatar: this.project.contractor.logo,
                        name: 'Alle beheerders',
                    },
                    organisationId: this.project.contractor.id,
                    organisation: {
                        avatar: this.project.contractor.logo,
                        name: this.project.contractor.name,
                    },
                    roles: ['admin'],
                    organisationAdmins: true,
                });
            }

            if (this.project.client) {
                items.push({
                    user: {
                        avatar: this.project.client.logo,
                        name: 'Alle beheerders',
                    },
                    organisationId: this.project.client.id,
                    organisation: {
                        avatar: this.project.client.logo,
                        name: this.project.client.name,
                    },
                    roles: ['admin'],
                    organisationAdmins: true,
                });
            }

            if (this.project.members)
                this.project.members.forEach((member) => {
                    let organisation = member.organisation || {
                        name: 'Onbekend',
                        avatar: undefined,
                    };
                    if (member.status !== 'invited' && member.status !== 'remote_added') {
                        items.push({
                            user: {
                                name:
                                    (member.user.firstName ? member.user.firstName : '') +
                                    ' ' +
                                    (member.user.lastName ? member.user.lastName : ''),
                                firstName: member.user.firstName,
                                lastName: member.user.lastName,
                                email: member.user.email,
                                avatar: member.user.avatar,
                                id: member.user.id,
                            },
                            organisation: {
                                id: organisation.id,
                                name: organisation.name,
                                avatar: organisation.logo,
                            },
                            organisationId: organisation.id,
                            roles: member.roles,
                        });
                    } else {
                        items.push({
                            user: {
                                name: member.email,
                                avatar: null,
                            },
                            organisation: {
                                id: organisation.id,
                                name: organisation.name,
                                avatar: organisation.logo,
                            },
                            organisationId: organisation.id,
                            roles: ['Uitgenodigd'],
                        });
                    }
                });

            this.membersLoading = false;
            this.members = items;
        },
        updateUserRoles() {
            const members = this.project.members || [];
            const user = this.$store.getters.getUser;
            const organisation = this.$store.getters.getCurrentOrganisation;
            const member = members.find(
                (member) => member.userId === user.id && member.organisationId === organisation.id
            );
            const isGeustOrganisation =
                organisation.id !== this.project.client?.id && organisation.id !== this.project.contractor?.id;

            this.projectAbility.set(this.project.id, member, this.ability, isGeustOrganisation);
            this.projectAbilityIsSet = true;
        },
        handleChangeName(projectName) {
            const isInitiator = this.project.organisationId === this.$store.getters.getCurrentOrganisation.id;

            const variables = {
                id: this.project.id
            }

            const key = `${this.organisationType}ProjectName`;
            variables[key] = projectName;

            if(isInitiator) variables['name'] = projectName;

            this.$apollo.mutate({
                mutation: UPDATE_PROJECT,
                variables
            })
            .then(result => {
                this.$store.commit('notify', { type: 'success', message: 'Projectnaam is succesvol aangepast' });
                this.project.name = result.data.project_update.name;
                this.project.contractorProjectName = result.data.project_update.contractorProjectName;
                this.project.clientProjectName = result.data.project_update.clientProjectName;
                this.$router.push(`/projects/${this.project.id}?title=${projectName}`);
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het aanpassen van de projectnaam' });
            })
        },
        canDeleteForm(form, phase) {
            const canDelete = {
                status: false,
                reason: null
            }
            
            if(!isAdminRole(this.roleId) && !this.projectAbility.can('admin__Form__delete')) {
                canDelete.reason = 'Alleen een (project)beheerder mag formulieren verwijderen';
                return canDelete
            }
            const statusKey = `${this.organisationType}Status`;
            if(this.project[statusKey] === 'finished') {
                canDelete.reason = 'Het project is afgerond, er kan niks meer gewijzigd worden';
                return canDelete
            }

            const isBpForm = ['client','contractor'].includes(form.type);
            if(!isBpForm) {
                canDelete.status = ['concept','finished'].includes(form.status);
                return canDelete
            }

            if(!this.isInitiator) {
                canDelete.reason = 'Alleen de initiator mag formulieren verwijderen';
                return canDelete
            }

            const intermediateMeasurementFormIds = phase.forms.filter(phaseForm => phaseForm.formType === 'bp-intermediate').map(f => f.id);
            const intermediateMeasurementIndex = intermediateMeasurementFormIds.indexOf(form.id);
            if(intermediateMeasurementIndex === 0) canDelete.reason = 'Eerste tussentijdse meting kan alleen worden overgeslagen';

            const isEmptyForm = form.status === 'concept' && !form.responseIds?.length;
            const companionIsEmpty = form.companions[0]?.status === 'concept' && !form.companions[0]?.responseIds?.length;
            canDelete.status = isEmptyForm && companionIsEmpty && form.formType !== 'bp-final' && !(form.formType === 'bp-intermediate' && intermediateMeasurementIndex === 0);

            return canDelete
        }
    },
    computed: {
        title() {
            return this.$route.query.title || this.project.title || null
        },
        isInitiator() {
            return this.$store.getters.getCurrentOrganisation.id === this.project.organisationId
        },
        organisationType() {
            return this.$store.getters.getOrganisationType
        },
        isContractor() {
            return this.$store.getters.getOrganisationType === 'contractor'
        },
        hasQfactPD: function() {
            return this.$store?.getters.hasQfactPD
        },
        roleId() {
            const organisation = this.$store.getters.getCurrentOrganisation;
            const user = this.$store.getters.getUser;
            const organisationUser = organisation.users.find((organisationUser) => organisationUser.userId === user.id);
            return organisationUser.roleId
        }
    },
    watch: {
        project() {
            const result = [];
            const client = this.project.client ? result.push('client') : null;
            const contractor = this.project.contractor ? result.push('contractor') : null;
            this.projectOrganisations = result;
        },
    },
    created() {
        this.getProject();
    },
};
</script>

<style lang="scss" scoped>
@import '../../../../components/qds/assets/style/_variables.scss';
@import '../../../../components/qds/assets/style/fonts/fonts.css';

$base-color: #eef0f1;
$shine-color: #e3e7e9;
$animation-duration: 2.6s;

@mixin background-gradient {
    background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
    background-size: 600px;
}

@keyframes shine-lines {
    0% {
        background-position: -150px;
    }

    100% {
        background-position: 350px;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.confirm-delete {
    background-color: $color-grey-3;
    padding: 3px 6px;
    border-radius: 4px;
    // font-weight: 500;
}

.bold {
    font-weight: 700;
}

.delete {
    padding: 0 0 40px 40px;
}

.delete-color {
    color: $color-red;
}

.items {
    margin-top: 10px;

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 12px 40px;
        animation: fade-in 280ms forwards ease-in;

        .phase {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;

            .form {
                display: flex;
                align-items: center;
                justify-content: space-between;

                background: $color-grey-1;
                padding: 8px 12px;
                border-radius: $radius-default;
            }
        }

        .title {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            width: 200px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            margin-bottom: 8px;
        }

        .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            width: 200px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .content {
            font-size: 13px;
            line-height: 22px;
            width: 440px;
        }

        .name.blank-row,
        .content.blank-row,
        .action.blank-row {
            background-color: lighten($color-grey-3, 5%);
            border-radius: 4px;
            // width: 30%;
            height: 20px;
            margin: 10px 0;

            @include background-gradient;
            animation: shine-lines $animation-duration infinite ease-out;
            .skeleton-line ~ .skeleton-line {
                background-color: #ddd;
            }
        }
    }
}

span {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

.role {
    font-weight: 700;
}

.divider {
    margin-left: -3px;
}

.settings-back {
    margin-left: -30px;
}

.settings {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 32px;

    .panel {
        max-width: 900px;
        width: 100%;

        h3 {
            padding: 32px 40px 0 40px;
        }

        p {
            padding: 20px 40px 0 40px;
        }

        &.shared {
            h3 {
                padding: 40px 0 0 40px;
            }
        }
    }
}

.head {
    // margin-bottom: 48px;
    display: flex;
    justify-content: space-between;

    .left {
        display: flex;
        align-items: center;

        .status {
            margin-left: 10px;
        }

        h3 {
            font-weight: 500;
            font-size: 23px;
            line-height: 23px;
        }
    }
}

* {
    font-family: Gotham;
}
</style>
