<template>
    <q-step
        :number="index"
        :id="index"
        :name="step.name"
        :about="step.about"
        :value="step.value"
        class="step"
        width="90%"
    >
        <div class="body">
            <div class="table-colleague">
                <div class="button-colleague">
                    <q-tooltip :disabled="$store.getters.getCurrentOrganisation.users.length !== users.length">
                        <template #tooltip>U heeft geen collega's meer</template>
                        <q-button
                            size="small"
                            @click="addColleague = true"
                            :disabled="$store.getters.getCurrentOrganisation.users.length == users.length"
                            >Collega toegang geven</q-button
                        >
                    </q-tooltip>
                </div>

                <q-popup v-if="addColleague" showCloseButton @close="closeColleaguePopup()">
                    <colleague-access
                        :usersToAdd="users"
                        @addUserToTable="userAddToTable"
                        @close="closeColleaguePopup()"
                    ></colleague-access>
                </q-popup>

                <q-table :data="users" :columns="userColumns">
                    <template v-slot:row="{ row, column }">
                        <span v-if="column == 'avatar'" class="vertical-center avatar-row padding-left">
                            <q-avatar size="small" :img="row.avatar">{{
                                initials(row.firstName, row.lastName)
                            }}</q-avatar>
                        </span>
                        <span v-else-if="column == 'user'">
                            <span>{{ row.firstName }} {{ row.lastName }}</span>
                        </span>

                        <span v-else-if="column == 'role'">
                            <span>{{ getRoleLabel(row.organisationRole) }}</span>
                        </span>
                        <!-- <span v-else-if="column == 'filters'" class="filter">
                            <div class="dropdown">
                                <q-dropdown
                                    :options="filterOptions"
                                    style="width: 130px"
                                    size="small"
                                    noBorder
                                    v-model="row.selectedFilterRole"
                                >
                                </q-dropdown>
                            </div>
                        </span> -->
                        <span v-else-if="column === 'access'">
                            <q-dropdown
                                :options="accessOptions"
                                size="small"
                                noBorder
                                v-model="row.accessRole"
                                @input="handleChangeAccessRole(row)"
                                :disabled="users.length === 1"
                                autocomplete
                            >
                            </q-dropdown>
                        </span>
                        <span v-else-if="column == 'actions'" class="padding-right">
                            <q-tooltip :disabled="users.length > 1">
                                <template v-slot:tooltip>
                                    <p>Een rapportage moet tenminste één rapportage eigenaar hebben</p>
                                </template>
                                <q-icon  class="remove-icon" type="close" @click="handleRemoveSelectionAccess(row)" :class="{ disabled: users.length === 1}"></q-icon>
                            </q-tooltip>
                        </span>
                        <div v-else>
                            <span>{{ row[column] }}</span>
                        </div>
                    </template>
                </q-table>

                <q-popup v-if="showDeleteModalAccess" :showCloseButton="false" @close="closeDeleteModalAccessPopup()">
                    <div class="modal">
                        <h4 class="pb-s">Weet je zeker dat je deze collega van de rapportage wilt verwijderen?</h4>
                        <p>
                            Als je
                            <strong>{{ removedUserAccess.firstName + ' ' + removedUserAccess.lastName }}</strong>
                            verwijderd is deze rapportage niet meer inzichtelijk voor deze gebruiker
                        </p>

                        <div class="footer flex-end">
                            <q-button
                                variation="blank"
                                @click="closeDeleteModalAccessPopup()"
                                style="margin-right: 24px"
                                >Terug</q-button
                            >

                            <q-button variation="danger" @click="userRemoveFromTable()">Verwijderen</q-button>
                        </div>
                    </div>
                </q-popup>
            </div>
        </div>
    </q-step>
</template>

<script>
import { extractError, getRoleNameFromCurrentOrganisation } from '../../../../assets/js/utils';
import _ from 'lodash';
import { userInitials } from '@/assets/js/utils';
import ColleagueAccess from '../ColleagueAccess.vue';
import RoleAccess from '../RoleAccess.vue';
import {
    REPORT_ADD_USER,
    REPORT_REMOVE_USER,
    REPORT_UPDATE_USER,
    REPORT_ADD_ROLE,
    REPORT_REMOVE_ROLE,
    REPORT_UPDATE_ROLE,
} from '@/graphql/mutations';

export default {
    name: 'report-step-access',
    components: {
        ColleagueAccess,
        RoleAccess,
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        step: {
            type: Object,
            required: true,
        },
        report: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            removedUserAccess: {},
            filterOptionSelected: null,
            accessOptionSelected: null,
            addColleague: false,
            addRole: false,
            usersToAdd: [],
            selectedAccess: null,
            showDeleteModalAccess: false,
            showDeleteModalRole: false,
            actionOptions: [
                {
                    name: 'Verwijderen',
                    icon: 'delete',
                    color: 'red',
                },
            ],
            filterOptions: [
                {
                    label: 'Alle filters',
                    value: 'allFilters',
                },
                {
                    label: 'Geen filters',
                    value: 'noFilters',
                },
            ],
            accessOptions: [
                {
                    label: 'Rapportage eigenaar',
                    value: 'admin',
                },
                {
                    label: 'Mag inzien en bewerken',
                    value: 'update',
                },
                {
                    label: 'Mag alleen inzien',
                    value: 'view',
                },
            ],
            userColumns: [
                {
                    field: 'avatar',
                    label: '',
                    width: '32px',
                },
                {
                    field: 'user',
                    label: 'Gebruiker',
                    width: '100px',
                },
                {
                    field: 'role',
                    label: 'Rol',
                    width: '80px',
                },
                // {
                //     field: 'filters',
                //     label: 'Filters',
                //     width: '100px',
                //     overflow: true,
                // },
                {
                    field: 'access',
                    label: 'Toegang',
                    width: '200px',
                    tooltip: true,
                },
                {
                    field: 'actions',
                    label: '',
                    width: '100px',
                    align: 'end',
                },
            ],
            roleColumns: [
                {
                    field: 'role',
                    label: 'Rol',
                    width: '80px',
                },
                // {
                //     field: 'filters',
                //     label: 'Filters',
                //     width: '100px',
                //     overflow: true,
                // },
                {
                    field: 'accessRole',
                    label: 'Toegang',
                    width: '100px',
                    overflow: true,
                },
                {
                    field: 'actions',
                    label: '',
                    width: '80px',
                    align: 'end',
                },
            ],
        };
    },
    methods: {
        getRoleLabel(roleId) {
            return getRoleNameFromCurrentOrganisation(roleId)
        },
        userAddToTable(user) {
            let organisationRole = user.organisations.find(
                (organisation) => organisation.organisationId === this.$store.getters.getCurrentOrganisation.id
            );
            const newUser = JSON.parse(JSON.stringify(user));
            newUser.organisationRole = organisationRole.roleId;
            newUser.accessRole = user.selectedAccessRole;
            this.addUser(newUser);
        },
        userRemoveFromTable() {
            this.$apollo
                .mutate({
                    mutation: REPORT_REMOVE_USER,
                    variables: {
                        id: this.report.id,
                        userId: this.removedUserAccess.id,
                    },
                })
                .then((result) => {
                    this.report.users = result.data.report_removeUser.users;
                    this.showDeleteModalAccess = false;
                    this.$store.commit('notify', { type: 'success', message: `${this.removedUserAccess.firstName} succesvol verwijderd van de rapportage` });
                    this.$emit('reportUpdated', this.report);
                })
                .catch((error) => {
                    this.$emit('loadingEnded');
                    this.$store.commit('notify', {
                        type: 'warning',
                        message: `Er is iets fout gegaan, we hebben ${this.removedUserAccess.firstName} niet kunnen verwijderen. Probeer dit later opnieuw.`,
                    });
                    this.showDeleteModalAccess = false;
                });
        },
        addUser(user) {
            this.$apollo
                .mutate({
                    mutation: REPORT_ADD_USER,
                    variables: {
                        id: this.report.id,
                        userId: user.id,
                        permissions: [user.accessRole],
                    },
                })
                .then((result) => {
                    this.report.users = result.data.report_addUser.users;
                    this.$store.commit('notify', { type: 'success', message: 'Gebruiker succesvol toegevoegd aan de rapportage' });
                    this.$emit('reportUpdated', this.report);
                })
                .catch((error) => {
                    console.log(error);
                    this.$store.commit('notify', { type: 'danger', message: 'Gebruiker niet toe kunnen voegen aan de rapportage' });
                    this.$emit('loadingEnded');
                });
        },
        handleChangeAccessRole(user) {
            this.updateUser(user);
        },
        updateUser(user) {
            this.$emit('loadingStarted');
            this.$apollo
                .mutate({
                    mutation: REPORT_UPDATE_USER,
                    variables: {
                        id: this.report.id,
                        userId: user.id,
                        permissions: [user.accessRole],
                    },
                })
                .then((result) => {
                    this.report.users = result.data.report_changeUser.users;
                    this.$emit('reportUpdated', this.report);
                })
                .catch((error) => {
                    this.$emit('loadingEnded');
                    console.log(error);
                });
        },
        handleSelectOptionRole() {
            //this.showDeleteModalRole = true;
        },
        closeDeleteModalAccessPopup() {
            this.showDeleteModalAccess = false;
        },
        handleRemoveSelectionAccess(user) {
            if(this.users.length === 1) return
            this.showDeleteModalAccess = true;
            this.removedUserAccess = { ...user };
        },
        handleRemoveRole(role) {
            this.$emit('loadingStarted');
            this.$apollo
                .mutate({
                    mutation: REPORT_REMOVE_ROLE,
                    variables: {
                        id: this.report.id,
                        role: role.role,
                    },
                })
                .then((result) => {
                    this.report.roles = result.data.report_removeRole.roles;
                    this.$emit('reportUpdated', this.report);
                })
                .catch((error) => {
                    this.$emit('loadingEnded');
                    console.log(error);
                });
        },
        handleUpdateRole(role) {
            this.$emit('loadingStarted');
            this.$apollo
                .mutate({
                    mutation: REPORT_UPDATE_ROLE,
                    variables: {
                        id: this.report.id,
                        role: role.role,
                        permissions: [role.accessRole],
                    },
                })
                .then((result) => {
                    this.report.roles = result.data.report_changeRole.roles;
                    this.$emit('reportUpdated', this.report);
                })
                .catch((error) => {
                    this.$emit('loadingEnded');
                    console.log(error);
                });
        },
        closeColleaguePopup() {
            this.addColleague = false;
        },
        closeRolePopup() {
            this.addRole = false;
        },

        initials(firstName, lastName) {
            return userInitials(firstName, lastName);
        },
        // getReportUsers(reportId) {
        //     this.$apollo.query({
        //         query: GET_REPORT_USERS,
        //         variables: {
        //             id: reportId
        //         }
        //     })
        //     .then(result => {
        //         this.usersToAdd = this.parseReportUsers(result.data.report.users || []);
        //     })
        //     .catch(error => console.log(error))
        // },
        parseReportUsers(reportUsers) {
            if (!reportUsers || reportUsers.length == 0) return [];
            return reportUsers.map((user) => {
                let organisationRole = user.user.organisations.find(
                    (organisation) => organisation.organisationId === this.$store.getters.getCurrentOrganisation.id
                );
                const parsedUser = {
                    ...user.user,
                    accessRole: user.permissions[0],
                    organisationRole: organisationRole.roleId,
                };
                return parsedUser;
            });
        },
        parseReportRoles(roles) {
            if (!roles || roles.length == 0) return [];
            return roles.map((role) => {
                return {
                    role: role.role,
                    accessRole: role.permissions[0],
                };
            });
        },
        handleRoleAdded(role) {
            this.$emit('loadingStarted');
            this.$apollo
                .mutate({
                    mutation: REPORT_ADD_ROLE,
                    variables: {
                        id: this.report.id,
                        role: role.role,
                        permissions: [role.accessRole],
                    },
                })
                .then((result) => {
                    this.report.roles = result.data.report_addRole.roles;
                    this.$emit('reportUpdated', this.report);
                })
                .catch((error) => {
                    this.$emit('loadingEnded');
                    console.log(error);
                });
        },
    },
    computed: {
        users: function () {
            return this.parseReportUsers(this.report.users || []);
        },
        roles: function () {
            return this.parseReportRoles(this.report.roles || []);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../../components/qds/assets/style/_variables.scss';
@import '../../../../components/qds/assets/style/fonts/fonts.css';

.padding-left {
    padding-left: 4px;
}
.padding-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 8px;
}

span {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

.row > .label > p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
}

.button-colleague {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.table-colleague {
    padding-top: 15px;
    margin-bottom: 50px;
    position: relative;
}

.modal {
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
    transition: ease-in;
    text-align: start;

    .footer {
        position: absolute;
        bottom: 0;
        width: calc(100% - 80px);
        margin: 0 -40px;
        padding: 20px 40px;
        border-top: 1px solid rgb(222, 226, 230);
        display: flex;
        justify-content: space-between;
    }
}

* {
    font-family: Gotham;
}


.remove-icon {
    color: red;
    cursor: pointer;
    scale: 0.8;
    padding-top: 8px;

    &.disabled {
        cursor: default;
        color: rgb(189, 185, 185);
    }
}
</style>
