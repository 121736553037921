<template>
    <div class="reference-letter-point">
        <div class="letter">
            <div class="left">
                <div class="tag">
                    <q-tag size="medium" :variation="letterStatus.variation">{{ $t(`formStatus.${letterStatus.status}`) }}</q-tag>
                </div>
                <p class="name">{{ referenceLetterPoint.form.name }}</p>
            </div>
            <div v-if="referenceLetterDocuments.length" class="right">
                <q-tooltip position="top">
                    <template #tooltip>
                        <div style="max-width: 30vw;" class="truncate-text" v-html="documentsTooltip"></div>
                    </template>
                    <q-tag size="small" variation="default">
                        <q-icon type="attachment" width="7" height="16"></q-icon>
                        <span>{{ referenceLetterDocuments.length }}</span>
                    </q-tag>
                </q-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import { GET_PROJECTS_BY_READMODEL_MINIMAL } from '@/graphql/queries'
import { isValidAnswer, executeParentFunction } from '@/assets/js/utils';

export default {
    name: 'reference-letter-point',
    props: {
        point: {
            type: Object,
            required: true
        },
        project: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            referenceLetterPoint: this.point,
            referenceLetterDocuments: []
        }
    },
    methods: {
        getProjectReadmodel() {
            this.$apollo.query({
                query: GET_PROJECTS_BY_READMODEL_MINIMAL,
                variables: {
                    where: {
                        projectId: this.project.id
                    }
                },
                fetchPolicy: 'no-cache'
            }).then(result => {
                const readmodel = result.data.projectReadmodels[0];
                this.referenceLetterDocuments = readmodel.info.referenceLetterDocuments || [];
            });
        }
    },
    computed: {
        letterStatus: function() {
            const status = this.project.status === 'finished' ? 'finished' : 'active';

            let variation = 'warning';
            if(Boolean(status === 'finished')) variation = 'success'

            return {
                status,
                variation
            }
        },
        canAnswerIndicator() {
            return executeParentFunction(this, 'project', 'canEditIndicator', this.referenceLetterPoint);
        },
        documentsTooltip: function() {
            return this.referenceLetterDocuments.map(document => document.originalFileName).join('<br>')
        }
    },
    watch: {
        point: {
            handler() {
                this.referenceLetterPoint = this.point;
            },
            deep: true
        }
    },
    created() {
        this.getProjectReadmodel();
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../../components/qds/assets/style/_variables.scss';
@import '../../../../../components/qds/assets/style/fonts/fonts.css';

.reference-letter-point {
    flex-grow: 1;

    .letter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;

        .left {
            display: flex;
            align-items: center;
            gap: 8px;

            .name {
                color: $color-grey-9;
            }
        }

        .right {
            .icon {
                transform: rotate(20deg);
            }
        }

    }
}

</style>