<template>
    <div ref="diagramWrapper" class="diagram-wrapper" :class="{ hover }" :style="`--size: ${size};`" @mouseenter="hover = true;" @mouseleave="hover = false;">
        <q-score-text v-if="showScore" class="score" :size="size/2.75" :score="score" color="#065D64"></q-score-text>
        <jump-transition>
            <div class="circles-wrapper">
                <svg class="placeholder-circle" :width="size + width" :height="size + width" :viewBox="`0 0 ${size + width + (size/8)} ${size + width + (size/8)}`">
                    <circle :cx="size/2 + width/2 + (size/16)" :cy="size/2 + width/2 + (size/16)" :r="size/2" :stroke-width="width" :stroke-dasharray="`${dashArray} ${dashArray}`" stroke-dashoffset="0" fill="none">
                        <animate
                            ref="placeholderAnimation"
                            class="animation-tag"
                            attributeName="stroke-dashoffset"
                            :from="lastScoreDashOffset === null ? dashArray*2 : lastScoreDashOffset + dashArray - spacing"
                            :to="scoreDashOffset + dashArray - spacing"
                            dur=".75s"
                            calcMode="spline"
                            keyTimes="0; 1"
                            keySplines=".8 .1 .1 1"
                            fill="freeze"
                            begin="indefinite"
                        /> 
                    </circle>
                </svg>
                <svg class="score-circle" :width="size + width" :height="size + width" :viewBox="`0 0 ${size + width + (size/8)} ${size + width + (size/8)}`" preserveAspectRatio="xMidYMin">
                    <circle 
                        :cx="size/2 + width/2 + (size/16)" 
                        :cy="size/2 + width/2 + (size/16)" 
                        :r="size/2"
                        :stroke="strokeColor"
                        :stroke-width="hover ? width + (size/32) : width" 
                        :stroke-dasharray="`${dashArray} ${dashArray}`" 
                        :stroke-dashoffset="dashArray" 
                        fill="none"
                    >
                        <animate
                            ref="scoreAnimation"
                            class="animation-tag"
                            attributeName="stroke-dashoffset"
                            :from="lastScoreDashOffset === null ? dashArray : lastScoreDashOffset"
                            :to="scoreDashOffset"
                            dur=".75s"
                            calcMode="spline"
                            keyTimes="0; 1"
                            keySplines=".8 .1 .1 1"
                            fill="freeze"
                            begin="indefinite"
                        /> 
                    </circle>
                </svg> 
            </div>
        </jump-transition>
    </div>
</template>

<script>
export default {
    name: 'q-circle-diagram',
    props: {
        size: {
            type: String|Number,
            default: 55
        },
        // width of the circle stroke in pixels
        width: {
            type: String|Number,
            default: 5
        },
        score: {
            type: Number,
            default: 0
        },
        // boolean to either show or hide the score in the center
        showScore: {
            type: Boolean,
            default: true
        },
        // stagger the animations of all the circles rendered on the current page
        stagger: {
            type: Boolean,
            default: false
        },
        // delay (ms) to be used when staggering the animations
        delay: {
            type: Number,
            default: 100
        },
        // color of the score part of the circle diagram
        strokeColor: {
            type: String,
            default: '#00a1ae'
        }
    },
    data() {
        return {
            index: 0,
            lastScoreDashOffset: null,
            hover: false
        }
    },
    methods: {
        async animateCircle() {
            if(this.stagger) await new Promise(r => setTimeout(r, this.delay * this.index));

            this.$refs.placeholderAnimation.beginElement();
            this.$refs.scoreAnimation.beginElement();
        }
    },
    computed: {
        mainScore: function() {
            if(this.score === 10) return 10
            const score = JSON.stringify(this.score);
            return score.substring(0,1);
        },
        decimalScore: function() {
            const score = JSON.stringify(this.score);
            const decimal = score.substring(1,score.length);
            if(!decimal.includes('.')) return '.0'
            return decimal.substring(0,2)
        },
        dashArray: function() {
            return 2 * (this.size/2) * Math.PI
        },
        scoreDashOffset: function() {
            return this.dashArray - this.dashArray / 10 * this.score
        },
        spacing: function() {
            if(this.score !== 0) return 4
            return 0
        }
    },
    async mounted() {
        const diagrams = [ ...document.querySelectorAll('.diagram-wrapper') ];
        this.index = diagrams.findIndex(diagram => diagram.contains(this.$refs.diagramWrapper));

        this.animateCircle();
    },
    watch: {
        score: function() {
            this.animateCircle();
        },
        scoreDashOffset: function(after, before) {
            if(this.lastScoreDashOffset === null) this.lastScoreDashOffset = after;

            this.lastScoreDashOffset = before;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.diagram-wrapper {
    position: relative;
    padding: 4px;
    margin: -4px;

    .score {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -51%);
    }
    .circles-wrapper {
        display: grid;
        place-items: center;
        transform: rotate(-90deg);

        .placeholder-circle {
            --rotate: calc(220 / var(--size));
            transform: rotate(calc(var(--rotate) * -1deg));

            circle {
                stroke: $color-grey-3;
            }
        }

        .score-circle {
            position: absolute;
            transition: .3s ease;
            -webkit-transition: .3s ease;
            will-change: transform;
            z-index: 1;

            circle {
                will-change: stroke-width;
                transition: stroke-width .3s ease;
                -webkit-transition: stroke-width .3s ease;
            }
        }
    }

    &.hover {
        .circles-wrapper {
            .score-circle {
                transform: scale(1.05);
                -webkit-transform: scale(1.05);
            }
        }
    }
}

// @media print {
    .animation-tag {
        all: unset;
        display: none;
    }
// }

</style>