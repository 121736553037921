<template>
    <nav class="navbar space-between">
        <q-button
            variation="blank"
            class="searchButton"
            @click="handleSearch"
            icon="search"
            size="large"
            iconSize="24"
            >Typ om te zoeken</q-button
        >
        <div class="vertical-center">
            <q-profile-info
                :firstName="user.firstName"
                :lastName="user.lastName"
                :role="user.email"
                :image="user.avatar"
                :notifications="notificationAmount"
                size="medium"
                variation="left"
                class="profileInfo"
                dropdown
            >
                <q-profile-dropdown
                    :firstName="user.firstName"
                    :lastName="user.lastName"
                    :role="user.email"
                    :image="user.avatar"
                    :organisations="user.organisations"
                    @logout="logout"
                    class="dropdown"
                >
                    <q-section hPadding="40" paddingTop="16" paddingBottom="16" borderBottom>
                        <q-profile-info
                            size="large"
                            variation="top"
                            :firstName="user.firstName"
                            :lastName="user.lastName"
                            :role="user.email"
                            :image="user.avatar"
                            class="dropdown-info"
                        >
                        </q-profile-info>
                    </q-section>

                    <user-organisations :organisations="organisations" xPadding="16"></user-organisations>
                    <div id="step1" style="overflow: hidden;">
                        <q-section
                            hPadding="40"
                            vPadding="15"
                            borderTop
                            class="add-organisation-row"
                            :class="{ disabled: user.status !== 'active' }"
                            style="width:100%"
                        >
                            <q-icon
                                @click="registerOrganisation"
                                type="plus-circle"
                                width="32"
                                height="32"
                                :color="iconColor"
                                class="icon"
                            />
                            <div @click="registerOrganisation">
                                <p class="main">Registreer organisatie</p>
                                <p class="sub">Nieuwe organisatie aanmelden</p>
                            </div>
                        </q-section>
                    </div>
                </q-profile-dropdown>
            </q-profile-info>
        </div>
    </nav>
</template>

<script>
import { ORG_MINE } from '@/graphql/queries';
import { EMVI_TOKEN } from '@/graphql/mutations';
import QProfileInfo from './QProfileInfo';
import QOrganizationInfo from './QOrganizationInfo';
import QProfileDropdown from './QProfileDropdown';
import UserOrganisations from '@/components/UserOrganisations';

export default {
    name: 'q-topbar',
    status: 'prototype',
    release: '0.0.1',
    components: {
        QProfileInfo,
        QOrganizationInfo,
        QProfileDropdown,
        UserOrganisations
    },
    props: ['user'],
    data() {
        return {
            activeOrganisations: [],
            newOrganisations: [],
            iconColor: '#22B8CF',
            notificationAmount: 0
        };
    },
    methods: {
        registerOrganisation() {
            this.$router.push('/access/register/organisation');
        },
        handleSearch() {
            this.$emit('search');
        },
        logout() {
            this.$store.commit('resetState');
            this.$router.push('/login');
        },
        async toEmvi() {
            const { hasQfactAccess} = this.$store.getters;
            const { user_emviToken } = (await this.$apollo.mutate({ mutation: EMVI_TOKEN })).data;
            const targetUrl = `${process.env.EMVI_APP_URL}/authentication/login?token=${user_emviToken}&qfact_access=${hasQfactAccess}`;
            window.location = targetUrl;
        }
    },
    computed: {
        organisations() {
            return [...this.activeOrganisations, ...this.newOrganisations];
        },
    },
    apollo: {
        userOrganisations: {
            query: ORG_MINE,
            fetchPolicy: 'no-cache',
            update: data => data.org_mine,
            result(res) {
                const vm = this;
                if (res.data.org_mine) {
                    const orgs = res.data.org_mine || [];
                    const activeOrgs = [];
                    const newOrgs = [];
                    orgs.forEach(org => {
                        const user = org.users.find(user => user.userId === vm.$store.getters.getUser.id);
                        if (org.status == 'active' && user.status === 'active') {
                            activeOrgs.push(org);
                        } else if (user.status === 'requested' || (user.status === 'active' && org.status === 'new')) {
                            newOrgs.push(org);
                        }
                        vm.activeOrganisations = activeOrgs;
                        vm.newOrganisations = newOrgs;
                    });
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../assets/style/_variables.scss';
@import '../../assets/style/fonts/fonts.css';

.add-organisation-row {
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-left: -24px;
}
.add-organisation-row > div {
    cursor: pointer;
}
.add-organisation-row > div > p.main {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}
.add-organisation-row > div > p.sub {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin-top: -3px;
}
.add-organisation-row > .icon {
    margin: 3px 12px 0 0;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}
//---
.searchButton {
    margin-left: 4px;
    font-weight: 400;

    // override default buttons styles
    font-size: 16px !important;
    line-height: 32px !important;
    padding-left: 56px !important;
}

.dropdown {
    text-align: center;
}

.organisation-list {
    max-height: 230px;
    overflow: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $color-white;
        border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $color-grey-3;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $color-grey-5;
    }
}

.settings {
    position: relative;
    padding: 0 0 10px 0;
    display: flex;
    justify-content: center;
}

.dropdown-info {
    padding: 10px 0;
}

.navbar {
    border-bottom: 1px solid $color-grey-3;
}

.profileInfo {
    margin-left: 40px;
}

.notifications-span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-orange;
    color: #ffffff;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 12px;
    font-family: 'Gotham';
    top: -4px;
    transform: translateX(330%);
}

</style>

<docs>
  ```jsx
  <div style="height: 1024px; border: 1px solid black; position:relative">
    <q-topbar > </q-topbar>
    
  </div>
  ```
</docs>
