<template>
    <div class="established-form">
        <div class="head">
            <h2>{{ form.name }}</h2>
            <img src="https://storage.googleapis.com/qfact_uploads_production/misc/crow-logo.png" alt="crow-image" />
        </div>

        <div :class="['organisation-names', isRelatedToProject && hasCompanion ? 'side' : 'center']">
            <div v-if="hasCompanion" class="left">
                <p>{{ myOrganisation.name }} beoordeelt {{ companionOrganisation.name }}</p>
                <p v-if="scores.companion">Cijfer: {{ scores.companion }}</p>
            </div>
            <div v-if="isRelatedToProject && hasCompanion" class="right">
                <p>
                    {{ companionOrganisation.name }} beoordeelt {{ myOrganisation.name }}
                </p>
                <p v-if="scores.mine">Cijfer: {{ scores.mine }}</p>
            </div>
        </div>
        
        <div class="form-actions" v-if="isRelatedToProject && showJudgement">
            <div 
                v-if="showCollaborationInputText" 
                class="collaboration-text-container content"
            >
                <div class="header">
                    <div class="line">
                        <p>Reactie op de samenwerking</p>
                    </div>
                </div>
                <div class="description">
                    <p>
                        Geef hier een reactie op de samenwerking met {{ form.project.client.name }}. Deze omschrijving
                        wordt gebruikt als je toelichting op de samenwerking met de opdrachtgever en wordt bij dit
                        project getoond in de landelijke database van CROW.
                    </p>
                </div>
                <q-text 
                    class="q-text" 
                    v-model="collaborationContractorInput" 
                    @blur="saveCollaborationContractor" 
                    placeholder="Waar werd jij blij van of wat zie je graag verbeterd worden?"
                ></q-text>
            </div>

            <div  
                v-if="
                    ['pending_crow_admin','crow_admin_disagreed','finished'].includes(crowJudgementStatus.status) && 
                    form.name === 'Eindmeting' &&
                    singleFormStatus.status !== 'single_response'
                " 
                class="crow-agreement-container content"
            >
                <div class="header">
                    <div class="line">
                        <q-tag size="small" :emoji="crowJudgementStatus.type"></q-tag>
                        <p v-if="crowJudgementStatus.status === 'finished'" >CROW Beheerder heeft akkoord gegeven</p>
                        <p v-else-if="crowJudgementStatus.status === 'crow_admin_disagreed'" >CROW Beheerder gaat niet akkoord</p>
                        <p v-else>Wachten op akkoord CROW Beheerder</p>
                    </div>
                </div>
                <div v-if="status === 'revision' && crowJudgementStatus.status === 'crow_admin_disagreed'" class="description">
                    <p>Jouw formulier staat nu in revisie, voer de gewenste aanpassingen <br>door en ga akkoord om dit formulier af te ronden</p>
                </div>
                <div v-if="['pending_crow_admin','crow_admin_disagreed'].includes(crowJudgementStatus.status)" class="action-buttons" style="margin-top: 20px;">
                    <q-tooltip :disabled="!canAdminAgree.reason">
                        <template #tooltip>
                            <span>{{ canAdminAgree.reason }}</span>
                        </template>
                        <q-button
                            v-if="isClient && crowJudgementStatus.status !== 'crow_admin_disagreed'"
                            variation="secondary"
                            @click="adminDisagree()"
                            :loading="disagreeButtonAdminLoading"
                            :disabled="!ability.get().can('disagree','Form') || !canAdminAgree.able"
                            >Niet akkoord (beheerder)</q-button
                        >
                    </q-tooltip>
                    <q-tooltip :disabled="!canAdminAgree.reason">
                        <template #tooltip>
                            <span>{{ canAdminAgree.reason }}</span>
                        </template>
                        <q-button
                            v-if="isClient"
                            variation="primary"
                            @click="adminAgree()"
                            :loading="agreeButtonAdminLoading"
                            :disabled="!ability.get().can('agree','Form') || !canAdminAgree.able"
                            >Akkoord (beheerder)</q-button
                        >
                    </q-tooltip>
                </div>
            </div>

            <div class="agreement-status-container">
                <div class="your-side" :class="[yourFormStatus.status, { 'no-line': singleFormStatus.status === 'single_response' }]">
                    <div class="green-line status-line"></div>
                    <div class="red-line status-line"></div>
                    <div class="orange-line status-line"></div>
                    <div class="grey-line status-line"></div>
                    <div class="content">
                        <div class="header">
                            <div class="line headline">
                                <p v-if="yourFormStatus.status === 'revision'">Revisie naar aanleiding van gesprek</p>
                                <p v-else>Akkoord naar aanleiding van {{ form.companions[0].hasBeenDisagreed ? 'revisie' : 'gesprek' }}</p>
                            </div>
                            <span class="line">
                                <q-tag size="small" :emoji="yourFormStatus.type"></q-tag>
                                <p v-if="yourFormStatus.status === 'judgement'">Wachten op akkoord van {{ companionName }}</p>
                                <p v-else-if="yourFormStatus.status === 'revision'">{{ companionName }} gaat niet akkoord</p>
                                <p v-else-if="singleFormStatus.status === 'single_response'">Enkelzijdig afgerond</p>
                                <p v-else>{{ companionName }} heeft akkoord gegeven</p>
                            </span>
                        </div>
                        <div class="description">
                            <p v-if="yourFormStatus.status === 'revision'">Vul de afgesproken aanpassingen per vraag <br>in en klik vervolgens op revisie gereed</p>
                        </div>
                        <div v-if="yourFormStatus.status === 'revision'" class="action-buttons">
                            <q-tooltip :disabled="!Boolean(invalidMessage)" position="bottom">
                                <template v-slot:tooltip>
                                    <p>{{ invalidMessage }}</p>
                                </template>
                                <q-button
                                    variation="primary"
                                    @click="agreeRevision()"
                                    :loading="rivisionButtonLoading"
                                    :disabled="!ability.get().can('agree','Form') || Boolean(invalidMessage)"
                                >Revisie gereed</q-button>
                            </q-tooltip>
                        </div>
                    </div>
                </div>

                <div v-if="singleFormStatus.status !== 'single_response'" class="agreement-center">
                    <div class="agreement-circle" :class="{ agreed: crowJudgementStatus.status === 'finished' }">
                        <q-icon type="shaking-hands" width="48" height="48"></q-icon>
                    </div>
                </div>

                <div v-if="singleFormStatus.status !== 'single_response'" class="companion-side" :class="companionFormStatus.status">
                    <div class="green-line status-line"></div>
                    <div class="orange-line status-line"></div>
                    <div class="grey-line status-line"></div>
                    <div class="content">
                        <div class="header">
                            <div class="line headline">
                                <p v-if="companionFormStatus.status === 'revision'">Revisie naar aanleiding van gesprek</p>
                                <p v-else>Akkoord naar aanleiding van {{ form.hasBeenDisagreed ? 'revisie' : 'gesprek' }}</p>
                            </div>
                            <div v-if="companionFormStatus.status !== 'judgement'" class="line">
                                <q-tag size="small" :emoji="companionFormStatus.type"></q-tag>
                                <p v-if="singleFormStatus.status === 'single_response'">Enkelzijdig afgerond</p>
                                <p v-else-if="companionFormStatus.status === 'agreed'">{{ myOrganisation.name }} heeft akkoord gegeven</p>
                                <p v-else-if="companionFormStatus.status === 'revision'">Wachten op revisie van {{ companionOrganisation.name }}</p>
                            </div>
                        </div>
                        <div class="description">
                            <p v-if="companionFormStatus.status === 'judgement'">Wil je naar aanleiding van het gesprek nog aanpassingen door laten voeren door de tegenpartij? Klik dan op niet akkoord</p>
                        </div>
                        <div v-if="companionFormStatus.status === 'judgement'" class="action-buttons">
                            <q-button
                                variation="secondary"
                                @click="disagree()"
                                :loading="disagreeButtonLoading"
                                :disabled="!ability.get().can('disagree','Form') || agreed"
                                >Niet akkoord</q-button
                            >
                            <q-tooltip :disabled="organisationType !== 'contractor' || collaborationContractorInput !== '' || form.name !== 'Eindmeting'">
                                <template #tooltip>
                                    <p>Vul eerst de reactie op de samenwerking in</p>
                                </template>
                                <q-button
                                    variation="primary"
                                    @click="agree()"
                                    :loading="agreeButtonLoading"
                                    :disabled="!ability.get().can('agree','Form') || !canAgree || disagreed"
                                >Akkoord</q-button>
                            </q-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="wrapper">
            <div
                v-for="(row, key) in getCrowFormRows"
                :key="key"
                :class="['row', isRelatedToProject && hasCompanion ? 'side' : 'center']"
            >
                <div class="left">
                    <question-overview
                        v-if="row.own"
                        :component="row.own"
                        :form="form"
                        :formState="status"
                        :useCrowComments="isCrowForm"
                        :projectJwt="projectJwt"
                        @componentUpdated="handleComponentUpdated"
                    ></question-overview>
                </div>
                <div v-if="status === 'waitingForCompanionToStart'" class="right zerostate-row">
                    <div v-if="key === 0" class="q-card-small card center">
                        <q-zerostate
                            class="zerostate"
                            img="/statics/img/survey_gradient.svg"
                            title="Moet nog beginnen met invullen"
                            :description="zerostateDescription"
                        ></q-zerostate>
                    </div>
                </div>
                <div v-else-if="status === 'waitingForCompanionFill'" class="right zerostate-row">
                    <div v-if="key === 0" class="q-card-small card center">
                        <q-zerostate
                            class="zerostate"
                            img="/statics/img/survey_gradient.svg"
                            title="Druk aan het invullen"
                            :description="zerostateDescription"
                        ></q-zerostate>
                    </div>
                </div>
                <div class="right" v-else-if="isRelatedToProject && hasCompanion">
                    <question-overview
                        v-if="row.other"
                        :component="row.other"
                        :form="form.companions[0]"
                        :useCrowComments="isCrowForm"
                    ></question-overview>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QuestionOverview from './QuestionOverview';

import {
    AGREE_FORM,
    DISAGREE_FORM,
    ADMIN_AGREE_FORM,
    ADMIN_DISAGREE_FORM,
    SAVE_VALUE_VALUE,
} from '../../../graphql/mutations';

import {
    GET_VALUES,
    GET_INDICATORS_MINIMAL
} from '../../../graphql/queries';

import { extractError, isDefined } from '@/assets/js/utils';
import jwtDecode from 'jwt-decode';

export default {
    name: 'EstablishedForm',
    props: {
        /**
         * Form contains companion form
         */
        form: {
            type: Object,
        },
        project: {
            type: Object,
        },
        /**
         * Current status of the form, as calculated by the parent.
         */
        status: String,
        isCrowForm: {
            type: Boolean,
            default: () => false,
        },
        projectJwt: {
            type: String
        },
        incompleteAnswers: {
            type: Array,
            default() { return [] }
        },
        invalidMessage: {
            type: String,
            default: null
        }
    },
    components: {
        'question-overview': QuestionOverview,
    },
    data() {
        return {
            organisationType: this.$store.getters.getOrganisationType,
            collaborationContractorInput: '',
            agreeButtonLoading: false,
            agreeButtonAdminLoading: false,
            disagreeButtonLoading: false,
            disagreeButtonAdminLoading: false,
            rivisionButtonLoading: false,
            isRelatedToProject: false,
            agreed: false,
            disagreed: false,
        };
    },
    methods: {
        saveCollaborationContractor() {
            const phases = this.project.timeline.phases || [];
            const formPhase = phases.find(phase => phase.pointIds.includes(this.form.id));
            const phaseKey = formPhase.id !== 'crow' ? `-${formPhase.id}` : '';
            const value = this.collaborationContractorInput;
            
            const componentId = `component-collaboration-contractor${phaseKey}`;
            const masterId = `collaboration-contractor-${phaseKey}`;
            const { references } = this.form.project;
            const formId = references.id;
            const responseId = references.responses[0].id;
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            const component = this.project.references.components.find(component => component.id === componentId);
            const indicatorId = component.indicator.id;
            
            this.$apollo.mutate({
                mutation: SAVE_VALUE_VALUE,
                variables: {
                    componentId,
                    responseId,
                    formId,
                    indicatorId,
                    value,
                    organisationId,
                    projectJwt: this.projectJwt
                },
                fetchPolicy: 'no-cache',
            })
            .catch((error) => {
                this.$store.commit('notify', extractError(error));
            });
        },
        agree() {
            this.agreed = true;
            this.agreeButtonLoading = true;
            const id = this.$route.params.id;

            this.$apollo
                .mutate({
                    mutation: AGREE_FORM,
                    variables: { id },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                })
                .then((response) => {
                    if (response.data.form_agree) {
                        this.$store.commit('notify', {
                            type: 'success',
                            message: 'Akkoord gegeven',
                        });

                        this.$emit('reload');
                    }
                })
                .catch((err) => {
                    this.agreed = false;
                    this.agreeButtonLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        disagree() {
            this.disagreed = true;
            this.disagreeButtonLoading = true;
            const id = this.$route.params.id;

            this.$apollo
                .mutate({
                    mutation: DISAGREE_FORM,
                    variables: { id },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                })
                .then((response) => {
                    if (response.data.form_disagree) {
                        this.$store.commit('notify', {
                            type: 'success',
                            message: 'Geen akkoord gegeven',
                        });

                        this.$emit('reload');
                    }
                })
                .catch((err) => {
                    this.disagreed = false;
                    this.disagreeButtonLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        agreeRevision() {
            this.rivisionButtonLoading = true;
            const id = this.form.companions[0].id;

            this.$apollo
                .mutate({
                    mutation: AGREE_FORM,
                    variables: { id },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                })
                .then((response) => {
                    if (response.data.form_agree) {
                        this.$store.commit('notify', {
                            type: 'success',
                            message: 'Revisie akkoord',
                        });

                        this.rivisionButtonLoading = false;
                        this.$emit('reload');
                    }
                })
                .catch((err) => {
                    this.rivisionButtonLoading = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        adminAgree() {
            this.agreed = true;
            this.agreeButtonAdminLoading = true;
            const id = this.$route.params.id;

            this.$apollo
                .mutate({
                    mutation: ADMIN_AGREE_FORM,
                    variables: { id },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                })
                .then((response) => {
                    if (response.data.form_adminAgree) {
                        this.$store.commit('notify', {
                            type: 'success',
                            message: 'Akkoord gegeven (beheerder)',
                        });

                        this.$emit('reload');
                    }
                })
                .catch((err) => {
                    this.agreeButtonAdminLoading = false;
                    this.agreed = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        adminDisagree() {
            this.disagreed = true;
            this.disagreeButtonAdminLoading = true;
            const id = this.$route.params.id;

            this.$apollo
                .mutate({
                    mutation: ADMIN_DISAGREE_FORM,
                    variables: { id },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                })
                .then((response) => {
                    if (response.data.form_adminDisagree) {
                        this.$store.commit('notify', {
                            type: 'success',
                            message: 'Geen akkoord gegeven (beheerder)',
                        });
                        this.$emit('reload');
                        this.disagreeButtonAdminLoading = false;
                    }
                })
                .catch((err) => {
                    this.disagreeButtonAdminLoading = false;
                    this.disagreed = false;
                    this.$store.commit('notify', extractError(err));
                });
        },
        getSmiley(answer) {
            return answer < 5.5 ? 'sad' : answer < 7 ? 'neutral' : 'happy';
        },
        getQuestionValue(question) {
            return {
                answer: question.answer,
                satisfied: question.satisfied,
                improvement: question.improvement,
            };
        },
        getNumber(component) {
            const components = this.form.components.filter((component) => component.type == 'question');
            const index = components.map((component) => component.indicator.id).indexOf(component.indicator.id);

            return index > -1 ? index + 1 : '';
        },
        longestListLength() {
            return this.form.components.length > this.companionComponents.length
                ? this.form.components.length
                : this.companionComponents.length;
        },
        getCurrentCollaborationAnswer() {
            const phases = this.project.timeline.phases || [];
            const formPhase = phases.find(phase => phase.pointIds.includes(this.form.id));
            const phaseKey = formPhase.id !== 'crow' ? `-${formPhase.id}` : '';
            const componentId = `component-collaboration-contractor${phaseKey}`;

            const [response] = this.form.project.references.responses;
            let value;
            this.$apollo.query({
                query: GET_VALUES,
                variables: {
                    where: {
                        AND: [
                            { componentId },
                            { responseId: response.id }
                        ]                        
                    }
                },
                fetchPolicy: 'no-cache'
            }).then(result => {
                value = result.data?.values[0]?.value;
                if (value) {
                    this.collaborationContractorInput = value;
                }
            });                     
        },
        handleComponentUpdated(component) {
            this.$emit('componentUpdated', component);
        }
    },
    computed: {
        hasCompanion: function () {
            return this.form.companions.length > 0 && this.form.companions[0].id !== '';
        },
        /**
         * Get the list of CROW forms.
         * It categorizes the indicators under the content items above itself
         */
        getCrowFormRows: function () {
            const isRelatedToProject = this.isRelatedToProject;

            const own = this.form.components;
            const other = this.form.companions.length > 0 ? this.form.companions[0].components : [];

            let categories = [];

            let current = 'no_category';

            own.forEach((component) => {
                if (component.type === 'content') {
                    const index = categories.map((c) => c.name).indexOf(component.items[0].text);
                    current = component.items[0].text;

                    if (index < 0) {
                        categories.push({
                            name: component.items[0].text,
                            ownComponents: [],
                            otherComponents: [],
                        });
                    }
                }

                const index = categories.map((c) => c.name).indexOf(current);

                categories[index].ownComponents.push(component);
            });

            other.forEach((component) => {
                if (component.type === 'content') {
                    const index = categories.map((c) => c.name).indexOf(component.items[0].text);
                    current = component.items[0].text;

                    if (index < 0) {
                        categories.push({
                            name: component.items[0].text,
                            ownComponents: [],
                            otherComponents: [],
                        });
                    }
                }

                const index = categories.map((c) => c.name).indexOf(current);

                categories[index].otherComponents.push(component);
            });

            let rows = [];

            categories.forEach((category) => {
                const longestLength = Math.max(category.ownComponents.length, category.otherComponents.length);

                for (let i = 0; i < longestLength; i++) {
                    rows.push({
                        own: category.ownComponents[i],
                        other: isRelatedToProject ? category.otherComponents[i] : {},
                    });
                }
            });

            return rows;
        },
        companionComponents: function () {
            if (this.status === 'waitingForCompanionFill') return [];
            return this.form.companions[0].components;
        },
        zerostateDescription: function () {
            if (!this.form.companions || this.form.companions.length === 0) return '';

            const { organisation } = this.form.companions[0];

            return `${organisation.name} is nog niet klaar met invullen.`;
        },
        myOrganisation: function () {
            return this.form.organisation;
        },
        companionOrganisation: function () {
            if (!this.form.companions || this.form.companions.length === 0) return {};

            return this.form.companions[0].organisation;
        },
        canAdminAgree: function() {
            const { users } = this.$store.getters.getCurrentOrganisation;
            
            if (!Array.isArray(users) || users.length === 0) return {
                able: this.isCrowAdmin,
                reason: this.isCrowAdmin ? null : 'Een CROW Beheerder moet deze actie uitvoeren'
            }
            
            const hasAgreed = this.form.agreedByUserId && this.form.agreedByUserId === this.user.id;
            const activeUsers = users.filter(user => user.status === 'active');
            const crowAdminExists = activeUsers.some(user => user.roleId === 'crow-admin-default');
            const approvers = crowAdminExists ? activeUsers.filter(user => user.roleId === 'crow-admin-default') : activeUsers.filter(user => user.roleId === 'admin-default');            
            const multipleApprovers = approvers.length > 1;
            const isApprover = crowAdminExists ? this.isCrowAdmin : this.isAdmin;
            const approverRoleName = crowAdminExists ? 'CROW Beheerder' : 'beheerder';

            const status = {
                able: false,
                reason: null
            }

            if (!isApprover) {
                status.reason = `Een ${approverRoleName} moet deze actie uitvoeren`;
            } else if (multipleApprovers && hasAgreed) {
                status.reason = `Een andere ${approverRoleName} moet deze actie uitvoeren`;
            } else {
                status.able = true;
            }

            return status
        },
        isCrowAdmin: function () {
            return this.ability.get().can('admin', 'Crow');
        },
        isAdmin: function() {
            return this.$store.getters.getCurrentOrganisation.users.find(user => user?.userId === this.$store.getters.getUser.id)?.roleId === 'admin-default';
        },
        isClient: function () {
            return this.$store.getters.getOrganisationType === 'client';
        },
        showCollaborationInputText: function () {
            const organisationType = this.$store.getters.getOrganisationType;

            return organisationType === 'contractor' && this.form.status === 'completed' && this.form.name === 'Eindmeting';
        },
        canAgree: function () {
            if (!this.showCollaborationInputText) return true;

            return this.collaborationContractorInput !== '';
        },
        showJudgement: function() {
            return ['discussion','revision','waitingForCompanionDiscussion','waitingForCompanionRevision','waitingForCrowAdmin','finished'].includes(this.status)
        },
        yourFormStatus: function() {
            if(!this.form.companions[0]) return companionStatus
            const companionStatus = this.form.companions[0].status;

            switch(companionStatus)  {
                case 'disagreed':
                    return {
                        status: 'revision',
                        type: 'sad'
                    }
                case 'completed':
                    return {
                        status: 'judgement',
                        type: 'neutral'
                    }
                default:
                    return {
                        status: 'agreed',
                        type: 'happy'
                    }
            }
        },
        companionFormStatus: function() {
            const status = this.form.status;

            switch(status)  {
                case 'disagreed':
                    return {
                        status: 'revision',
                        type: 'neutral'
                    }
                case 'completed':
                    return {
                        status: 'judgement',
                        type: 'neutral'
                    }
                default:
                    return {
                        status: 'agreed',
                        type: 'happy'
                    }
            }
        },
        crowJudgementStatus: function() {
            const clientForm = this.organisationType === 'client' ? this.form : this.form.companions[0];
            const status = clientForm.status;
            let type = '';
            switch(clientForm.status) {
                case 'disagreed':
                case 'crow_admin_disagreed':
                    type = 'sad';
                    break;
                case 'finished':
                    type = 'happy';
                    break;
                case 'pending_crow_admin':
                    type = 'unknown';
                    break;
            }

            return {
                status,
                type
            }

        },
        yourName: function() {
            if(this.myOrganisation) return this.myOrganisation.name 
            return this.$t(this.organisationType === 'client')
        },
        companionName: function() {
            if(this.companionOrganisation) return this.companionOrganisation.name 
            return this.$t(this.organisationType === 'client' ? 'contractor' : 'client')
        },
        singleFormStatus: function() {
            if(this.form.status === 'finished' && (this.form.companions.length === 0 || this.form.companions[0].responses.length === 0)) return {
                status: 'single_response',
                type: 'happy'
            }
            if((this.form.companions.length === 0 || this.form.companions[0].status === 'finished') && this.form.responses.length === 0) return {
                status: 'single_response',
                type: 'happy'
            }

            return {
                status: 'unknown',
                type: 'unknown'
            }
        },
        user: function() {
            return this.$store.getters.getUser
        },
        scores: function() {
            const scores = {
                mine: null,
                companion: null
            };

            const organisationType = this.form.type;
            const myKey = `${organisationType}ScoreData`;
            const companionKey = `${organisationType === 'client' ? 'contractor' : 'client'}ScoreData`;

            
            if(isDefined(this.form[myKey]?.average) && !isNaN(this.form[myKey]?.average)) scores.mine = this.form[myKey].average.toFixed(1);
            if(isDefined(this.form[companionKey]?.average) && !isNaN(this.form[companionKey]?.average)) scores.companion = this.form[companionKey].average.toFixed(1);

            return scores
        }
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    created() {
        this.getCurrentCollaborationAnswer();

        const organisationId = this.$store.getters.getCurrentOrganisation.id;
        this.isRelatedToProject =
            this.form.project.client.id === organisationId || this.form.project.contractor.id === organisationId;
    },
};
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';

.established-form {
    position: relative;

    .form-actions {
        --line-gap: 12px;

        display: flex;
        flex-direction: column;
        align-items: center;
        padding-block: 32px;

        .content {
            text-align: center;

            .header {
                .line {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    margin-bottom: 7px;
                }
                .headline p {
                    padding-inline: var(--line-gap);
                    background-color: white;
                }
                p {
                    font-weight: 500;
                    font-size: 15px;
                    width: fit-content;
                }
            }

            .description {
                margin-bottom: 17px;

                p {
                    line-height: 20px;
                    font-size: 14px;
                }

                &:empty {
                    display: none;
                }
            }

            .action-buttons {
                display: flex;
                justify-content: center;
                gap: 12px;
            }
        }

        .collaboration-text-container {
            width: 650px;

            .q-text {
                margin-inline: 32px;
            }
        }

        .agreement-status-container {
            display: flex;
            align-items: start;
            justify-content: space-between;
            margin: auto;
            padding-block: 32px;
            width: 100%;

            .your-side, .companion-side {
                position: relative;
                flex-grow: 1;
                display: grid;
                place-items: center;
                padding-top: 20px;
                isolation: isolate;

                .status-line {
                    position: absolute;
                    top: 31px;
                    height: 1px;
                }

                &.agreed {
                    .green-line {
                        animation: slide-in .5s ease-out forwards;
                    }
                }
                .green-line {
                    width: 0%;
                    background-color: $color-green;
                    z-index: -1;
                }
                .orange-line {
                    width: 0%;
                    background-color: $color-orange;
                    z-index: -2;
                }
                .red-line {
                    width: 0%;
                    background-color: $color-red;
                    z-index: -3;
                }
                .grey-line {
                    background-color: $color-grey-3;
                    z-index: -4;
                }
            }
            .your-side {
                &.no-line {
                    .status-line {
                        display: none;
                    }
                }
                .status-line {
                    left: 50%;
                    right: var(--line-gap);
                    margin-right: auto;
                }

                &.revision {
                    .red-line {
                        animation: slide-in .5s ease-out forwards;
                    }
                }
            }
            .companion-side {
                .status-line {
                    left: var(--line-gap);
                    right: 50%;
                    margin-left: auto;
                }
                &.revision {
                    .orange-line {
                        animation: slide-in .5s ease-out forwards;
                    }
                }
            }

            .agreement-center {
                width: 64px;
                display: grid;
                place-items: center;

                .agreement-circle {
                    background-color: #F4F5F6;
                    color: #495057;
                    border-radius: 50%;
                    padding: 8px;

                    &.agreed {
                        animation: agreed .2s ease forwards .3s;

                        .icon {
                            animation: shake .6s ease-in-out .1s;
                        }
                    }
                }

                
            }

            .content {
                width: 440px !important;
            }
        }
    }
}

@keyframes shake {
    0%, 100% {
        translate: 0 0;
    } 33% { 
        translate: 0 -4px;
    } 66% { 
        translate: 0 4px;
    }
}

@keyframes agreed {
    from {
        background-color: #F4F5F6;
        color: #495057;
    } to {
        background-color: #E1F2E6;
        color: #5EB15A;
    }
}

@keyframes slide-in {
    from {
        width: 0;
    } to {
        width: calc(50% - var(--line-gap));
    }
}

.organisation-names {
    display: flex;

    &.side {
        justify-content: space-between;
    }

    &.center {
        justify-content: center;
    }

    .right {
        text-align: right;
    }

    margin: 20px 0;

    p {
        font-family: Gotham;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }
}

.card {
    position: absolute;
}

.zerostate {
    padding: 50px 0;
}

.card {
    width: 45%;
    margin: 80px 0 0 0;
}

.wrapper {
    .row {
        display: flex;

        &.side {
            justify-content: space-between;
        }

        &.center {
            justify-content: center;
        }

        .left,
        .right {
            width: 48%;

            p {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
            }
        }

        .zerostate-row {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            .card {
                width: 90%;
            }
        }
    }
}

.head {
    display: flex;
    justify-content: space-between;

    img {
        width: 120px;
    }
}
</style>
