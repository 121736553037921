<template>
    <svg :width="width" :height="height" viewBox="0 0 8 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.23529 15.9505C5.23529 15.9505 5.23529 11.6057 5.23529 8.82178C5.23529 6.26394 2.41176 6.26394 2.41176 8.82178C2.41176 12.5337 2.41174 14.6148 2.41176 18.3267C2.41179 21.8911 7.00002 21.8911 6.99999 18.3267C6.99996 13.0682 7.00002 10.0098 6.99999 4.86139C6.99997 -0.287044 1 -0.287128 1 4.86137C1 8.02966 1 16.3465 1 16.3465" :stroke="color"/>
    </svg>
</template>

<script>
export default {
    name: 'Attachment',
    props: {
        color: {
            type: String,
            default: '#DEE2E6'
        },
        width: {
            type: [Number, String],
            default: 8
        },
        height: {
            type: [Number, String],
            default: 22
        }
    }
};
</script>