<template>
    <div class="row full-height middle-xs q-background">
        <div class="center-xs vertical-center login-sider">
            <div v-if="showEvironmentWarning" class="announcement-card warning">
                <h3>Let op! Je logt nu in op de <span class="block">{{ environment }}</span> omgeving</h3>
            </div>
            <div v-else class="cards">
                <div class="announcement-card fade-and-slide" style="--animation-delay: 0s">
                    <h3 class="fade-and-slide" style="margin-top: 24px; margin-left: 24px; font-size: 23px; --animation-delay: 0s">CROW Better Performance voor aannemers</h3>
                    <div class="webinar-card">
                        <h3 class="title fade-and-slide" style="--animation-delay: .1s">Weet binnen 30 minuten wat jullie hier als bedrijf mee kunnen</h3>
                        <div class="content">
                            <div class="left">
                                <img src="/statics/img/Foto-Leen.png" alt="" class="webinar-image fade-and-slide leen"  style="--animation-delay: .2s">
                                <div class="info fade-and-slide">
                                    <div class="line fade-and-slide"  style="--animation-delay: .33s">
                                        <p>Datum</p>
                                        <p class="bold">Zelf in te plannen</p>
                                    </div>
                                    <div class="line fade-and-slide"  style="--animation-delay: .36s">
                                        <p>Duur</p>
                                        <p class="bold">30 minuten</p>
                                    </div>
                                </div>
                            </div>
                            <div class="right">
                                <p class="about fade-and-slide" style="--animation-delay: .4s">Interesse in het gebruik van CROW Better Performance? Meer dan 120 bedrijven in de GWW zijn inmiddels aangesloten en meten op hun eigen initiatief CROW Better Performance. In een half uur laat Leen Valk je zien wat jullie hier als bedrijf mee kunnen. Klik op de knop hieronder om zelf een datum en tijdstip voor een online afspraak van 30 minuten in te plannen.</p>
                                <q-button class="fade-and-slide" style="--animation-delay: .5s" variation="primary" type="a" :href="'https://calendly.com/qfact-leen/uitleg-crow-better-performance-voor-aannemers'">Plan een afspraak</q-button>
                            </div>
                        </div>
                    </div>
                    <div class="divider"></div>
                    <div v-if="Date.now() > 1736870400000" class="webinar-card">
                        <h3 class="title fade-and-slide" style="--animation-delay: .3s">Better Performance Bouwteams is live!</h3>
                        <div class="content">
                            <div class="left">
                                <img src="/statics/img/training-samenwerken-in-bouwteams.jpg" alt="" class="webinar-image fade-and-slide nicolette" style="--animation-delay: .2s">
                            </div>
                            <div class="right">
                                <p class="about fade-and-slide" style="--animation-delay: .4s">
                                    De software module voor Better Performance Bouwteams is live! 🎉 Hiermee kun je nu de prestaties van bouwteams meten en verbeteren. Alles gericht op samenwerking, hoge kwaliteit en succes!<br>
                                    <br>
                                    Benieuwd of jij hier ook gebruik van kan maken en hoe het werkt? Lees alles over bouwteams in onze kennisbank via de knop. Samen maken we impact! 💡
                                </p>
                                <q-button class="fade-and-slide" style="--animation-delay: .5s" variation="primary" type="a" :href="'https://kennisbank.qfact.nl/nl/article/veelgestelde-vragen-over-better-performance-bouwteams'">Lees over bouwteams</q-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="center-xs login-main">
            <q-section class="top-bar" hPadding="30" vPadding="30">
                <p class="semi-bold" @click="toClientRegister" style="cursor: pointer">Ben je een <span class="client" :class="{ 'color-red': showEvironmentWarning }">opdrachtgever</span>? Bekijk <span class="client-here">hier</span> hoe je een account aanmaakt</p>
                <q-button @click="toContractorRegister" variation="light" size="small" :class="{ 'border-red color-red': showEvironmentWarning }">Account aanmaken als opdrachtnemer</q-button>
            </q-section>
            <q-section class="login-form">
                <div v-if="appDisabled" class="message">
                    <q-icon type="danger" width="32" height="32" color="rgb(216, 163, 3)"></q-icon>

                    <p v-html="appDisabledMessage"></p>
                </div>
                <div v-else-if="appInfoMessage" class="message">
                    <q-icon type="danger" width="32" height="32" color="rgb(216, 163, 3)"></q-icon>

                    <p v-html="appInfoMessage"></p>
                </div>
                <div v-if="appDisabled" class="message">
                    <q-icon type="danger" width="32" height="32" color="rgb(216, 163, 3)"> </q-icon>

                    <p v-html="appDisabledMessage"></p>
                </div>
                <h2 class="py-s">
                    Welkom terug bij Qfact
                </h2>
                <q-input labelPosition="inside"
                    v-model="email"
                    inputId="email"
                    inputType="email"
                    placeholder="voorbeeld@email.nl"
                    size="medium"
                    autofocus
                    :disabled="appDisabled"
                    >E-mailadres</q-input
                >
                <q-input labelPosition="inside"
                    v-model="password"
                    inputType="password"
                    placeholder="Wachtwoord"
                    size="medium"
                    :disabled="appDisabled"
                    @keyup.enter.native="handleLogin"
                    >Wachtwoord</q-input
                >
            </q-section>

            <q-section class="login-form space-between reset-password">
                <q-button to="/resetpassword" class="reset-password-button" variation="blank">Wachtwoord vergeten?</q-button>
            </q-section>

            <q-section class="login-form space-between" paddingBottom="0">
                <q-button @click="handleLogin" :loading="awaitLogin" :variation="showEvironmentWarning ? 'danger' : 'primary'" class="login-button" :disabled="appDisabled">Inloggen</q-button>
            </q-section>

            <q-section class="login-form space-between" paddingBottom="0" paddingTop="30">
                <p class="client-connect-organisation" @click="toConnectClient">Een nieuwe organisatie koppelen aan je account? Bekijk <span class="client-here">hier</span> hoe je dit doet</p>
            </q-section>

            <q-section class="bottom-bar row" hPadding="0" vPadding="0">
                <div class="col-sm-5"></div>
                <div class="col-sm-7 center-xs">
                    <img class="qfact-logo-login" src="/statics/img/Qfact-logo.svg" alt="" />
                </div>
            </q-section>
            <notifications />
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { LOGIN } from '@/graphql/mutations';
import Notifications from '@/components/Notifications';
import ArrowButton from '@/components/ArrowButton';
import { handleLoginSuccess, extractError } from '@/assets/js/utils';

export default {
    components: {
        Notifications,
        ArrowButton
    },
    data() {
        return {
            email: '',
            password: '',
            awaitLogin: false,
            appDisabled: process.env.BLOCK_LOGIN || false,
            appDisabledMessage: process.env.BLOCK_MESSAGE || 'Qfact is momenteel in onderhoudsmodus.',
            appInfoMessage: process.env.LOGIN_MESSAGE,
            environment: process.env.APP_ENV
        };
    },
    mounted() {
        document.getElementById('email').focus();
        const { error } = this.$route.query;
        if (error) this.$store.commit('notify', { type: 'warning', message: error });
    },
    methods: {
        to(url) {
            window.open(url, '_blank');
        },
        handleLogin() {
            const vm = this;

            if (this.appDisabled) {
                return this.$store.commit('notify', { type: 'info', message: 'Inloggen is momenteel niet mogelijk.' });
            }

            if (this.email == '' || this.password == '') return;

            this.awaitLogin = true;
            this.$apollo
                .mutate({
                    mutation: LOGIN,
                    variables: { email: this.email, password: this.password },
                })
                .then((response) => {
                    if (response.data.user_login.twofactorRequired) {
                        vm.$store.commit('setTwofactorJwt', response.data.user_login.jwt);

                        if (response.data.user_login.twofactorQRcode) {
                            vm.$store.commit('setTwofactorQrCode', response.data.user_login.twofactorQRcode);
                            vm.$router.push('/twofactorconfig');
                        } else {
                            // check if 2FA needs config
                            vm.$router.push('/twofactor');
                        }
                    } else {
                        return handleLoginSuccess(vm, response.data.user_login);
                    }
                })
                .catch(error => {
                    vm.awaitLogin = false;

                    const errors = error.graphQLErrors;
                    const messages = errors.map(error => error.message);

                    if (!messages || messages.length == 0) return
                    
                    messages.forEach(message => {
                        if(message.toLowerCase().includes('niet')) {
                            return this.$store.commit('notify', {
                                type: 'danger',
                                message: message
                            });
                        }
                        return this.$store.commit('notify', {
                            type: 'danger',
                            message: 'Oeps, we doen iets niet goed. Neem contact op met de helpdesk als deze foutmelding blijft terugkomen.'
                        })
                    });
                });
        },
        toCourse(to) {
            window.location.href = to;
        },
        toClientRegister() {
            window.open(` https://kennisbank.qfact.nl/nl/article/account-aanmaken-als-opdrachtgever`, '_blank');
        },
        toContractorRegister() {
            this.$router.push('/register')
        },
        toConnectClient() {
            window.open(`https://kennisbank.qfact.nl/nl/article/een-nieuwe-opdrachtgeversorganisatie-koppelen-aan-je-account`, '_blank');
        },
    },
    computed: {
        showEvironmentWarning: function() {
            return ['acc','test'].includes(this.environment)
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../components/qds/assets/style/_variables.scss';

.color-red {
    color: $color-red !important;
}
.background-red {
    background-color: $color-red !important;
}
.border-red {
    border-color: $color-red !important;
}

.course-header {
    display: flex;
    align-items: center;
    color: #8b8b8b;

    & img {
        margin-left: 10px;
        margin-top: 2px;
    }
}

.qfact-logo-login {
    height: 50px;
    z-index: -1;
}

.full-height {
    height: 100vh;
}

.q-background {
    background-color: #fff;
    background-image: url('/statics/img/qfact_hero.png');
    background-size: 1400px;
    background-position: -600px;
    background-repeat: no-repeat;
}

.login-sider {
    justify-content: center;
    height: 100%;
    width: 45%;
}

.top-bar {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    justify-content: flex-end;
    margin-bottom: 200px;
}

.bottom-bar {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100px;
    width: 100%;
}

.notifs {
    position: absolute;
    padding: 20px;
    right: 10px;
}

.login-form {
    margin: auto;
    text-align: left;
    width: 430px;

    .message {
        animation: fadeIn 800ms forwards;
        animation-delay: 1000ms;
        opacity: 0;
        margin-top: -142px;
        margin-bottom: 24px;
        border: 1px solid rgb(253, 216, 104);
        background: rgb(253, 225, 139);
        border-radius: 5px;
        padding: 16px;

        p {
            margin-top: 8px;
        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
                z-index: 1;
            }
            100% {
                opacity: 1;
                z-index: 5;
            }
        }
    }
}

.semi-bold {
    font-weight: 500;
    margin-right: 30px;
    line-height: 30px;
    color: #aeb5bc;
}

.qfact-logo {
    font-weight: 800;
    font-size: 30px;
    color: #dee2e6;
    transition: all 0.2s ease;

    &:hover {
        color: #495057;
        cursor: pointer;
    }
}

.fade {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 400ms;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    margin-right: -40px;
}

.fade-and-slide {
    opacity: 0;
    animation: fadeAndSlide .4s ease-out forwards var(--animation-delay, 0s);
    -webkit-animation: fadeAndSlide .4s ease-out forwards var(--animation-delay, 0s);
}

.announcement-card {
    background-color: white;
    width: 100%;
    max-width: 680px;
    max-height: 100vh;
    border-radius: 12px;
    margin-left: 3vw;
    text-align: left;
    border: 1px solid #48a09a;
    box-shadow: 0 2px 15px #92cbc7;
    overflow: auto;
    display: flex;
    flex-direction: column;

    h3 {
        color: #48a09a;
        font-size: 20px;
    }

    p {
        margin: 10px 0;
        font-size: 13px;

        .link {
            opacity: 0.8;
        }
    }


    img {
        width: 100%;
        margin-top: 6px;
        border-radius: 4px;
    }

    .divider {
        height: 1px;
        inset: auto 0;
        background-color: #48a09a;
    }

    .webinar-card {
        padding: 18px 24px;

        .title {
            margin-bottom: 8px;
            font-size: 18px;
        }

        .content {
            display: flex;
            gap: 10px;
            margin: 4px 0;

            .left {
                display: flex;
                flex-direction: column;
                width: 40%;
    
                .webinar-image {
                    max-height: 130px;
                    max-width: 240px;
                    object-fit: cover;

                    &.leen {
                        object-position: center 10%;
                    }
                    &.nicolette {
                        object-position: center 35%;
                    }
                }

                .webinar-gif {
                    max-width: 200px;
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    margin: 12px 0;

                    .line {
                        display: flex;
                        align-items: center;

                        .flex-column {
                            display: flex;
                            flex-direction: column;

                            p {
                                width: 100%;
                            }
                        }

                        .bold {
                            font-weight: 500;
                            flex-grow: 1;
                            width: 120px;
                        }
                        p {
                            width: 70px;
                            margin: 0;
                            line-height: 18px;
                        }
                    }
                }
            }
            .right {
                width: 60%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: space-between;
                margin-left: 6px;

                .about {
                    margin: 0 0 12px 0;
                }
                .button {
                }
            }
        }
    }

    &.warning {
        width: unset;
        border: 4px solid $color-red;
        box-shadow: 0 2px 15px $color-red-light;
        padding: 28px;
        animation: pulse 2s cubic-bezier(.2,0,0,1) infinite 1.5s;
        -webkit-animation: pulse 2s cubic-bezier(.2,0,0,1) infinite 1.5s;
        overflow: visible;
        z-index: 1000;

        h3 {
            color: $color-red;
            font-size: 22px;
        }

        .block {
            position: relative;
            display: inline-block;
            font-size: 22px;
            font-weight: 600;
            text-transform: uppercase;
            color: white;
            padding-inline: 6px;
            isolation: isolate;

            &:after {
                content: "";
                position: absolute;
                width: 150vw;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 10vw;
                aspect-ratio: 16/9;
                background: $color-red;
                animation: pull-focus 1s ease forwards .5s;
                -webkit-animation: pull-focus 1s ease forwards .5s;
                z-index: -1;
            }
        }
    }
}

@keyframes pull-focus {
    to {
        width: 64px;
        aspect-ratio: 5/3;
        border-radius: 8px;
    }
}
@-webkit-keyframes pull-focus {
    to {
        width: 64px;
        aspect-ratio: 5/3;
        border-radius: 8px;
    }
}

@keyframes pulse {
    0% {
        scale: 1;
        outline-style: solid;
        outline-width: 4px;
        outline-offset: -4px;
        outline-color: rgba($color-red, 1);
    } 15% {
        scale: 1.05;
        outline-style: solid;
        outline-width: 4px;
        outline-offset: -4px;
        outline-color: rgba($color-red, 1);
    } 50% {
        scale: 1;
        outline-style: solid;
        outline-width: 3px;
        outline-color: rgba($color-red, .6);
    } 100% {
        outline-style: solid;
        outline-width: 1px;
        outline-offset: 50px;
        outline-color: rgba($color-red, 0);
    }
}

@keyframes fadeAndSlide {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@-webkit-keyframes fadeAndSlide {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.login-main {
    width: 55%;
}

.client {
    color: #00A1AE;
}

.client-here {
    text-decoration: underline;
}

.reset-password {
    justify-content: flex-end;
}

.reset-password-button {
    padding: 0 0 20px 0!important;
}

.login-button {
    width: 100%;
}

.client-connect-organisation {
    color: #757575;
    cursor: pointer;
    font-size: 12px;
    text-align: center;
}
</style>
