<template>
    <div class="focus-guide">
        <div 
            class="circle" 
            :class="{ focus }"
            :style="`--focus-width: ${focusElement.width}px; --focus-height: ${focusElement.height}px; --focus-x: ${focusElement.x}px; --focus-y: ${focusElement.y}px`"
        ></div>
        <div class="description bottom" :class="{ show: focus }">
            <p v-html="description"></p>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'focus-guide',
    data() {
        return {
            enableFocus: false,
            focus: false,
            description: '',
            focusElement: {
                width: 0,
                height: 0,
                x: 0,
                y: 0
            },
            scrolling: false,
            focusTimeout: null
        }
    },
    methods: {
        async startNewGuide({ elementId, description }) {
            const overflowContainer = document.getElementById('content-overflow');
            const element = document.getElementById(elementId);
            const headerHeight = 80;
            const { top: originalTop } = element.getBoundingClientRect();
            
            if(originalTop < headerHeight || originalTop > window.innerHeight) {
                element.scrollIntoView({ block: 'center' });
                await new Promise(r => setTimeout(r, 10));
            }

            const { left, top, width, height } = element.getBoundingClientRect();
            
            this.focusElement.x = left;
            this.focusElement.y = top;
            this.focusElement.width = width;
            this.focusElement.height = height;

            this.description = description;

            element.focus();

            this.enableFocus = true;
            if(top > 0 && (top + height) < window.innerHeight) this.focus = true;
        },
        handleScrollDebounce() {
            if(!this.enableFocus) return

            this.scrolling = true;
            if(this.focus) return this.resetGuide();
            this._handleScrollDebounce();
        },
        _handleScrollDebounce: _.debounce(function() {
            this.scrolling = false;
            if(this.enableFocus) this.focus = true;
        }, 200),
        async handleFocusTimeout(timeout = 5000) {
            this.focusTimeout = setTimeout(this.resetGuide, timeout);
        },
        async resetGuide() {
            if(this.focusTimeout) clearTimeout(this.focusTimeout);
            this.enableFocus = false;
            this.focus = false;
            this.scrolling = false;
            this.description = '';
            await new Promise(r => setTimeout(r, 400));
            if(this.focusTimeout) clearTimeout(this.focusTimeout);
            this.focusElement = {
                width: 0,
                height: 0,
                x: 0,
                y: 0
            }
        },
        handleMouseMove(event) {
            if(!this.focus) return

            if(
                (event.x > this.focusElement.x && event.x < this.focusElement.x + this.focusElement.width) &&
                (event.y > this.focusElement.y && event.y < this.focusElement.y + this.focusElement.height)
            ) return this.resetGuide()
        }
    },
    watch: {
        focus: function() {
            if(this.focus) this.handleFocusTimeout();
        }
    },
    mounted() {
        this.$root.$on('scroll', this.handleScrollDebounce);
        this.$root.$on('mousemove', this.handleMouseMove);
    },
    beforeDestroy() {
        this.$root.$off('scroll', this.handleScrollDebounce);
        this.$root.$off('mousemove', this.handleMouseMove);
    },
}
</script>

<style lang="scss" scoped>

.focus-guide {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
    z-index: 1000;

    .circle {
        position: absolute;
        width: var(--focus-width);
        height: var(--focus-height);
        left: var(--focus-x);
        top: var(--focus-y);
        scale: 1.2;
        border-radius: 12px;
        outline: 100vw solid #00000000;

        transition: outline-color 400ms ease, scale 0s ease 400ms;
        -webkit-transition: outline-color 400ms ease, scale 0s ease 400ms;
        
        &.focus {
            outline-color: #000000AA;
            scale: 1;

            transition: outline-color 1200ms ease, scale 600ms ease-out;
            -webkit-transition: outline-color 1200ms ease, scale 600ms ease-out;
        }
    }

    .description {
        --position: -100px;

        position: absolute;
        inset-inline: 0;
        text-align: center;
        color: white;
        transition: 800ms ease 400ms;
        -webkit-transition: 800ms ease 400ms;

        &.show {
            --position: 48px;
        }

        &.bottom {
            bottom: var(--position);
        }
        
        &.top {
            top: var(--position);
        }

        p {
            font-size: 18px;
            font-weight: 500;
            text-shadow: 0 0 32px black;
        }
    }
}

</style>