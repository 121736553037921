<template>
    <div class="icon-button-wrapper">
        <button ref="button" class="empty-button show-focus-state" @click.prevent.stop="handleOpenMenu" @mouseenter="hover = true;" @mouseleave="handleMouseLeave">
            <q-icon class="icon" :class="[{ hover, active, animation }, animation]" :type="icon" :variation="variation" :width="iconWidth" :height="iconHeight" :color="iconColor"></q-icon>
        </button>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'q-icon-button',
    props: {
        icon: {
            type: String,
            required: true
        },
        variation: {
            type: String,
            default: null
        },
        iconWidth: {
            type: String | Number,
            default: 22
        },
        iconHeight: {
            type: String | Number,
            default: 22
        },
        iconColor: {
            type: String,
            default: '#484f56'
        },
        active: {
            type: Boolean,
            default: false
        },
        animation: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            hover: false
        }
    },
    methods: {
        handleOpenMenu(event) {
            this.$emit('click');
            // this._handleBlur();
        },
        // _handleBlur: _.debounce(function() {
        //     const activeElement = document.activeElement;
        //     console.log(activeElement)
        //     if(activeElement) activeElement.blur();
        // }, 500),
        handleMouseLeave() {
            this.hover = false;
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/components/qds/assets/style/_variables.scss';

.empty-button {
    position: relative;
    border: none;
    background: none;
    border: none;
    padding: 3px;
    cursor: pointer;
    border-radius: 50%;
    aspect-ratio: 1/1;
    isolation: isolate;

    &:focus-visible .icon,
    .icon.hover {
        transition: color 600ms ease;
        color: $color-grey-7;

        &:before {
            scale: 1;
            transition: scale 800ms cubic-bezier(.13,1.55,.16,.98);
        }
    }

    &:active .icon:before {
        scale: 0.85 !important;
        transition: scale 250ms cubic-bezier(.51,2.5,.45,1.08);
    }
}

.icon {
    color: $color-grey-5;
    scale: 1;
    transition: color 300ms ease;

    &:before {
        content: "";
        position: absolute;
        inset: calc(-6px - 2%);
        aspect-ratio: 1/1;
        transform-origin: center center;
        margin: auto;
        background: #c4c4c460;
        scale: 0;
        border-radius: 50%;
        z-index: -1;
        transition: scale 300ms ease;
    }
}

.animation {
    &.rotate {
        transition: color 300ms ease, transform 600ms ease;

        &.active {
            transform: rotate(270deg);
            -webkit-transform: rotate(270deg);
            transition: color 300ms ease, transform 800ms cubic-bezier(.45,-0.74,.4,1) !important
        }
    }
}

@keyframes rotateWheel {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    } 20% {
        transform: rotate(-60deg);
        -webkit-transform: rotate(-60deg);
    } 100% {
        transform: rotate(270deg);
        -webkit-transform: rotate(270deg);
    }
}

</style>